import React, { FC, useCallback, useState } from 'react';
import { ActivationStatus, BudgetLimitsTransactionResponse } from '../../../../types.__generated__';
import { AccountTile } from '../../../Account/MyAccount/AccountTile';
import { AccountLimitsItemContent } from '../AccountLimitsItemContent';
import { AccountLimitsItemPendingLimit } from '../AccountLimitsItemPendingLimit/AccountLimitsItemPendingLimit';
import { LimitsConfirmationPrompt } from '../LimitsConfirmationPrompt';
import { LimitPeriodsEnum, ModalAction } from '../types';
import { MediatorType } from '../../types';
import './styles';

interface AccountLimitsItemProps {
  label: string;
  mediatorType: MediatorType;
  data: BudgetLimitsTransactionResponse[];
  refetchLimits: () => void;
  onClick: (period?: LimitPeriodsEnum, mediatorType?: MediatorType, action?: ModalAction) => void;
}

export const AccountLimitsItem: FC<AccountLimitsItemProps> = ({
  label,
  data,
  onClick,
  refetchLimits,
  mediatorType,
}) => {
  const [showPrompt, setShowPrompt] = useState<boolean>(false);

  const pendingCancellationItem = data?.find((item) => item.activationStatus === ActivationStatus.PendingCancellation);
  const pendingUserActionItem = data?.find((item) => item.activationStatus === ActivationStatus.PendingUserAction);
  const pendingActivationItem = data?.find((item) => item.activationStatus === ActivationStatus.PendingActivation);
  const activeItem = data?.find(
    (item) => item.activationStatus === ActivationStatus.Active && !item.isPendingCancellation
  );
  const pendingItem = pendingCancellationItem || pendingActivationItem || pendingUserActionItem;
  const period = (activeItem?.period || pendingItem?.period) as LimitPeriodsEnum;
  const previousActiveItem = data?.find((item) => item.activationStatus === ActivationStatus.PendingCancellation);

  const onEdit = useCallback(() => onClick(period, mediatorType, ModalAction.Edit), [mediatorType]);
  const onRemove = useCallback(() => onClick(period, mediatorType, ModalAction.Remove), [mediatorType]);

  return (
    <div className="account-limits-item">
      <AccountTile>
        <AccountLimitsItemContent
          data={activeItem}
          isInitialLimit={
            !activeItem && !pendingUserActionItem && !pendingActivationItem && !pendingItem && !pendingCancellationItem
          }
          onEdit={onEdit}
          onRemove={onRemove}
          label={label}
        />
        <AccountLimitsItemPendingLimit
          pendingCancellationItem={pendingCancellationItem}
          pendingUserActionItem={pendingUserActionItem}
          pendingActivationItem={pendingActivationItem}
          setShowPrompt={setShowPrompt}
        />
        {showPrompt && (
          <LimitsConfirmationPrompt
            mediatorType={mediatorType}
            pendingUserActionItem={pendingUserActionItem}
            activeItem={pendingItem}
            activeAmount={activeItem?.amount || previousActiveItem?.amount || 0}
            onCloseCallback={setShowPrompt}
            refetchLimits={refetchLimits}
          />
        )}
      </AccountTile>
    </div>
  );
};
