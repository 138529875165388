import { FC } from 'react';
import './LogoSpinner.scss';

interface LogoSpinnerProps {
  showText?: boolean;
  size?: 'small' | 'medium' | 'large';
  className?: string;
}

export const LogoSpinner: FC<LogoSpinnerProps> = ({ showText = false, size = 'medium', className = '' }) => {
  return (
    <div className={`logo-spinner ${size} ${className}`}>
      <div className="logo-wrapper target">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="61.885 0 20.05 19.619">
          <path
            fill="#fff"
            d="M71.912 0c-5.537 0-10.027 4.389-10.027 9.81 0 5.42 4.49 9.809 10.027 9.809 5.537 0 10.023-4.389 10.023-9.81 0-5.42-4.5-9.809-10.023-9.809zm0 15.844a6.025 6.025 0 01-5.484-3.823 6.063 6.063 0 011.393-6.55 6.022 6.022 0 019.248.985 6.058 6.058 0 011.003 3.35 6.132 6.132 0 01-1.838 4.293 6.1 6.1 0 01-4.322 1.745z"
          />
          <path
            fill="#EB6708"
            d="M71.901 13.417c2.037 0 3.689-1.617 3.689-3.611 0-1.995-1.652-3.612-3.689-3.612s-3.689 1.617-3.689 3.612c0 1.994 1.652 3.611 3.69 3.611z"
          />
        </svg>
        {showText && <p>Loading...</p>}
      </div>
    </div>
  );
};
