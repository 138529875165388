export const setXtremepushId = (id: string): void => {
  (window as unknown as Record<string, (arg1: string, arg2: string, arg3: string) => void>).xtremepush(
    'set',
    'user_id',
    id
  );
};

export const xtremepushEvent = (eventName: string): void => {
  (window as unknown as Record<string, (arg1: string, arg2: string) => void>).xtremepush('event', eventName);
};
