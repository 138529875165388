export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  BigFloat: { input: any; output: any; }
  BigInt: { input: any; output: any; }
  Cursor: { input: any; output: any; }
  Datetime: { input: any; output: any; }
  JSON: { input: any; output: any; }
  UUID: { input: any; output: any; }
};

export enum ActivationStatus {
  Active = 'ACTIVE',
  Canceled = 'CANCELED',
  Expired = 'EXPIRED',
  PendingActivation = 'PENDING_ACTIVATION',
  PendingCancellation = 'PENDING_CANCELLATION',
  PendingUserAction = 'PENDING_USER_ACTION'
}

export type AddIdentityRoleForIdentityUserResponse = {
  __typename?: 'AddIdentityRoleForIdentityUserResponse';
  data?: Maybe<IdentityRoleForIdentityUserCreateSuccess>;
  error?: Maybe<Array<IdentityValidationErrors>>;
};

export type AddRelatedIdentityRoleToRoleResponse = {
  __typename?: 'AddRelatedIdentityRoleToRoleResponse';
  data?: Maybe<Scalars['JSON']['output']>;
  error?: Maybe<Array<IdentityValidationErrors>>;
};

export type AdditionalDataScore = {
  __typename?: 'AdditionalDataScore';
  ragStatus?: Maybe<Scalars['String']['output']>;
  riskScore?: Maybe<Scalars['Int']['output']>;
};

export type AdditionalPropertiesInput = {
  minimumOdds?: InputMaybe<Scalars['Float']['input']>;
};

export type Address = {
  __typename?: 'Address';
  addressCountry?: Maybe<Scalars['String']['output']>;
  addressLocality?: Maybe<Scalars['String']['output']>;
  addressRegion?: Maybe<Scalars['String']['output']>;
  postOfficeBoxNumber?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  streetAddress?: Maybe<Scalars['String']['output']>;
};

export enum AdjustmentType {
  Credit = 'Credit',
  Debit = 'Debit'
}

export type AdminCategory = {
  __typename?: 'AdminCategory';
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type AdministrationConfig = {
  __typename?: 'AdministrationConfig';
  email?: Maybe<Scalars['String']['output']>;
  familyName?: Maybe<Scalars['String']['output']>;
  givenName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  identityRolesByIdentityUserId?: Maybe<Array<Maybe<IdentityRolesValues>>>;
  identityUsersConfig?: Maybe<AdministrationIdentityUsersConfig>;
  isEmailVerified?: Maybe<Scalars['Boolean']['output']>;
  isEnabled?: Maybe<Scalars['Boolean']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};


export type AdministrationConfigIdentityRolesByIdentityUserIdArgs = {
  xTenantID: Scalars['ID']['input'];
};


export type AdministrationConfigIdentityUsersConfigArgs = {
  xTenantID: Scalars['ID']['input'];
};

export type AdministrationIdentityRolesByIdentityUsersConfig = {
  __typename?: 'AdministrationIdentityRolesByIdentityUsersConfig';
  detail?: Maybe<Scalars['String']['output']>;
  instance?: Maybe<Scalars['String']['output']>;
  property1?: Maybe<Scalars['String']['output']>;
  property2?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['Boolean']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type AdministrationIdentityUsersConfig = {
  __typename?: 'AdministrationIdentityUsersConfig';
  email?: Maybe<Scalars['String']['output']>;
  familyName?: Maybe<Scalars['String']['output']>;
  givenName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isEmailVerified?: Maybe<Scalars['Boolean']['output']>;
  isEnabled?: Maybe<Scalars['Boolean']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

export type AdministrationPagedIdentityUsers = {
  __typename?: 'AdministrationPagedIdentityUsers';
  count?: Maybe<Scalars['Float']['output']>;
  data?: Maybe<Array<Maybe<AdministrationConfig>>>;
};

export type AggregateFilters = {
  key?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<FiltersValues>;
};

export type AnswerChoices = {
  __typename?: 'AnswerChoices';
  id?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
};

export type AspectRatio = {
  __typename?: 'AspectRatio';
  /** Contains any extraneous properties defined on the content model */
  additionalProperties?: Maybe<Scalars['JSON']['output']>;
  ratioX?: Maybe<Scalars['Int']['output']>;
  ratioY?: Maybe<Scalars['Int']['output']>;
};

export type AssignBonusCreateInput = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  bonusTemplateId: Scalars['ID']['input'];
};

export type AuthenticationStatus = {
  __typename?: 'AuthenticationStatus';
  forLoggedIn: Scalars['Boolean']['output'];
  forLoggedOut: Scalars['Boolean']['output'];
};

export type AuthorMetadata = {
  __typename?: 'AuthorMetadata';
  name?: Maybe<Scalars['String']['output']>;
};

export type Banner = {
  __typename?: 'Banner';
  /** Contains any extraneous properties defined on the content model */
  additionalProperties?: Maybe<Scalars['JSON']['output']>;
  assets: BannerAssets;
  callToActions: Array<CallToAction>;
  content: BannerContent;
  filters: Filters;
  id: Scalars['ID']['output'];
  sort: Scalars['Int']['output'];
};

export type BannerAssets = {
  __typename?: 'BannerAssets';
  additionalLogo?: Maybe<Scalars['String']['output']>;
  largeBackground?: Maybe<Scalars['String']['output']>;
  largeMidground?: Maybe<Scalars['String']['output']>;
  smallBackground?: Maybe<Scalars['String']['output']>;
  smallMidground?: Maybe<Scalars['String']['output']>;
};

export type BannerCollection = {
  __typename?: 'BannerCollection';
  banners: Array<Banner>;
  pages: Array<Scalars['String']['output']>;
};

export type BannerContent = {
  __typename?: 'BannerContent';
  /** Contains any extraneous properties defined on the content model */
  additionalProperties?: Maybe<Scalars['JSON']['output']>;
  longContent: Scalars['String']['output'];
  longHeading: Scalars['String']['output'];
  longSubheading: Scalars['String']['output'];
  shortContent: Scalars['String']['output'];
  shortHeading: Scalars['String']['output'];
  shortSubheading: Scalars['String']['output'];
};

export type BoTenantConfigResponse = {
  __typename?: 'BoTenantConfigResponse';
  data?: Maybe<TenantConfigBo>;
  error?: Maybe<TenantConfigBoError>;
};

export type BoTenantListResponse = {
  __typename?: 'BoTenantListResponse';
  data?: Maybe<Array<Maybe<TenantListBo>>>;
  error?: Maybe<TenantConfigBoError>;
};

export type BonusContent = {
  __typename?: 'BonusContent';
  /** Contains any extraneous properties defined on the content model */
  additionalProperties?: Maybe<Scalars['JSON']['output']>;
  description: Scalars['String']['output'];
  filters: Filters;
  image?: Maybe<Scalars['String']['output']>;
  subtitle: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type BonusPagedTemplates = {
  __typename?: 'BonusPagedTemplates';
  count?: Maybe<Scalars['Float']['output']>;
  data?: Maybe<Array<Maybe<BonusTemplate>>>;
};

export type BonusTemplate = {
  __typename?: 'BonusTemplate';
  additionalProperties?: Maybe<Scalars['JSON']['output']>;
  claimableFor?: Maybe<Scalars['String']['output']>;
  claimedFor?: Maybe<Scalars['String']['output']>;
  consumeTypeStrategy?: Maybe<ConsumeTypeStrategy>;
  consumingZeroAmount?: Maybe<Scalars['Float']['output']>;
  conversionMax?: Maybe<Calculation>;
  conversionMin?: Maybe<Calculation>;
  createdBy?: Maybe<Scalars['String']['output']>;
  createdOn?: Maybe<Scalars['String']['output']>;
  creator?: Maybe<Scalars['String']['output']>;
  currencyCode?: Maybe<Scalars['String']['output']>;
  gameConfigFilterType?: Maybe<ConfigFilterType>;
  gameConfigIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  granting?: Maybe<Calculation>;
  grantingMaxAmount?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  inProgressFor?: Maybe<Scalars['String']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isClaimable?: Maybe<Scalars['Boolean']['output']>;
  isUsed?: Maybe<Scalars['Boolean']['output']>;
  lastUpdatedBy?: Maybe<Scalars['String']['output']>;
  lastUpdatedOn?: Maybe<Scalars['String']['output']>;
  lastUpdater?: Maybe<Scalars['String']['output']>;
  locking?: Maybe<Calculation>;
  mediatorType?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  providerConfigFilterType?: Maybe<ConfigFilterType>;
  providerConfigIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  wageringContributionStrategy?: Maybe<WageringContributionStrategy>;
  wageringRequirement?: Maybe<Calculation>;
};

export type BonusTemplatesCreateInput = {
  additionalProperties?: InputMaybe<AdditionalPropertiesInput>;
  claimableFor?: InputMaybe<Scalars['String']['input']>;
  claimedFor?: InputMaybe<Scalars['String']['input']>;
  consumeTypeStrategy?: InputMaybe<ConsumeTypeStrategy>;
  consumingZeroAmount?: InputMaybe<Scalars['Float']['input']>;
  conversionMax?: InputMaybe<CalculationInput>;
  conversionMin?: InputMaybe<CalculationInput>;
  currencyCode?: InputMaybe<Scalars['String']['input']>;
  gameConfigFilterType?: InputMaybe<ConfigFilterType>;
  gameConfigIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  granting?: InputMaybe<CalculationInput>;
  grantingMaxAmount?: InputMaybe<Scalars['Float']['input']>;
  inProgressFor?: InputMaybe<Scalars['String']['input']>;
  isClaimable?: InputMaybe<Scalars['Boolean']['input']>;
  locking?: InputMaybe<CalculationInput>;
  mediatorType?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  providerConfigFilterType?: InputMaybe<ConfigFilterType>;
  providerConfigIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  wageringContributionStrategy?: InputMaybe<WageringContributionStrategyInput>;
  wageringRequirement?: InputMaybe<CalculationInput>;
};

export type BonusTemplatesCreateResponse = {
  __typename?: 'BonusTemplatesCreateResponse';
  data?: Maybe<BonusTemplatesCreateSuccess>;
  error?: Maybe<Array<BonusValidationErrors>>;
};

export type BonusTemplatesCreateSuccess = {
  __typename?: 'BonusTemplatesCreateSuccess';
  id: Scalars['ID']['output'];
};

export type BonusTemplatesUpdateInput = {
  additionalProperties?: InputMaybe<AdditionalPropertiesInput>;
  claimableFor?: InputMaybe<Scalars['String']['input']>;
  claimedFor?: InputMaybe<Scalars['String']['input']>;
  consumeTypeStrategy?: InputMaybe<ConsumeTypeStrategy>;
  consumingZeroAmount?: InputMaybe<Scalars['Float']['input']>;
  conversionMax?: InputMaybe<CalculationInput>;
  conversionMin?: InputMaybe<CalculationInput>;
  currencyCode?: InputMaybe<Scalars['String']['input']>;
  gameConfigFilterType?: InputMaybe<ConfigFilterType>;
  gameConfigIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  granting?: InputMaybe<CalculationInput>;
  grantingMaxAmount?: InputMaybe<Scalars['Float']['input']>;
  inProgressFor?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isClaimable?: InputMaybe<Scalars['Boolean']['input']>;
  locking?: InputMaybe<CalculationInput>;
  mediatorType?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  providerConfigFilterType?: InputMaybe<ConfigFilterType>;
  providerConfigIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  wageringContributionStrategy?: InputMaybe<WageringContributionStrategyInput>;
  wageringRequirement?: InputMaybe<CalculationInput>;
};

export type BonusTemplatesUpdateResponse = {
  __typename?: 'BonusTemplatesUpdateResponse';
  data?: Maybe<BonusTemplate>;
  error?: Maybe<Array<BonusValidationErrors>>;
};

export type BonusUserProfileBonusesUpdate = {
  __typename?: 'BonusUserProfileBonusesUpdate';
  after?: Maybe<Endeavour_Bonuses_User_Profile_Bonuses_Dot_Value2>;
  before?: Maybe<Endeavour_Bonuses_User_Profile_Bonuses_Dot_Value>;
};

export type BonusValidationErrors = FieldError | GenericError;

/** A filter to be used against Boolean fields. All fields are combined with a logical ‘and.’ */
export type BooleanFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['Boolean']['input']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['Boolean']['input']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['Boolean']['input']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['Boolean']['input']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['Boolean']['input']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['Boolean']['input']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['Boolean']['input']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['Boolean']['input']>>;
};

export type BrandMetadata = {
  __typename?: 'BrandMetadata';
  logo?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type BudgetLimitsCreateTransactionInput = {
  amount: Scalars['Float']['input'];
  createdBy?: InputMaybe<Scalars['String']['input']>;
  currencyCode: Scalars['String']['input'];
  mediatorType: Scalars['String']['input'];
  period: Scalars['String']['input'];
  reason?: InputMaybe<Scalars['String']['input']>;
  type: BudgetLimitsTransactionType;
};

export type BudgetLimitsReview = {
  confirmed?: InputMaybe<Scalars['Boolean']['input']>;
};

export type BudgetLimitsTransactionResponse = {
  __typename?: 'BudgetLimitsTransactionResponse';
  activationStatus?: Maybe<ActivationStatus>;
  amount?: Maybe<Scalars['Float']['output']>;
  changeCooldown?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  createdOn?: Maybe<Scalars['String']['output']>;
  creator?: Maybe<Scalars['String']['output']>;
  currencyCode?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isCancellable?: Maybe<Scalars['Boolean']['output']>;
  isPendingCancellation?: Maybe<Scalars['Boolean']['output']>;
  lastUpdatedOn?: Maybe<Scalars['String']['output']>;
  mediatorType?: Maybe<Scalars['String']['output']>;
  pendingChangeExpectedOn?: Maybe<Scalars['String']['output']>;
  period?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  userProfileId?: Maybe<Scalars['ID']['output']>;
};

export enum BudgetLimitsTransactionType {
  Deposit = 'DEPOSIT',
  Loss = 'LOSS',
  Maxwager = 'MAXWAGER',
  Netdeposit = 'NETDEPOSIT',
  Wagering = 'WAGERING',
  Withdrawal = 'WITHDRAWAL'
}

export type Calculation = {
  __typename?: 'Calculation';
  amount?: Maybe<Scalars['Float']['output']>;
  type?: Maybe<CalculationType>;
};

export type CalculationInput = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  type?: InputMaybe<CalculationType>;
};

export enum CalculationType {
  Absolute = 'Absolute',
  Ratio = 'Ratio'
}

export type CallToAction = {
  __typename?: 'CallToAction';
  /** Contains any extraneous properties defined on the content model */
  additionalProperties?: Maybe<Scalars['JSON']['output']>;
  content: CallToActionContent;
  name: Scalars['String']['output'];
  style: CallToActionStyle;
};

export type CallToActionContent = {
  __typename?: 'CallToActionContent';
  desktopURL: Scalars['String']['output'];
  longText: Scalars['String']['output'];
  mobileURL: Scalars['String']['output'];
  shortText: Scalars['String']['output'];
};

export type CallToActionStyle = {
  __typename?: 'CallToActionStyle';
  desktop: Scalars['String']['output'];
  mobile: Scalars['String']['output'];
};

export type CashierLaunchArguments = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  /** @deprecated */
  backUrl?: InputMaybe<Scalars['String']['input']>;
  currencyCode: Scalars['String']['input'];
  /** @deprecated */
  errorUrl?: InputMaybe<Scalars['String']['input']>;
  /** @deprecated */
  successUrl?: InputMaybe<Scalars['String']['input']>;
  type: MethodType;
  userProfileId: Scalars['ID']['input'];
};

/** Contains the session context ID used to make a payment */
export type CashierSession = {
  __typename?: 'CashierSession';
  providerExtra?: Maybe<Scalars['JSON']['output']>;
  sessionContextId: Scalars['ID']['output'];
};

export type CasinoConfigValidationErrors = FieldError | GenericError;

export type CasinoConfiguration = {
  __typename?: 'CasinoConfiguration';
  parallel_activations: ParallelActivationsConfiguration;
};

export type CasinoGameConfig = {
  __typename?: 'CasinoGameConfig';
  adminCategories?: Maybe<Array<Maybe<AdminCategory>>>;
  adminCategoryId?: Maybe<Scalars['String']['output']>;
  bonusContributionRatio?: Maybe<Scalars['Float']['output']>;
  certificateId?: Maybe<Scalars['String']['output']>;
  certificateName?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  createdOn?: Maybe<Scalars['String']['output']>;
  creator?: Maybe<Scalars['String']['output']>;
  currencyCode?: Maybe<Scalars['String']['output']>;
  gameCategories?: Maybe<Array<Maybe<GameCategory>>>;
  gameCategoryId?: Maybe<Scalars['String']['output']>;
  gameExternalAlternateId?: Maybe<Scalars['String']['output']>;
  gameExternalId?: Maybe<Scalars['String']['output']>;
  gameVariantExternalId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  isDemoAllowed?: Maybe<Scalars['Boolean']['output']>;
  jurisdictionCategory?: Maybe<Scalars['String']['output']>;
  jurisdictionVariant?: Maybe<Scalars['String']['output']>;
  lastUpdatedBy?: Maybe<Scalars['String']['output']>;
  lastUpdatedOn?: Maybe<Scalars['String']['output']>;
  lastUpdater?: Maybe<Scalars['String']['output']>;
  launchContext?: Maybe<Scalars['String']['output']>;
  maxBetAmount?: Maybe<Scalars['Float']['output']>;
  minBetAmount?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  providerConfig?: Maybe<CasinoProviderConfig>;
  providerConfigId?: Maybe<Scalars['String']['output']>;
  providerName?: Maybe<Scalars['String']['output']>;
  reportingCategory?: Maybe<Scalars['String']['output']>;
  studio?: Maybe<Scalars['String']['output']>;
  theoreticalRTP?: Maybe<Scalars['Float']['output']>;
  version?: Maybe<Scalars['String']['output']>;
  versionedOn?: Maybe<Scalars['String']['output']>;
};


export type CasinoGameConfigAdminCategoriesArgs = {
  xTenantID: Scalars['ID']['input'];
};


export type CasinoGameConfigGameCategoriesArgs = {
  xTenantID: Scalars['ID']['input'];
};


export type CasinoGameConfigProviderConfigArgs = {
  xTenantID: Scalars['ID']['input'];
};

export type CasinoGameConfigCreateInput = {
  adminCategoryId?: InputMaybe<Scalars['String']['input']>;
  bonusContributionRatio?: InputMaybe<Scalars['Float']['input']>;
  certificateId?: InputMaybe<Scalars['String']['input']>;
  certificateName?: InputMaybe<Scalars['String']['input']>;
  currencyCode?: InputMaybe<Scalars['String']['input']>;
  gameCategoryId?: InputMaybe<Scalars['String']['input']>;
  gameExternalAlternateId?: InputMaybe<Scalars['String']['input']>;
  gameExternalId?: InputMaybe<Scalars['String']['input']>;
  gameVariantExternalId?: InputMaybe<Scalars['String']['input']>;
  isDemoAllowed?: InputMaybe<Scalars['Boolean']['input']>;
  jurisdictionCategory?: InputMaybe<Scalars['String']['input']>;
  jurisdictionVariant?: InputMaybe<Scalars['String']['input']>;
  maxBetAmount?: InputMaybe<Scalars['Float']['input']>;
  minBetAmount?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  providerConfigId?: InputMaybe<Scalars['String']['input']>;
  reportingCategory?: InputMaybe<Scalars['String']['input']>;
  studio?: InputMaybe<Scalars['String']['input']>;
  theoreticalRTP?: InputMaybe<Scalars['Float']['input']>;
  version?: InputMaybe<Scalars['String']['input']>;
  versionedOn?: InputMaybe<Scalars['String']['input']>;
};

export type CasinoGameConfigCreateResponse = {
  __typename?: 'CasinoGameConfigCreateResponse';
  data?: Maybe<CasinoGameConfigCreateSuccess>;
  error?: Maybe<Array<CasinoConfigValidationErrors>>;
};

export type CasinoGameConfigCreateSuccess = {
  __typename?: 'CasinoGameConfigCreateSuccess';
  id: Scalars['ID']['output'];
};

export type CasinoGameConfigUpdateInput = {
  adminCategoryId?: InputMaybe<Scalars['String']['input']>;
  bonusContributionRatio?: InputMaybe<Scalars['Float']['input']>;
  certificateId?: InputMaybe<Scalars['String']['input']>;
  certificateName?: InputMaybe<Scalars['String']['input']>;
  currencyCode?: InputMaybe<Scalars['String']['input']>;
  gameCategoryId?: InputMaybe<Scalars['String']['input']>;
  gameExternalAlternateId?: InputMaybe<Scalars['String']['input']>;
  gameExternalId?: InputMaybe<Scalars['String']['input']>;
  gameVariantExternalId?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  isDemoAllowed?: InputMaybe<Scalars['Boolean']['input']>;
  jurisdictionCategory?: InputMaybe<Scalars['String']['input']>;
  jurisdictionVariant?: InputMaybe<Scalars['String']['input']>;
  maxBetAmount?: InputMaybe<Scalars['Float']['input']>;
  minBetAmount?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  providerConfigId?: InputMaybe<Scalars['String']['input']>;
  reportingCategory?: InputMaybe<Scalars['String']['input']>;
  studio?: InputMaybe<Scalars['String']['input']>;
  theoreticalRTP?: InputMaybe<Scalars['Float']['input']>;
  version?: InputMaybe<Scalars['String']['input']>;
  versionedOn?: InputMaybe<Scalars['String']['input']>;
};

export type CasinoGameConfigUpdateResponse = {
  __typename?: 'CasinoGameConfigUpdateResponse';
  data?: Maybe<CasinoGameConfig>;
  error?: Maybe<Array<CasinoConfigValidationErrors>>;
};

export type CasinoIntegrationConfig = {
  __typename?: 'CasinoIntegrationConfig';
  apiVersion?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  createdOn?: Maybe<Scalars['String']['output']>;
  creator?: Maybe<Scalars['String']['output']>;
  freeSpinsApiVersion?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  integrationId?: Maybe<Scalars['String']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  jurisdictionCodes: Array<Maybe<Scalars['String']['output']>>;
  lastUpdatedBy?: Maybe<Scalars['String']['output']>;
  lastUpdatedOn?: Maybe<Scalars['String']['output']>;
  lastUpdater?: Maybe<Scalars['String']['output']>;
  mediatorId?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  type?: Maybe<IntegrationType>;
};

export type CasinoIntegrationConfigCreateInput = {
  apiVersion?: InputMaybe<Scalars['String']['input']>;
  freeSpinsApiVersion?: InputMaybe<Scalars['String']['input']>;
  integrationId?: InputMaybe<Scalars['String']['input']>;
  jurisdictionCodes: Array<InputMaybe<Scalars['String']['input']>>;
  mediatorId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<IntegrationType>;
};

export type CasinoIntegrationConfigCreateResponse = {
  __typename?: 'CasinoIntegrationConfigCreateResponse';
  data?: Maybe<CasinoIntegrationConfigCreateSuccess>;
  error?: Maybe<Array<CasinoConfigValidationErrors>>;
};

export type CasinoIntegrationConfigCreateSuccess = {
  __typename?: 'CasinoIntegrationConfigCreateSuccess';
  id: Scalars['ID']['output'];
};

export type CasinoIntegrationConfigUpdateInput = {
  apiVersion?: InputMaybe<Scalars['String']['input']>;
  freeSpinsApiVersion?: InputMaybe<Scalars['String']['input']>;
  integrationId?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  jurisdictionCodes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<IntegrationType>;
};

export type CasinoIntegrationConfigUpdateResponse = {
  __typename?: 'CasinoIntegrationConfigUpdateResponse';
  data?: Maybe<CasinoIntegrationConfig>;
  error?: Maybe<Array<CasinoConfigValidationErrors>>;
};

export type CasinoPagedGameConfig = {
  __typename?: 'CasinoPagedGameConfig';
  count?: Maybe<Scalars['Float']['output']>;
  data?: Maybe<Array<Maybe<CasinoGameConfig>>>;
};

export type CasinoPagedIntegrationConfig = {
  __typename?: 'CasinoPagedIntegrationConfig';
  count?: Maybe<Scalars['Float']['output']>;
  data?: Maybe<Array<Maybe<CasinoIntegrationConfig>>>;
};

export type CasinoPagedProviderConfig = {
  __typename?: 'CasinoPagedProviderConfig';
  count?: Maybe<Scalars['Float']['output']>;
  data?: Maybe<Array<Maybe<CasinoProviderConfig>>>;
};

export type CasinoProviderConfig = {
  __typename?: 'CasinoProviderConfig';
  bonusContributionRatio?: Maybe<Scalars['Float']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  createdOn?: Maybe<Scalars['String']['output']>;
  creator?: Maybe<Scalars['String']['output']>;
  currencyCode?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  integrationConfig?: Maybe<CasinoIntegrationConfig>;
  integrationConfigId?: Maybe<Scalars['String']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  isDemoAllowed?: Maybe<Scalars['Boolean']['output']>;
  lastUpdatedBy?: Maybe<Scalars['String']['output']>;
  lastUpdatedOn?: Maybe<Scalars['String']['output']>;
  lastUpdater?: Maybe<Scalars['String']['output']>;
  launchArgs?: Maybe<Scalars['String']['output']>;
  launchContext?: Maybe<Scalars['String']['output']>;
  maxBetAmount?: Maybe<Scalars['Float']['output']>;
  mediatorId?: Maybe<Scalars['String']['output']>;
  minBetAmount?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};


export type CasinoProviderConfigIntegrationConfigArgs = {
  xTenantID: Scalars['ID']['input'];
};

export type CasinoProviderConfigCreateInput = {
  bonusContributionRatio?: InputMaybe<Scalars['Float']['input']>;
  currencyCode?: InputMaybe<Scalars['String']['input']>;
  integrationConfigId?: InputMaybe<Scalars['String']['input']>;
  isDemoAllowed?: InputMaybe<Scalars['Boolean']['input']>;
  launchArgs?: InputMaybe<Scalars['String']['input']>;
  launchContext?: InputMaybe<Scalars['String']['input']>;
  maxBetAmount?: InputMaybe<Scalars['Float']['input']>;
  minBetAmount?: InputMaybe<Scalars['Float']['input']>;
};

export type CasinoProviderConfigCreateResponse = {
  __typename?: 'CasinoProviderConfigCreateResponse';
  data?: Maybe<CasinoProviderConfigCreateSuccess>;
  error?: Maybe<Array<CasinoConfigValidationErrors>>;
};

export type CasinoProviderConfigCreateSuccess = {
  __typename?: 'CasinoProviderConfigCreateSuccess';
  id: Scalars['ID']['output'];
};

export type CasinoProviderConfigUpdateInput = {
  bonusContributionRatio?: InputMaybe<Scalars['Float']['input']>;
  currencyCode?: InputMaybe<Scalars['String']['input']>;
  integrationConfigId?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  isDemoAllowed?: InputMaybe<Scalars['Boolean']['input']>;
  launchArgs?: InputMaybe<Scalars['String']['input']>;
  launchContext?: InputMaybe<Scalars['String']['input']>;
  maxBetAmount?: InputMaybe<Scalars['Float']['input']>;
  minBetAmount?: InputMaybe<Scalars['Float']['input']>;
};

export type CasinoProviderConfigUpdateResponse = {
  __typename?: 'CasinoProviderConfigUpdateResponse';
  data?: Maybe<CasinoProviderConfig>;
  error?: Maybe<Array<CasinoConfigValidationErrors>>;
};

export type CloseSessionParamsInput = {
  errorCode?: InputMaybe<Scalars['String']['input']>;
  sessionContextId?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type CombinedLimits = BudgetLimitsTransactionResponse | LimitsMaxWagerResponse;

export type Compliance = {
  __typename?: 'Compliance';
  addressVerificationStatus?: Maybe<Scalars['String']['output']>;
  adverseMediaVerificationStatus?: Maybe<Scalars['String']['output']>;
  ageVerificationStatus?: Maybe<Scalars['String']['output']>;
  emailVerificationStatus?: Maybe<ProfileVerificationStatus>;
  idVerificationStatus?: Maybe<Scalars['String']['output']>;
  isDepositAllowed?: Maybe<Scalars['Boolean']['output']>;
  isLoginAllowed?: Maybe<Scalars['Boolean']['output']>;
  isMarketingAllowed?: Maybe<Scalars['Boolean']['output']>;
  isPEP?: Maybe<Scalars['Boolean']['output']>;
  isPromotionsAllowed?: Maybe<Scalars['Boolean']['output']>;
  isSanctioned?: Maybe<Scalars['Boolean']['output']>;
  isWageringAllowed?: Maybe<Scalars['Boolean']['output']>;
  isWithdrawAllowed?: Maybe<Scalars['Boolean']['output']>;
  kycLevel?: Maybe<Scalars['Int']['output']>;
  kycVerificationStatus?: Maybe<Scalars['String']['output']>;
  multipleAccountCheckStatus?: Maybe<Scalars['String']['output']>;
  pepVerificationStatus?: Maybe<Scalars['String']['output']>;
  phoneVerificationStatus?: Maybe<ProfileVerificationStatus>;
  riskScores?: Maybe<ProfileRiskScoresStatus>;
  sanctionVerificationStatus?: Maybe<Scalars['String']['output']>;
  selfExclusionCheckStatus?: Maybe<Scalars['String']['output']>;
  sourceOfFundsVerificationStatus?: Maybe<Scalars['String']['output']>;
  sourceOfWealthDocumentsVerificationStatus?: Maybe<Scalars['String']['output']>;
  sourceOfWealthVerificationStatus?: Maybe<Scalars['String']['output']>;
  vatCountryAlpha2Code?: Maybe<Scalars['String']['output']>;
};

export enum ConfigFilterType {
  Exclude = 'Exclude',
  Include = 'Include',
  None = 'None'
}

export type Consents = {
  __typename?: 'Consents';
  basicCookiesAllowed?: Maybe<Scalars['Boolean']['output']>;
  callAllowed?: Maybe<Scalars['String']['output']>;
  cookiePolicyStatus?: Maybe<Scalars['String']['output']>;
  emailAllowed?: Maybe<Scalars['String']['output']>;
  functionalCookiesAllowed?: Maybe<Scalars['Boolean']['output']>;
  lastCookiePolicyVersionAccepted?: Maybe<Scalars['String']['output']>;
  lastEmailSentUtc?: Maybe<Scalars['String']['output']>;
  lastPrivacyPolicyAcceptedOnUtc?: Maybe<Scalars['String']['output']>;
  lastPrivacyPolicyVersionAccepted?: Maybe<Scalars['String']['output']>;
  lastSMSSentUtc?: Maybe<Scalars['String']['output']>;
  lastSecurityPolicyVersionAccepted?: Maybe<Scalars['String']['output']>;
  lastTermsAndConditionsAcceptedOnUtc?: Maybe<Scalars['String']['output']>;
  lastTermsAndConditionsVersionAccepted?: Maybe<Scalars['String']['output']>;
  marketingAllowedFrom?: Maybe<Scalars['String']['output']>;
  marketingAllowedTo?: Maybe<Scalars['String']['output']>;
  performanceCookiesAllowed?: Maybe<Scalars['Boolean']['output']>;
  postalMailAllowed?: Maybe<Scalars['String']['output']>;
  privacyPolicyStatus?: Maybe<Scalars['String']['output']>;
  productMarketingAllowed?: Maybe<ProductMarketingAllowed>;
  pushNotificationAllowed?: Maybe<Scalars['String']['output']>;
  securityPolicyStatus?: Maybe<Scalars['String']['output']>;
  smsAllowed?: Maybe<Scalars['String']['output']>;
  termsAndConditionsStatus?: Maybe<Scalars['String']['output']>;
};

export enum ConsumeTypeStrategy {
  BonusMoneyFirst = 'BonusMoneyFirst',
  RealMoneyFirst = 'RealMoneyFirst'
}

export type CreateIdentityRoleInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type CreateIdentityRoleResponse = {
  __typename?: 'CreateIdentityRoleResponse';
  data?: Maybe<Scalars['JSON']['output']>;
  error?: Maybe<Array<IdentityValidationErrors>>;
};

export type CreateIdentityUserInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  familyName?: InputMaybe<Scalars['String']['input']>;
  givenName?: InputMaybe<Scalars['String']['input']>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type CreateIdentityUserResponse = {
  __typename?: 'CreateIdentityUserResponse';
  data?: Maybe<IdentityUserCreateSuccess>;
  error?: Maybe<Array<IdentityValidationErrors>>;
};

export type CreateLimitContinuousSuccess = {
  __typename?: 'CreateLimitContinuousSuccess';
  id: Scalars['ID']['output'];
};

export type CreateLimitSuccess = {
  __typename?: 'CreateLimitSuccess';
  id: Scalars['ID']['output'];
};

export enum CreatorName {
  BackofficeUser = 'BackofficeUser',
  External = 'External',
  Policy = 'Policy',
  User = 'User'
}

export type CustomGraphQlErrorExtensions = {
  __typename?: 'CustomGraphQLErrorExtensions';
  code?: Maybe<Scalars['String']['output']>;
  fieldErrors?: Maybe<Array<FieldError>>;
  statusCode?: Maybe<Scalars['Int']['output']>;
  traceId?: Maybe<Scalars['String']['output']>;
  traceresponse?: Maybe<Scalars['String']['output']>;
};

export type DataDocumentVerificationInput = {
  expiresOn?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<DocumentInitialStatus>;
};

export enum DataVerificationStatus {
  Failed = 'Failed',
  NotMatched = 'NotMatched',
  PartialVerified = 'PartialVerified',
  Pending = 'Pending',
  Verified = 'Verified'
}

/** A filter to be used against Datetime fields. All fields are combined with a logical ‘and.’ */
export type DatetimeFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['Datetime']['input']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['Datetime']['input']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['Datetime']['input']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['Datetime']['input']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['Datetime']['input']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['Datetime']['input']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['Datetime']['input']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['Datetime']['input']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['Datetime']['input']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['Datetime']['input']>>;
};

export type DelayCloseSessionParamsInput = {
  closeAtEpoch?: InputMaybe<Scalars['Int']['input']>;
  errorCode?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type DeleteIdentityRoleResponse = {
  __typename?: 'DeleteIdentityRoleResponse';
  data?: Maybe<Scalars['JSON']['output']>;
  error?: Maybe<Array<IdentityValidationErrors>>;
};

export type Document = {
  __typename?: 'Document';
  category?: Maybe<Scalars['String']['output']>;
  contentType?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  createdOn?: Maybe<Scalars['String']['output']>;
  creator?: Maybe<Scalars['String']['output']>;
  dataSource?: Maybe<Scalars['String']['output']>;
  dataValue?: Maybe<Scalars['String']['output']>;
  documentNumber?: Maybe<Scalars['String']['output']>;
  expiresOn?: Maybe<Scalars['String']['output']>;
  externalResource?: Maybe<Scalars['String']['output']>;
  externalResourceId?: Maybe<Scalars['String']['output']>;
  fileName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  lastUpdatedBy?: Maybe<Scalars['String']['output']>;
  lastUpdatedOn?: Maybe<Scalars['String']['output']>;
  lastUpdater?: Maybe<Scalars['String']['output']>;
  mediatorId?: Maybe<Scalars['String']['output']>;
  side?: Maybe<DocumentSide>;
  status?: Maybe<DocumentStatus>;
  type?: Maybe<Scalars['String']['output']>;
  userProfileId?: Maybe<Scalars['String']['output']>;
  verificationStatus?: Maybe<DocumentVerificationStatus>;
};

export type DocumentConfiguration = {
  __typename?: 'DocumentConfiguration';
  expirationRequired?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  numberRequired?: Maybe<Scalars['Boolean']['output']>;
  sides?: Maybe<Array<Side>>;
};

export type DocumentDataVerificationByIdUpdateResponse = {
  __typename?: 'DocumentDataVerificationByIdUpdateResponse';
  data?: Maybe<DocumentVerifications>;
  error?: Maybe<Array<DocumentsVerificationErrors>>;
};

export type DocumentDataVerificationUpdateInput = {
  expiresOn?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<DocumentInitialStatus>;
};

export type DocumentDataVerificationUpdateResponse = {
  __typename?: 'DocumentDataVerificationUpdateResponse';
  data?: Maybe<DocumentVerifications>;
  error?: Maybe<Array<DocumentsVerificationErrors>>;
};

export enum DocumentInitialStatus {
  Expired = 'Expired',
  Ignored = 'Ignored',
  Rejected = 'Rejected',
  Verified = 'Verified'
}

export type DocumentInput = {
  data?: InputMaybe<Scalars['String']['input']>;
  documentNumber?: InputMaybe<Scalars['String']['input']>;
  expiresOn?: InputMaybe<Scalars['String']['input']>;
  side?: InputMaybe<DocumentSide>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export enum DocumentMediatorType {
  BackofficeUser = 'BackofficeUser',
  External = 'External'
}

export type DocumentMetadataCreateErrors = FieldError | GenericError;

export type DocumentMetadataCreateResponse = {
  __typename?: 'DocumentMetadataCreateResponse';
  data?: Maybe<Scalars['Boolean']['output']>;
  error?: Maybe<Array<DocumentMetadataCreateErrors>>;
};

export type DocumentRequestInput = {
  category?: InputMaybe<Scalars['String']['input']>;
  externalResource?: InputMaybe<Scalars['String']['input']>;
  externalResourceId?: InputMaybe<Scalars['String']['input']>;
  side?: InputMaybe<DocumentRequestSide>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type DocumentRequestResponse = {
  __typename?: 'DocumentRequestResponse';
  data?: Maybe<DocumentRequestResponseSuccess>;
  error?: Maybe<Array<DocumentsVerificationErrors>>;
};

export type DocumentRequestResponseSuccess = {
  __typename?: 'DocumentRequestResponseSuccess';
  id: Scalars['ID']['output'];
};

export enum DocumentRequestSide {
  Back = 'Back',
  Front = 'Front',
  NotSet = 'NotSet'
}

export enum DocumentSide {
  Back = 'Back',
  Front = 'Front',
  NotSet = 'NotSet'
}

export enum DocumentStatus {
  Active = 'Active',
  Expired = 'Expired',
  Ignored = 'Ignored',
  Outdated = 'Outdated'
}

export type DocumentTypeConfiguration = {
  __typename?: 'DocumentTypeConfiguration';
  address?: Maybe<Array<Scalars['String']['output']>>;
  id?: Maybe<Array<Scalars['String']['output']>>;
  pop?: Maybe<Array<Scalars['String']['output']>>;
  sof?: Maybe<Array<Scalars['String']['output']>>;
  sow?: Maybe<Array<Scalars['String']['output']>>;
  sowd?: Maybe<Array<Scalars['String']['output']>>;
};

export type DocumentUpdateInput = {
  data?: InputMaybe<Scalars['String']['input']>;
  documentNumber?: InputMaybe<Scalars['String']['input']>;
  expiresOn?: InputMaybe<Scalars['String']['input']>;
  side?: InputMaybe<DocumentSide>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type DocumentUpdateResponse = {
  __typename?: 'DocumentUpdateResponse';
  data?: Maybe<Document>;
  error?: Maybe<Array<DocumentsVerificationErrors>>;
};

export type DocumentVerification = {
  __typename?: 'DocumentVerification';
  createdOn?: Maybe<Scalars['String']['output']>;
  documentId?: Maybe<Scalars['String']['output']>;
  expiresOn?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  mediatorId?: Maybe<Scalars['String']['output']>;
  mediatorType?: Maybe<DocumentMediatorType>;
  reason?: Maybe<Scalars['String']['output']>;
  status?: Maybe<VerificationStatus>;
  userProfileId?: Maybe<Scalars['String']['output']>;
};

export type DocumentVerificationInput = {
  documentId?: InputMaybe<Scalars['String']['input']>;
  expiresOn?: InputMaybe<Scalars['String']['input']>;
  mediatorId?: InputMaybe<Scalars['String']['input']>;
  mediatorType?: InputMaybe<DocumentMediatorType>;
  reason?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<DocumentInitialStatus>;
};

export type DocumentVerificationResponse = {
  __typename?: 'DocumentVerificationResponse';
  data?: Maybe<Scalars['Boolean']['output']>;
  error?: Maybe<Array<DocumentsVerificationErrors>>;
};

export enum DocumentVerificationStatus {
  Expired = 'Expired',
  Ignored = 'Ignored',
  Pending = 'Pending',
  Rejected = 'Rejected',
  Requested = 'Requested',
  Verified = 'Verified',
  VerifiedPartial = 'VerifiedPartial'
}

export type DocumentVerifications = {
  __typename?: 'DocumentVerifications';
  createdOn?: Maybe<Scalars['String']['output']>;
  expiresOn?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  status?: Maybe<DataVerificationStatus>;
  type?: Maybe<Scalars['String']['output']>;
  userProfileId?: Maybe<Scalars['String']['output']>;
};

export type Documents = {
  __typename?: 'Documents';
  /** Contains any extraneous properties defined on the content model */
  additionalProperties?: Maybe<Scalars['JSON']['output']>;
  content: Scalars['String']['output'];
  dateCreated: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastUpdated: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  title: Scalars['String']['output'];
  version: Scalars['String']['output'];
};

export type DocumentsResponse = {
  __typename?: 'DocumentsResponse';
  data?: Maybe<Scalars['Boolean']['output']>;
  error?: Maybe<Array<DocumentsVerificationErrors>>;
};

export type DocumentsUpdated = {
  __typename?: 'DocumentsUpdated';
  after?: Maybe<Endeavour_Verifications_Documents_Dot_Value2>;
  before?: Maybe<Endeavour_Verifications_Documents_Dot_Value>;
  op?: Maybe<Scalars['String']['output']>;
  source?: Maybe<Io_Dot_Debezium_Dot_Connector_Dot_Postgresql_Dot_Source>;
  transaction?: Maybe<Endeavour_Verifications_Documents_Slash__Slash_Properties_Slash_Transaction_Slash_OneOf_Slash_1>;
  tsMs?: Maybe<Scalars['BigInt']['output']>;
};

export type DocumentsVerificationErrors = FieldError | GenericError;

export enum DurationUnit {
  Days = 'DAYS',
  Hours = 'HOURS',
  Months = 'MONTHS'
}

export type Endeavour_Dot_RealityCheck_Dot_Messaging_Dot_Contracts_Dot_RealityCheck_Dot_Events_Dot_RealityCheckUpdatedRealityCheck = {
  __typename?: 'Endeavour_DOT_RealityCheck_DOT_Messaging_DOT_Contracts_DOT_RealityCheck_DOT_Events_DOT_RealityCheckUpdatedRealityCheck';
  elapsedCount?: Maybe<Scalars['Int']['output']>;
  interval?: Maybe<Scalars['String']['output']>;
  lastUpdatedOn?: Maybe<Scalars['String']['output']>;
  sessionContextIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  status?: Maybe<Scalars['String']['output']>;
};

export type Endeavour_Dot_RealityCheck_Dot_Messaging_Dot_Contracts_Dot_RealityCheck_Dot_Events_Dot_RealityCheckUpdatedRealityCheck2 = {
  __typename?: 'Endeavour_DOT_RealityCheck_DOT_Messaging_DOT_Contracts_DOT_RealityCheck_DOT_Events_DOT_RealityCheckUpdatedRealityCheck2';
  elapsedCount?: Maybe<Scalars['Int']['output']>;
  interval?: Maybe<Scalars['String']['output']>;
  lastUpdatedOn?: Maybe<Scalars['String']['output']>;
  sessionContextIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  status?: Maybe<Scalars['String']['output']>;
};

export type Endeavour_Dot_SessionContext_Dot_Messaging_Dot_Contracts_Dot_SessionContexts_Dot_Events_Dot_SessionContextUpdatedSessionContext = {
  __typename?: 'Endeavour_DOT_SessionContext_DOT_Messaging_DOT_Contracts_DOT_SessionContexts_DOT_Events_DOT_SessionContextUpdatedSessionContext';
  additionalProperties?: Maybe<Scalars['String']['output']>;
  errorCode?: Maybe<Scalars['String']['output']>;
  expiresOn?: Maybe<Scalars['String']['output']>;
  expiryExternalResource?: Maybe<Scalars['String']['output']>;
  expiryExternalResourceId?: Maybe<Scalars['String']['output']>;
  lastUpdatedOn?: Maybe<Scalars['String']['output']>;
  refreshesOn?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type Endeavour_Dot_SessionContext_Dot_Messaging_Dot_Contracts_Dot_SessionContexts_Dot_Events_Dot_SessionContextUpdatedSessionContext2 = {
  __typename?: 'Endeavour_DOT_SessionContext_DOT_Messaging_DOT_Contracts_DOT_SessionContexts_DOT_Events_DOT_SessionContextUpdatedSessionContext2';
  additionalProperties?: Maybe<Scalars['String']['output']>;
  errorCode?: Maybe<Scalars['String']['output']>;
  expiresOn?: Maybe<Scalars['String']['output']>;
  expiryExternalResource?: Maybe<Scalars['String']['output']>;
  expiryExternalResourceId?: Maybe<Scalars['String']['output']>;
  lastUpdatedOn?: Maybe<Scalars['String']['output']>;
  refreshesOn?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type Endeavour_Dot_UserProfile_Dot_Messaging_Dot_Contracts_Dot_Events_Dot_Address = {
  __typename?: 'Endeavour_DOT_UserProfile_DOT_Messaging_DOT_Contracts_DOT_Events_DOT_Address';
  addressCountry?: Maybe<Scalars['String']['output']>;
  addressLocality?: Maybe<Scalars['String']['output']>;
  addressRegion?: Maybe<Scalars['String']['output']>;
  postOfficeBoxNumber?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  streetAddress?: Maybe<Scalars['String']['output']>;
};

export type Endeavour_Dot_UserProfile_Dot_Messaging_Dot_Contracts_Dot_Events_Dot_Address2 = {
  __typename?: 'Endeavour_DOT_UserProfile_DOT_Messaging_DOT_Contracts_DOT_Events_DOT_Address2';
  addressCountry?: Maybe<Scalars['String']['output']>;
  addressLocality?: Maybe<Scalars['String']['output']>;
  addressRegion?: Maybe<Scalars['String']['output']>;
  postOfficeBoxNumber?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  streetAddress?: Maybe<Scalars['String']['output']>;
};

export type Endeavour_Dot_UserProfile_Dot_Messaging_Dot_Contracts_Dot_Events_Dot_Compliance = {
  __typename?: 'Endeavour_DOT_UserProfile_DOT_Messaging_DOT_Contracts_DOT_Events_DOT_Compliance';
  addressVerificationStatus?: Maybe<Scalars['String']['output']>;
  adverseMediaVerificationStatus?: Maybe<Scalars['String']['output']>;
  ageVerificationStatus?: Maybe<Scalars['String']['output']>;
  emailVerificationStatus?: Maybe<Endeavour_Dot_UserProfile_Dot_Messaging_Dot_Contracts_Dot_Events_Dot_VerificationStatus2>;
  idVerificationStatus?: Maybe<Scalars['String']['output']>;
  isDepositAllowed?: Maybe<Scalars['Boolean']['output']>;
  isLoginAllowed?: Maybe<Scalars['Boolean']['output']>;
  isMarketingAllowed?: Maybe<Scalars['Boolean']['output']>;
  isPEP?: Maybe<Scalars['Boolean']['output']>;
  isPromotionsAllowed?: Maybe<Scalars['Boolean']['output']>;
  isSanctioned?: Maybe<Scalars['Boolean']['output']>;
  isWageringAllowed?: Maybe<Scalars['Boolean']['output']>;
  isWithdrawAllowed?: Maybe<Scalars['Boolean']['output']>;
  kycLevel?: Maybe<Scalars['Int']['output']>;
  kycVerificationStatus?: Maybe<Scalars['String']['output']>;
  multipleAccountCheckStatus?: Maybe<Scalars['String']['output']>;
  pEPVerificationStatus?: Maybe<Scalars['String']['output']>;
  phoneVerificationStatus?: Maybe<Endeavour_Dot_UserProfile_Dot_Messaging_Dot_Contracts_Dot_Events_Dot_VerificationStatus>;
  sanctionVerificationStatus?: Maybe<Scalars['String']['output']>;
  selfExclusionCheckStatus?: Maybe<Scalars['String']['output']>;
  sourceOfWealthDocumentsVerificationStatus?: Maybe<Scalars['String']['output']>;
  sourceOfWealthVerificationStatus?: Maybe<Scalars['String']['output']>;
  vATCountryAlpha2Code?: Maybe<Scalars['String']['output']>;
};

export type Endeavour_Dot_UserProfile_Dot_Messaging_Dot_Contracts_Dot_Events_Dot_Consents = {
  __typename?: 'Endeavour_DOT_UserProfile_DOT_Messaging_DOT_Contracts_DOT_Events_DOT_Consents';
  basicCookiesAllowed?: Maybe<Scalars['Boolean']['output']>;
  callAllowed?: Maybe<Scalars['String']['output']>;
  cookiePolicyStatus?: Maybe<Scalars['String']['output']>;
  emailAllowed?: Maybe<Scalars['String']['output']>;
  fastEmailSentUtc?: Maybe<Scalars['String']['output']>;
  functionalCookiesAllowed?: Maybe<Scalars['Boolean']['output']>;
  lastCookiePolicyVersionAccepted?: Maybe<Scalars['String']['output']>;
  lastPrivacyPolicyAcceptedOnUtc?: Maybe<Scalars['String']['output']>;
  lastPrivacyPolicyVersionAccepted?: Maybe<Scalars['String']['output']>;
  lastSMSSentUtc?: Maybe<Scalars['String']['output']>;
  lastSecurityPolicyVersionAccepted?: Maybe<Scalars['String']['output']>;
  lastTermsAndConditionsAcceptedOnUtc?: Maybe<Scalars['String']['output']>;
  lastTermsAndConditionsVersionAccepted?: Maybe<Scalars['String']['output']>;
  marketingAllowedFrom?: Maybe<Scalars['String']['output']>;
  marketingAllowedTo?: Maybe<Scalars['String']['output']>;
  performanceCookiesAllowed?: Maybe<Scalars['Boolean']['output']>;
  postalMailAllowed?: Maybe<Scalars['String']['output']>;
  privacyPolicyStatus?: Maybe<Scalars['String']['output']>;
  pushNotificationAllowed?: Maybe<Scalars['String']['output']>;
  sMSAllowed?: Maybe<Scalars['String']['output']>;
  securityPolicyStatus?: Maybe<Scalars['String']['output']>;
  termsAndConditionsStatus?: Maybe<Scalars['String']['output']>;
};

export type Endeavour_Dot_UserProfile_Dot_Messaging_Dot_Contracts_Dot_Events_Dot_Marketing = {
  __typename?: 'Endeavour_DOT_UserProfile_DOT_Messaging_DOT_Contracts_DOT_Events_DOT_Marketing';
  agent?: Maybe<Scalars['String']['output']>;
  campaign?: Maybe<Scalars['String']['output']>;
  content?: Maybe<Scalars['String']['output']>;
  medium?: Maybe<Scalars['String']['output']>;
  payload?: Maybe<Scalars['String']['output']>;
  source?: Maybe<Scalars['String']['output']>;
  term?: Maybe<Scalars['String']['output']>;
};

export type Endeavour_Dot_UserProfile_Dot_Messaging_Dot_Contracts_Dot_Events_Dot_ObjectTag = {
  __typename?: 'Endeavour_DOT_UserProfile_DOT_Messaging_DOT_Contracts_DOT_Events_DOT_ObjectTag';
  objectTagId?: Maybe<Scalars['String']['output']>;
  tagName?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type Endeavour_Dot_UserProfile_Dot_Messaging_Dot_Contracts_Dot_Events_Dot_Person = {
  __typename?: 'Endeavour_DOT_UserProfile_DOT_Messaging_DOT_Contracts_DOT_Events_DOT_Person';
  additionalName?: Maybe<Scalars['String']['output']>;
  address?: Maybe<Endeavour_Dot_UserProfile_Dot_Messaging_Dot_Contracts_Dot_Events_Dot_Address2>;
  birthDate?: Maybe<Scalars['String']['output']>;
  birthPlace?: Maybe<Endeavour_Dot_UserProfile_Dot_Messaging_Dot_Contracts_Dot_Events_Dot_Address>;
  deathDate?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  familyName?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Scalars['String']['output']>;
  givenName?: Maybe<Scalars['String']['output']>;
  honorificPrefix?: Maybe<Scalars['String']['output']>;
  honorificSuffix?: Maybe<Scalars['String']['output']>;
  jobTitle?: Maybe<Scalars['String']['output']>;
  knowsLanguage?: Maybe<Scalars['String']['output']>;
  mobileNumber?: Maybe<Endeavour_Dot_UserProfile_Dot_Messaging_Dot_Contracts_Dot_Events_Dot_PhoneNumber2>;
  nationality?: Maybe<Scalars['String']['output']>;
  protectedTaxId?: Maybe<Scalars['String']['output']>;
  spouseId?: Maybe<Scalars['String']['output']>;
  taxId?: Maybe<Scalars['String']['output']>;
  telephoneNumber?: Maybe<Endeavour_Dot_UserProfile_Dot_Messaging_Dot_Contracts_Dot_Events_Dot_PhoneNumber>;
  username?: Maybe<Scalars['String']['output']>;
  vATId?: Maybe<Scalars['String']['output']>;
};

export type Endeavour_Dot_UserProfile_Dot_Messaging_Dot_Contracts_Dot_Events_Dot_PhoneNumber = {
  __typename?: 'Endeavour_DOT_UserProfile_DOT_Messaging_DOT_Contracts_DOT_Events_DOT_PhoneNumber';
  number?: Maybe<Scalars['String']['output']>;
  prefix?: Maybe<Scalars['String']['output']>;
};

export type Endeavour_Dot_UserProfile_Dot_Messaging_Dot_Contracts_Dot_Events_Dot_PhoneNumber2 = {
  __typename?: 'Endeavour_DOT_UserProfile_DOT_Messaging_DOT_Contracts_DOT_Events_DOT_PhoneNumber2';
  number?: Maybe<Scalars['String']['output']>;
  prefix?: Maybe<Scalars['String']['output']>;
};

export type Endeavour_Dot_UserProfile_Dot_Messaging_Dot_Contracts_Dot_Events_Dot_UserProfile = {
  __typename?: 'Endeavour_DOT_UserProfile_DOT_Messaging_DOT_Contracts_DOT_Events_DOT_UserProfile';
  accountStatus?: Maybe<Scalars['String']['output']>;
  additionalData?: Maybe<User_Profile_Updated_Slash__Slash_Properties_Slash_Current_Slash_Properties_Slash_AdditionalData_Slash_OneOf_Slash_1>;
  compliance?: Maybe<Endeavour_Dot_UserProfile_Dot_Messaging_Dot_Contracts_Dot_Events_Dot_Compliance>;
  consents?: Maybe<Endeavour_Dot_UserProfile_Dot_Messaging_Dot_Contracts_Dot_Events_Dot_Consents>;
  identityProviderExternalId?: Maybe<Scalars['String']['output']>;
  isTestUser?: Maybe<Scalars['Boolean']['output']>;
  jurisdictionCode?: Maybe<Scalars['String']['output']>;
  lastUpdatedOn?: Maybe<Scalars['String']['output']>;
  marketing?: Maybe<Endeavour_Dot_UserProfile_Dot_Messaging_Dot_Contracts_Dot_Events_Dot_Marketing>;
  objectTags?: Maybe<Array<Maybe<Endeavour_Dot_UserProfile_Dot_Messaging_Dot_Contracts_Dot_Events_Dot_ObjectTag>>>;
  optedInCampaignIdentifiers?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  riskLevel?: Maybe<Scalars['Int']['output']>;
  user?: Maybe<Endeavour_Dot_UserProfile_Dot_Messaging_Dot_Contracts_Dot_Events_Dot_Person>;
};

export type Endeavour_Dot_UserProfile_Dot_Messaging_Dot_Contracts_Dot_Events_Dot_VerificationStatus = {
  __typename?: 'Endeavour_DOT_UserProfile_DOT_Messaging_DOT_Contracts_DOT_Events_DOT_VerificationStatus';
  code?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type Endeavour_Dot_UserProfile_Dot_Messaging_Dot_Contracts_Dot_Events_Dot_VerificationStatus2 = {
  __typename?: 'Endeavour_DOT_UserProfile_DOT_Messaging_DOT_Contracts_DOT_Events_DOT_VerificationStatus2';
  code?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type ExtendSuspensionLimitInput = {
  duration?: InputMaybe<Scalars['String']['input']>;
  intent: SuspensionLimitsType;
};

export type ExtendSuspensionLimitResponse = {
  __typename?: 'ExtendSuspensionLimitResponse';
  data?: Maybe<SuspensionLimitsResponse>;
  error?: Maybe<Array<SuspensionLimitsValidationErrors>>;
};

export enum FeeMode {
  AddedToAmount = 'AddedToAmount',
  DeductedFromAmount = 'DeductedFromAmount',
  NotSet = 'NotSet'
}

export type FeedEvent = {
  __typename?: 'FeedEvent';
  /** @deprecated No longer in use */
  displayCategory?: Maybe<Scalars['String']['output']>;
  feedType?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isPerformanceTest?: Maybe<Scalars['Boolean']['output']>;
  isTest?: Maybe<Scalars['Boolean']['output']>;
  lastUpdatedOn?: Maybe<Scalars['String']['output']>;
  metaData?: Maybe<Scalars['JSON']['output']>;
  payload?: Maybe<Scalars['JSON']['output']>;
  tenantId?: Maybe<Scalars['String']['output']>;
  userProfileId?: Maybe<Scalars['String']['output']>;
};

export type FeedType = {
  __typename?: 'FeedType';
  feedType?: Maybe<Scalars['String']['output']>;
};

/**
 * A condition to be used against `FeedType` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type FeedTypeCondition = {
  /** Checks for equality with the object’s `feedType` field. */
  feedType?: InputMaybe<Scalars['String']['input']>;
};

/** A filter to be used against `FeedType` object types. All fields are combined with a logical ‘and.’ */
export type FeedTypeFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FeedTypeFilter>>;
  /** Filter by the object’s `feedType` field. */
  feedType?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<FeedTypeFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FeedTypeFilter>>;
};

/** A connection to a list of `FeedType` values. */
export type FeedTypesConnection = {
  __typename?: 'FeedTypesConnection';
  /** A list of edges which contains the `FeedType` and cursor to aid in pagination. */
  edges: Array<FeedTypesEdge>;
  /** A list of `FeedType` objects. */
  nodes: Array<Maybe<FeedType>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FeedType` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `FeedType` edge in the connection. */
export type FeedTypesEdge = {
  __typename?: 'FeedTypesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `FeedType` at the end of the edge. */
  node?: Maybe<FeedType>;
};

/** Methods to use when ordering `FeedType`. */
export enum FeedTypesOrderBy {
  FeedTypeAsc = 'FEED_TYPE_ASC',
  FeedTypeDesc = 'FEED_TYPE_DESC',
  Natural = 'NATURAL'
}

export type FieldError = {
  __typename?: 'FieldError';
  code: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  fieldName: Scalars['String']['output'];
  kind: Array<Maybe<Scalars['String']['output']>>;
  pattern?: Maybe<Scalars['String']['output']>;
};

export enum FilterInputTypes {
  Boolean = 'BOOLEAN',
  Datetime = 'DATETIME',
  Guid = 'GUID',
  List = 'LIST',
  Number = 'NUMBER',
  String = 'STRING',
  Substring = 'SUBSTRING'
}

export type Filters = {
  __typename?: 'Filters';
  authenticationStatus?: Maybe<AuthenticationStatus>;
  countries?: Maybe<Array<Scalars['String']['output']>>;
  devices?: Maybe<Array<Scalars['String']['output']>>;
  endDate?: Maybe<Scalars['Float']['output']>;
  jurisdictions?: Maybe<Array<Scalars['String']['output']>>;
  startDate?: Maybe<Scalars['Float']['output']>;
  tags?: Maybe<Array<Scalars['String']['output']>>;
};

export type FiltersValues = {
  operator?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<FilterInputTypes>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type FooterCollection = {
  __typename?: 'FooterCollection';
  filters: Filters;
  id?: Maybe<Scalars['ID']['output']>;
  items?: Maybe<Array<Maybe<FooterItem>>>;
  sort?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type FooterIcon = {
  __typename?: 'FooterIcon';
  /** Contains any extraneous properties defined on the content model */
  additionalProperties?: Maybe<Scalars['JSON']['output']>;
  asset?: Maybe<Scalars['String']['output']>;
  filters: Filters;
  id?: Maybe<Scalars['ID']['output']>;
};

export type FooterItem = {
  __typename?: 'FooterItem';
  /** Contains any extraneous properties defined on the content model */
  additionalProperties?: Maybe<Scalars['JSON']['output']>;
  filters: Filters;
  icons?: Maybe<Array<Maybe<FooterIcon>>>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  openInNewTab?: Maybe<Scalars['Boolean']['output']>;
  sort?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type FreeBetContent = {
  __typename?: 'FreeBetContent';
  /** Contains any extraneous properties defined on the content model */
  additionalProperties?: Maybe<Scalars['JSON']['output']>;
  description: Scalars['String']['output'];
  filters: Filters;
  image?: Maybe<Scalars['String']['output']>;
  subtitle: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type FreeBetPagedTemplates = {
  __typename?: 'FreeBetPagedTemplates';
  count?: Maybe<Scalars['Float']['output']>;
  data?: Maybe<Array<Maybe<FreeBetTemplate>>>;
};

export type FreeBetTemplate = {
  __typename?: 'FreeBetTemplate';
  assignedFor?: Maybe<Scalars['String']['output']>;
  bonusTemplateId?: Maybe<Scalars['String']['output']>;
  conversionMaxAmount?: Maybe<Scalars['Float']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  createdOn?: Maybe<Scalars['String']['output']>;
  creator?: Maybe<Scalars['String']['output']>;
  currencyCode?: Maybe<Scalars['String']['output']>;
  externalProperties?: Maybe<Scalars['JSON']['output']>;
  grantingAmount?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isUsed?: Maybe<Scalars['Boolean']['output']>;
  lastUpdatedBy?: Maybe<Scalars['String']['output']>;
  lastUpdatedOn?: Maybe<Scalars['String']['output']>;
  lastUpdater?: Maybe<Scalars['String']['output']>;
  mediatorId?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type FreeBetTemplateCreateInput = {
  assignedFor?: InputMaybe<Scalars['String']['input']>;
  bonusTemplateId?: InputMaybe<Scalars['String']['input']>;
  conversionMaxAmount?: InputMaybe<Scalars['Float']['input']>;
  currencyCode?: InputMaybe<Scalars['String']['input']>;
  externalProperties?: InputMaybe<Scalars['JSON']['input']>;
  grantingAmount?: InputMaybe<Scalars['Float']['input']>;
  mediatorId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type FreeBetTemplateCreateResponse = {
  __typename?: 'FreeBetTemplateCreateResponse';
  data?: Maybe<FreeBetTemplateCreateSuccess>;
  error?: Maybe<Array<FreeBetValidationErrors>>;
};

export type FreeBetTemplateCreateSuccess = {
  __typename?: 'FreeBetTemplateCreateSuccess';
  id: Scalars['ID']['output'];
};

export type FreeBetTemplateUpdateInput = {
  assignedFor?: InputMaybe<Scalars['String']['input']>;
  bonusTemplateId?: InputMaybe<Scalars['String']['input']>;
  conversionMaxAmount?: InputMaybe<Scalars['Float']['input']>;
  currencyCode?: InputMaybe<Scalars['String']['input']>;
  externalProperties?: InputMaybe<Scalars['JSON']['input']>;
  grantingAmount?: InputMaybe<Scalars['Float']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  mediatorId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type FreeBetTemplateUpdateResponse = {
  __typename?: 'FreeBetTemplateUpdateResponse';
  data?: Maybe<FreeBetTemplate>;
  error?: Maybe<Array<FreeBetValidationErrors>>;
};

export type FreeBetValidationErrors = FieldError | GenericError;

export type FreeSpinContent = {
  __typename?: 'FreeSpinContent';
  /** Contains any extraneous properties defined on the content model */
  additionalProperties?: Maybe<Scalars['JSON']['output']>;
  description: Scalars['String']['output'];
  filters: Filters;
  image?: Maybe<Scalars['String']['output']>;
  subtitle: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type FreeSpinPagedTemplates = {
  __typename?: 'FreeSpinPagedTemplates';
  count?: Maybe<Scalars['Float']['output']>;
  data?: Maybe<Array<Maybe<FreeSpinTemplate>>>;
};

export type FreeSpinTemplate = {
  __typename?: 'FreeSpinTemplate';
  assignedFor?: Maybe<Scalars['String']['output']>;
  betAmount?: Maybe<Scalars['Float']['output']>;
  bonusTemplateId?: Maybe<Scalars['String']['output']>;
  conversionMaxAmount?: Maybe<Scalars['Float']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  createdOn?: Maybe<Scalars['String']['output']>;
  creator?: Maybe<Scalars['String']['output']>;
  currencyCode?: Maybe<Scalars['String']['output']>;
  externalProperties?: Maybe<Scalars['JSON']['output']>;
  gameConfigId?: Maybe<Scalars['String']['output']>;
  grantingAmount?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isUsed?: Maybe<Scalars['Boolean']['output']>;
  lastUpdatedBy?: Maybe<Scalars['String']['output']>;
  lastUpdatedOn?: Maybe<Scalars['String']['output']>;
  lastUpdater?: Maybe<Scalars['String']['output']>;
  mediatorId?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  spinsCount?: Maybe<Scalars['Float']['output']>;
};

export type FreeSpinTemplateCreateInput = {
  assignedFor?: InputMaybe<Scalars['String']['input']>;
  betAmount?: InputMaybe<Scalars['Float']['input']>;
  bonusTemplateId?: InputMaybe<Scalars['String']['input']>;
  conversionMaxAmount?: InputMaybe<Scalars['Float']['input']>;
  currencyCode?: InputMaybe<Scalars['String']['input']>;
  externalProperties?: InputMaybe<Scalars['JSON']['input']>;
  gameConfigId?: InputMaybe<Scalars['String']['input']>;
  mediatorId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  spinsCount?: InputMaybe<Scalars['Float']['input']>;
};

export type FreeSpinTemplateCreateResponse = {
  __typename?: 'FreeSpinTemplateCreateResponse';
  data?: Maybe<FreeSpinTemplateCreateSuccess>;
  error?: Maybe<Array<FreeSpinValidationErrors>>;
};

export type FreeSpinTemplateCreateSuccess = {
  __typename?: 'FreeSpinTemplateCreateSuccess';
  id: Scalars['ID']['output'];
};

export type FreeSpinTemplateUpdateInput = {
  assignedFor?: InputMaybe<Scalars['String']['input']>;
  betAmount?: InputMaybe<Scalars['Float']['input']>;
  bonusTemplateId?: InputMaybe<Scalars['String']['input']>;
  conversionMaxAmount?: InputMaybe<Scalars['Float']['input']>;
  currencyCode?: InputMaybe<Scalars['String']['input']>;
  externalProperties?: InputMaybe<Scalars['JSON']['input']>;
  gameConfigId?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  mediatorId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  spinsCount?: InputMaybe<Scalars['Float']['input']>;
};

export type FreeSpinTemplateUpdateResponse = {
  __typename?: 'FreeSpinTemplateUpdateResponse';
  data?: Maybe<FreeSpinTemplate>;
  error?: Maybe<Array<FreeSpinValidationErrors>>;
};

export type FreeSpinValidationErrors = FieldError | GenericError;

export type Game = {
  __typename?: 'Game';
  /** Contains any extraneous properties defined on the content model */
  additionalProperties?: Maybe<Scalars['JSON']['output']>;
  alternateGameId?: Maybe<Scalars['String']['output']>;
  category?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  descriptionTitle?: Maybe<Scalars['String']['output']>;
  desktopAspectRatio: AspectRatio;
  filters: Filters;
  gameId: Scalars['String']['output'];
  gameTags: Array<GameTags>;
  gameVendor: GameVendor;
  gameplayBackground?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isLobby: Scalars['Boolean']['output'];
  keywords?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  metatags?: Maybe<MetaTags>;
  name: Scalars['String']['output'];
  rating: Scalars['Int']['output'];
  ratingDescription?: Maybe<Scalars['String']['output']>;
  rtp?: Maybe<Scalars['String']['output']>;
  slug: Scalars['String']['output'];
  sort: Scalars['Int']['output'];
  structuredData?: Maybe<ProductMetadata>;
  thumbnails: Array<Thumbnail>;
};


export type GameMetatagsArgs = {
  locale: Scalars['String']['input'];
  skinId: Scalars['String']['input'];
  xTenantID: Scalars['String']['input'];
};


export type GameStructuredDataArgs = {
  locale: Scalars['String']['input'];
  skinId: Scalars['String']['input'];
  xTenantID: Scalars['String']['input'];
};

export type GameCategories = {
  __typename?: 'GameCategories';
  /** Contains any extraneous properties defined on the content model */
  additionalProperties?: Maybe<Scalars['JSON']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  filters: Filters;
  games?: Maybe<Array<Game>>;
  id: Scalars['ID']['output'];
  image?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  sort?: Maybe<Scalars['Int']['output']>;
  subCategories?: Maybe<Array<GameCategories>>;
  type?: Maybe<Scalars['String']['output']>;
};

export type GameCategoriesByLobbyId = {
  __typename?: 'GameCategoriesByLobbyId';
  gameCategories: Array<GameCategoryById>;
};

export type GameCategory = {
  __typename?: 'GameCategory';
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type GameCategoryById = {
  __typename?: 'GameCategoryById';
  id: Scalars['ID']['output'];
  image?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  subCategories?: Maybe<Array<GameCategoryById>>;
};

export type GameLaunchDetails = {
  countryCode: Scalars['String']['input'];
  currencyCode: Scalars['String']['input'];
  isDemoPlay: Scalars['Boolean']['input'];
  isMobile: Scalars['Boolean']['input'];
  language: Scalars['String']['input'];
  origin: Scalars['String']['input'];
  userProfileId?: InputMaybe<Scalars['String']['input']>;
};

export type GameLaunchError = {
  __typename?: 'GameLaunchError';
  errors?: Maybe<Array<GameLaunchErrorValue>>;
  internalGameId: Scalars['String']['output'];
};

export type GameLaunchErrorValue = {
  __typename?: 'GameLaunchErrorValue';
  code?: Maybe<Scalars['String']['output']>;
  key?: Maybe<Scalars['String']['output']>;
};

export type GameLaunchGame = {
  internalGameId: Scalars['String']['input'];
};

export type GameLaunchResponse = GameLaunchError | GameLaunchSuccess;

export type GameLaunchSuccess = {
  __typename?: 'GameLaunchSuccess';
  externalGameId: Scalars['String']['output'];
  internalGameId: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type GameTags = {
  __typename?: 'GameTags';
  /** Contains any extraneous properties defined on the content model */
  additionalProperties?: Maybe<Scalars['JSON']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  name: Scalars['String']['output'];
  sort: Scalars['Int']['output'];
  variant: Scalars['String']['output'];
};

export type GameVendor = {
  __typename?: 'GameVendor';
  /** Contains any extraneous properties defined on the content model */
  additionalProperties?: Maybe<Scalars['JSON']['output']>;
  id: Scalars['ID']['output'];
  logo?: Maybe<Scalars['String']['output']>;
  multiplayEnabled: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
};

export type GamesResponse = {
  __typename?: 'GamesResponse';
  count: Scalars['Int']['output'];
  games: Array<Game>;
};

export type GamingConfiguration = {
  __typename?: 'GamingConfiguration';
  casino: CasinoConfiguration;
};

export type GeneratedReport = {
  __typename?: 'GeneratedReport';
  html: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type GeneratedReportError = {
  __typename?: 'GeneratedReportError';
  errorCode?: Maybe<Scalars['String']['output']>;
  errorMessage?: Maybe<Scalars['String']['output']>;
};

export type GeneratedReportResponse = GeneratedReport | GeneratedReportError;

export type GenericError = {
  __typename?: 'GenericError';
  code: Scalars['String']['output'];
};

export type GenericSuccessResponse = {
  __typename?: 'GenericSuccessResponse';
  id: Scalars['ID']['output'];
};

export type GeoLocationResponse = {
  __typename?: 'GeoLocationResponse';
  isAllowed: Scalars['Boolean']['output'];
};

export type GeoProviderConfig = {
  __typename?: 'GeoProviderConfig';
  provider: GeolocationProvider;
};

export enum GeolocationProvider {
  None = 'NONE',
  Xpoint = 'XPOINT'
}

export type Granting = {
  __typename?: 'Granting';
  amount?: Maybe<Scalars['Float']['output']>;
  type?: Maybe<GrantingType>;
};

export type GrantingInput = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  type?: InputMaybe<GrantingType>;
};

export type GrantingPromotional = {
  __typename?: 'GrantingPromotional';
  amount?: Maybe<Scalars['Float']['output']>;
  type?: Maybe<GrantingType>;
};

export type GrantingPromotionalInput = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  type?: InputMaybe<GrantingType>;
};

export enum GrantingType {
  Absolute = 'Absolute',
  Ratio = 'Ratio'
}

export type IdentityRoleForIdentityUserCreateSuccess = {
  __typename?: 'IdentityRoleForIdentityUserCreateSuccess';
  id: Scalars['ID']['output'];
};

export type IdentityRolesValues = {
  __typename?: 'IdentityRolesValues';
  attributes?: Maybe<Scalars['JSON']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type IdentityUserCreateSuccess = {
  __typename?: 'IdentityUserCreateSuccess';
  id: Scalars['ID']['output'];
};

export type IdentityValidationErrors = FieldError | GenericError;

export type IgnoreUploadedDocumentRequestResponse = {
  __typename?: 'IgnoreUploadedDocumentRequestResponse';
  data?: Maybe<IgnoreUploadedDocumentResponseSuccess>;
  error?: Maybe<Array<DocumentsVerificationErrors>>;
};

export type IgnoreUploadedDocumentResponseSuccess = {
  __typename?: 'IgnoreUploadedDocumentResponseSuccess';
  id: Scalars['ID']['output'];
};

export type InovapayLaunchInput = {
  /** The Bank Account. Required only if externalPaymentMethodId is not “pix”. */
  account?: InputMaybe<Scalars['String']['input']>;
  /** The Bank Account Type. Required only if externalPaymentMethodId is not “pix”. */
  accountType?: InputMaybe<Scalars['String']['input']>;
  /** The transaction amount */
  amount: Scalars['Float']['input'];
  /** The Bank Branch. Required only if externalPaymentMethodId is not “pix”. */
  branch?: InputMaybe<Scalars['String']['input']>;
  /** The launch context */
  context?: InputMaybe<Scalars['String']['input']>;
  /** Currency code of the transaction */
  currencyCode: Scalars['String']['input'];
  /** The users email who is making the payment */
  email: Scalars['String']['input'];
  /** The Payment Methods External Id */
  externalPaymentMethodId: Scalars['String']['input'];
  /** Language code relayed to inovapay */
  languageCode: Scalars['String']['input'];
  /** The URL where the cashier will redirect to after completion (success or failure) */
  returnUrl?: InputMaybe<Scalars['String']['input']>;
  /** Session context ID retrieved from the cashier launch endpoint */
  sessionContextId: Scalars['ID']['input'];
};

export type InovapayLaunchResponse = {
  __typename?: 'InovapayLaunchResponse';
  /** The launch URL for the cashier (usually not sent if the transaction was a withdrawal) */
  cashierLaunchUrl?: Maybe<Scalars['String']['output']>;
  sessionContextId?: Maybe<Scalars['ID']['output']>;
};

export enum InovapayMethodType {
  Deposit = 'Deposit',
  Withdrawal = 'Withdrawal'
}

export type InsightsSheet = {
  __typename?: 'InsightsSheet';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type InsightsTokenDetails = {
  __typename?: 'InsightsTokenDetails';
  token: Scalars['String']['output'];
  url: Scalars['String']['output'];
  xrfKey: Scalars['String']['output'];
};

export enum IntegrationType {
  Social = 'Social',
  Standard = 'Standard'
}

/** A filter to be used against JSON fields. All fields are combined with a logical ‘and.’ */
export type JsonFilter = {
  /** Contained by the specified JSON. */
  containedBy?: InputMaybe<Scalars['JSON']['input']>;
  /** Contains the specified JSON. */
  contains?: InputMaybe<Scalars['JSON']['input']>;
  /** Contains all of the specified keys. */
  containsAllKeys?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Contains any of the specified keys. */
  containsAnyKeys?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Contains the specified key. */
  containsKey?: InputMaybe<Scalars['String']['input']>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['JSON']['input']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['JSON']['input']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['JSON']['input']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['JSON']['input']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['JSON']['input']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['JSON']['input']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['JSON']['input']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['JSON']['input']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['JSON']['input']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['JSON']['input']>>;
};

export type KnownLanguage = {
  __typename?: 'KnownLanguage';
  display_name: Scalars['String']['output'];
  language_code: Scalars['String']['output'];
  sort?: Maybe<Scalars['Int']['output']>;
};

export type Kpis = {
  __typename?: 'Kpis';
  AmountWagered?: Maybe<Scalars['String']['output']>;
  BonusCost?: Maybe<Scalars['String']['output']>;
  Deposits?: Maybe<Scalars['String']['output']>;
  NGR?: Maybe<Scalars['String']['output']>;
  NetDeposits?: Maybe<Scalars['String']['output']>;
  RTP?: Maybe<Scalars['String']['output']>;
  TotalWinnings?: Maybe<Scalars['String']['output']>;
  Withdrawals?: Maybe<Scalars['String']['output']>;
};

export type Languages = {
  __typename?: 'Languages';
  display_name: Scalars['String']['output'];
  language_code: Scalars['String']['output'];
  sort?: Maybe<Scalars['Int']['output']>;
};

export type LastLogin = {
  __typename?: 'LastLogin';
  countryCode?: Maybe<Scalars['String']['output']>;
  device?: Maybe<Scalars['String']['output']>;
  ipAddress?: Maybe<Scalars['String']['output']>;
  isMobile?: Maybe<Scalars['Boolean']['output']>;
  lastLoginOn?: Maybe<Scalars['String']['output']>;
};

export type Layout = {
  __typename?: 'Layout';
  /** Contains any extraneous properties defined on the content model */
  additionalProperties?: Maybe<Scalars['JSON']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  variant: Scalars['String']['output'];
};

export type LimitConfiguration = {
  __typename?: 'LimitConfiguration';
  createLimit?: Maybe<LimitConfigurationDetail>;
  extendLimit?: Maybe<LimitConfigurationDetail>;
};

export type LimitConfigurationDetail = {
  __typename?: 'LimitConfigurationDetail';
  durationFrom?: Maybe<Scalars['Int']['output']>;
  durationTo?: Maybe<Scalars['Int']['output']>;
  isExtendable?: Maybe<Scalars['Boolean']['output']>;
  unit?: Maybe<DurationUnit>;
};

export type LimitsConfiguration = {
  __typename?: 'LimitsConfiguration';
  selfExclusion?: Maybe<LimitConfiguration>;
  timeout?: Maybe<LimitConfiguration>;
};

export type LimitsMaxWagerCreateInput = {
  amount: Scalars['Float']['input'];
  createdBy?: InputMaybe<Scalars['String']['input']>;
  currencyCode: Scalars['String']['input'];
  mediatorType: Scalars['String']['input'];
  reason?: InputMaybe<Scalars['String']['input']>;
};

export type LimitsMaxWagerResponse = {
  __typename?: 'LimitsMaxWagerResponse';
  activationStatus?: Maybe<ActivationStatus>;
  amount?: Maybe<Scalars['Float']['output']>;
  changeCooldown?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  createdOn?: Maybe<Scalars['String']['output']>;
  creator?: Maybe<Scalars['String']['output']>;
  currencyCode?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isCancellable?: Maybe<Scalars['Boolean']['output']>;
  isPendingCancellation?: Maybe<Scalars['Boolean']['output']>;
  lastUpdatedOn?: Maybe<Scalars['String']['output']>;
  mediatorType?: Maybe<Scalars['String']['output']>;
  pendingChangeExpectedOn?: Maybe<Scalars['String']['output']>;
  userProfileId?: Maybe<Scalars['ID']['output']>;
};

export type LimitsMaxWagerReview = {
  confirmed?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LimitsMaxWageringUpdate = {
  __typename?: 'LimitsMaxWageringUpdate';
  after?: Maybe<Endeavour_Limits_Max_Wager_Limits_Dot_Value2>;
  before?: Maybe<Endeavour_Limits_Max_Wager_Limits_Dot_Value>;
};

export type LimitsSessionContinuousUpdate = {
  __typename?: 'LimitsSessionContinuousUpdate';
  after?: Maybe<Endeavour_Limits_Continuous_Session_Limits_Dot_Value2>;
  before?: Maybe<Endeavour_Limits_Continuous_Session_Limits_Dot_Value>;
};

export type LimitsSessionCumulativeUpdate = {
  __typename?: 'LimitsSessionCumulativeUpdate';
  after?: Maybe<Endeavour_Limits_Cumulative_Session_Limits_Dot_Value2>;
  before?: Maybe<Endeavour_Limits_Cumulative_Session_Limits_Dot_Value>;
};

export type LimitsSuspensionUpdate = {
  __typename?: 'LimitsSuspensionUpdate';
  after?: Maybe<Endeavour_Limits_Suspension_Limits_Dot_Value2>;
  before?: Maybe<Endeavour_Limits_Suspension_Limits_Dot_Value>;
};

export type LimitsUpdate = {
  __typename?: 'LimitsUpdate';
  after?: Maybe<Endeavour_Limits_Transaction_Limits_Dot_Value2>;
  before?: Maybe<Endeavour_Limits_Transaction_Limits_Dot_Value>;
};

export type LivespinsLaunchRequest = {
  currencyCode: Scalars['String']['input'];
  deviceCode?: InputMaybe<Scalars['String']['input']>;
  languageCode: Scalars['String']['input'];
};

export type LivespinsLaunchResponse = {
  __typename?: 'LivespinsLaunchResponse';
  url: Scalars['String']['output'];
};

export type Lobby = {
  __typename?: 'Lobby';
  /** Contains any extraneous properties defined on the content model */
  additionalProperties?: Maybe<Scalars['JSON']['output']>;
  filters: Filters;
  gameCategories: Array<GameCategories>;
  id: Scalars['ID']['output'];
  slug: Scalars['String']['output'];
};

export type LoginConfiguration = {
  __typename?: 'LoginConfiguration';
  inactivity: Scalars['String']['output'];
};

export type ManualAdjustment = {
  /** The amount to adjust the wallet by */
  amount: Scalars['Float']['input'];
  /** The currency code of the amount */
  currencyCode: Scalars['String']['input'];
  /** The fee amount to apply */
  feeAmount: Scalars['Float']['input'];
  /** The currency code of the fee amount */
  feeCurrencyCode: Scalars['String']['input'];
  /** The fee mode to use */
  feeMode: FeeMode;
  /** The reason for the adjustment */
  reason: Scalars['String']['input'];
  /** Optional reference to the transaction that this adjustment is for */
  transactionExternalId?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the user profile the wallet belongs to */
  userProfileId: Scalars['ID']['input'];
};

export type ManualAdjustmentResponse = {
  __typename?: 'ManualAdjustmentResponse';
  /** The transaction ID for the adjustment that was made */
  transactionId?: Maybe<Scalars['ID']['output']>;
};

export type ManualAdjustmentReviewResponse = {
  __typename?: 'ManualAdjustmentReviewResponse';
  /** The new status of the adjustment transaction */
  status?: Maybe<ReviewStatusType>;
  /** The transaction ID for the adjustment */
  transactionId?: Maybe<Scalars['ID']['output']>;
};

export type Marketing = {
  __typename?: 'Marketing';
  agent?: Maybe<Scalars['String']['output']>;
  campaign?: Maybe<Scalars['String']['output']>;
  content?: Maybe<Scalars['String']['output']>;
  medium?: Maybe<Scalars['String']['output']>;
  payload?: Maybe<Scalars['String']['output']>;
  source?: Maybe<Scalars['String']['output']>;
  term?: Maybe<Scalars['String']['output']>;
};

export type MaxWagerLimitsCreateErrors = FieldError | GenericError;

export type MaxWagerLimitsCreateResponse = {
  __typename?: 'MaxWagerLimitsCreateResponse';
  data?: Maybe<CreateLimitSuccess>;
  error?: Maybe<Array<MaxWagerLimitsCreateErrors>>;
};

export type MaxWagerLimitsUpdateResponse = {
  __typename?: 'MaxWagerLimitsUpdateResponse';
  data?: Maybe<CreateLimitSuccess>;
  error?: Maybe<Array<MaxWagerLimitsCreateErrors>>;
};

export type MetaTags = {
  __typename?: 'MetaTags';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  keywords?: Maybe<Scalars['String']['output']>;
  ogDescription?: Maybe<Scalars['String']['output']>;
  ogImage?: Maybe<Scalars['String']['output']>;
  ogTitle?: Maybe<Scalars['String']['output']>;
  ogType?: Maybe<Scalars['String']['output']>;
  ogUrl?: Maybe<Scalars['String']['output']>;
  pageTitle?: Maybe<Scalars['String']['output']>;
};

export enum MethodType {
  Deposit = 'Deposit',
  Withdrawal = 'Withdrawal'
}

export type Monitoring = {
  __typename?: 'Monitoring';
  additionalData?: Maybe<Scalars['JSON']['output']>;
  createdOn?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  externalResource?: Maybe<Scalars['String']['output']>;
  externalResourceId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  intent?: Maybe<Scalars['String']['output']>;
  mediatorId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<MonitoringStatus>;
  type?: Maybe<Scalars['String']['output']>;
  userProfileId?: Maybe<Scalars['String']['output']>;
};

export enum MonitoringStatus {
  Monitoring = 'Monitoring',
  NotSet = 'NotSet',
  Pending = 'Pending',
  ReMonitoring = 'ReMonitoring'
}

export type Mutation = {
  __typename?: 'Mutation';
  /** addIdentityRoleToIdentityUser: Creates a Identity Role for an Identity User */
  addIdentityRoleToIdentityUser: AddIdentityRoleForIdentityUserResponse;
  /** addRelatedIdentityRoleToRole: Add Related Identity Role */
  addRelatedIdentityRoleToRole: AddRelatedIdentityRoleToRoleResponse;
  /** bonusTemplatesCreate: Creates bonus template */
  bonusTemplatesCreate?: Maybe<BonusTemplatesCreateResponse>;
  /** bonusTemplatesUpdate: Updates bonus template */
  bonusTemplatesUpdate?: Maybe<BonusTemplatesUpdateResponse>;
  /** bonusTemplatesUserProfileCreate: Creates a user profile bonus from bonus template */
  bonusTemplatesUserProfileCreate?: Maybe<Scalars['JSON']['output']>;
  /** cancelUserSweepstake: Cancel a user profile sweepstake. */
  cancelUserSweepstake: UserSweepstakeCancelResponse;
  /** Creates a session context ID used to make a payment with the specified provider */
  cashierSessionCreate: CashierSession;
  /** casinoGameConfigCreate: Creates a Game Config */
  casinoGameConfigCreate: CasinoGameConfigCreateResponse;
  /** casinoGameConfigDelete: Delete an already created Game Config by Id */
  casinoGameConfigDelete?: Maybe<Scalars['JSON']['output']>;
  /** casinoGameConfigUpdate: Updates a Game Config by Id */
  casinoGameConfigUpdate: CasinoGameConfigUpdateResponse;
  /** casinoIntegrationConfigCreate: Creates a Integration Config */
  casinoIntegrationConfigCreate: CasinoIntegrationConfigCreateResponse;
  /** casinoIntegrationConfigDelete: Delete an already created Integration Config by Id */
  casinoIntegrationConfigDelete?: Maybe<Scalars['JSON']['output']>;
  /** casinoIntegrationConfigUpdate: Updates a Integration Config by Id */
  casinoIntegrationConfigUpdate: CasinoIntegrationConfigUpdateResponse;
  /** casinoProviderConfigCreate: Creates a Provider Config */
  casinoProviderConfigCreate: CasinoProviderConfigCreateResponse;
  /** casinoProviderConfigDelete: Delete an already created Provider Config by Id */
  casinoProviderConfigDelete?: Maybe<Scalars['JSON']['output']>;
  /** casinoProviderConfigUpdate: Updates a Provider Config by Id */
  casinoProviderConfigUpdate: CasinoProviderConfigUpdateResponse;
  /** continueRealityCheck: Continues a Reality-Check. */
  continueRealityCheck: RealityCheckContinueResponse;
  /** createIdentityRole: Create Identity Role */
  createIdentityRole: CreateIdentityRoleResponse;
  /** createIdentityUser: Creates a Identity User */
  createIdentityUser: CreateIdentityUserResponse;
  /** deleteIdentityRole: Delete Identity Role */
  deleteIdentityRole?: Maybe<DeleteIdentityRoleResponse>;
  /** documentMetadataCreate: Will be call upon upload progress is complete */
  documentMetadataCreate?: Maybe<DocumentMetadataCreateResponse>;
  /** documentRequest: Requests a Document */
  documentRequest: DocumentRequestResponse;
  /** documentUpdate: Updates a Document data by Id */
  documentUpdate: DocumentUpdateResponse;
  /** documentVerification: Creates Document Verification */
  documentVerification: DocumentVerificationResponse;
  /** documents: Creates Document */
  documents: DocumentsResponse;
  /** extendSuspensionLimit: Extends an already created suspension limit on a specific user */
  extendSuspensionLimit?: Maybe<ExtendSuspensionLimitResponse>;
  /** forfeitUserSweepstake: Cancel a user profile sweepstake. */
  forfeitUserSweepstake: UserSweepstakeForfeitResponse;
  /** freeBetAssignToUser: Assign free bet to a user */
  freeBetAssignToUser?: Maybe<Scalars['JSON']['output']>;
  /** freeBetCancelToUser: Cancel an assigned free bet to a user */
  freeBetCancelToUser?: Maybe<Scalars['JSON']['output']>;
  /** freeBetTemplateCreate: Creates a free bet template */
  freeBetTemplateCreate: FreeBetTemplateCreateResponse;
  /** freeBetTemplateUpdate: Updates a free bet template */
  freeBetTemplateUpdate: FreeBetTemplateUpdateResponse;
  /** freeSpinAssignToUser: Assign free spin to a user */
  freeSpinAssignToUser?: Maybe<Scalars['JSON']['output']>;
  /** freeSpinCancelToUser: Cancel an assigned free spin to a user */
  freeSpinCancelToUser?: Maybe<Scalars['JSON']['output']>;
  /** freeSpinTemplateCreate: Creates a free spin template */
  freeSpinTemplateCreate: FreeSpinTemplateCreateResponse;
  /** freeSpinTemplateUpdate: Updates a free spin template */
  freeSpinTemplateUpdate: FreeSpinTemplateUpdateResponse;
  /** ignoreUploadedDocument: ignore a Document */
  ignoreUploadedDocument: IgnoreUploadedDocumentRequestResponse;
  /** Returns a inovapay cashier url for the cashier session provided */
  inovapayLaunch: InovapayLaunchResponse;
  /** launch: Creates a new token to be used to launch */
  launchVerificationProvider?: Maybe<VerificationProviderLaunchResponse>;
  /** livespinsLaunchUrl: Will get the lunch url for livespins */
  livespinsLaunchUrl: LivespinsLaunchResponse;
  /** Creates a debit or credit manual adjustment on the given wallet */
  manualAdjustmentCreate: ManualAdjustmentResponse;
  /** Reviews a adjustment transaction and sets the status to completed or declined */
  manualAdjustmentReview: ManualAdjustmentReviewResponse;
  /** maxWagerLimitsCreate: Creates a new max wager limit on a specific user */
  maxWagerLimitsCreate?: Maybe<MaxWagerLimitsCreateResponse>;
  /** maxWagerLimitsDelete: Delete an already created max wager limit on a specific user */
  maxWagerLimitsDelete?: Maybe<Scalars['JSON']['output']>;
  /** maxWagerLimitsReview: Creates a new max wager limit review on a specific user */
  maxWagerLimitsUpdate?: Maybe<MaxWagerLimitsUpdateResponse>;
  /** noteCategoryCreate: Create a note category */
  noteCategoryCreate: NoteCategoryCreateResponse;
  /** noteCreate: Create a note */
  noteCreate: NoteCreateResponse;
  /** noteUpdate: Update a note */
  noteUpdate: NoteUpdateResponse;
  /** notesCategoryUpdate: Update a note category by id */
  notesCategoryUpdate: NotesCategoryUpdateResponse;
  /** Creates a withdrawal request for the current user and returns the redirect url */
  nuveiLaunch: NuveiLaunchResponse;
  /** Returns a play4fun cashier url for the cashier session provided */
  pay4funLaunch: Pay4FunLaunchResponse;
  /** paymentIntegrationConfigCreate: Creates a Integration Config */
  paymentIntegrationConfigCreate: PaymentIntegrationConfigCreateResponse;
  /** paymentIntegrationConfigDelete: Delete an already created Integration Config by Id */
  paymentIntegrationConfigDelete?: Maybe<Scalars['JSON']['output']>;
  /** paymentIntegrationConfigUpdate: Updates a Integration Config by Id */
  paymentIntegrationConfigUpdate: PaymentIntegrationConfigUpdateResponse;
  /** paymentMethodConfigCreate: Creates a Payment Config */
  paymentMethodConfigCreate: PaymentMethodConfigCreateResponse;
  /** paymentMethodConfigDelete: Delete an already created Payment Config by Id */
  paymentMethodConfigDelete?: Maybe<Scalars['JSON']['output']>;
  /** paymentMethodConfigUpdate: Updates a Payment Config by Id */
  paymentMethodConfigUpdate: PaymentMethodConfigUpdateResponse;
  /** paymentProviderConfigCreate: Creates a Provider Config */
  paymentProviderConfigCreate: PaymentProviderConfigCreateResponse;
  /** paymentProviderConfigDelete: Delete an already created Provider Config by Id */
  paymentProviderConfigDelete?: Maybe<Scalars['JSON']['output']>;
  /** paymentProviderConfigUpdate: Updates a Provider Config by Id */
  paymentProviderConfigUpdate: PaymentProviderConfigUpdateResponse;
  /** paymentsApprove: Approve a withdraw */
  paymentsApprove: PaymentsApproveResponse;
  /** paymentsDeny: Deny a withdraw */
  paymentsDeny: PaymentsDenyResponse;
  /** questionnaireAssignToUser: Assign a questionnaire template to a user */
  questionnaireAssignToUser?: Maybe<Scalars['JSON']['output']>;
  /** questionnaireStatusUpdateToUser: Update the questionnaire status for a user profile */
  questionnaireStatusUpdateToUser: QuestionnaireStatusUpdateToUserResponse;
  questionnaireTemplatesCreate: QuestionnaireTemplatesResponse;
  /** questionnaireTemplatesDelete: Delete a Questionnaire Template by Id */
  questionnaireTemplatesDelete?: Maybe<QuestionnaireTemplatesResponse>;
  /** questionnaireTemplatesUpdate: Updates a Questionnaire Template */
  questionnaireTemplatesUpdate: QuestionnaireTypesUpdateResponse;
  /** questionnaireTemplatesUpdate: Updates a Questionnaire Template by Id */
  questionnaireTemplatesUpdateById: QuestionnaireTypesUpdateResponse;
  /** questionnaireTypesCreate: Creates a Questionnaire Types */
  questionnaireTypesCreate: QuestionnaireTypesResponse;
  /** questionnaireTypesUpdate: Updates a Questionnaire Types by Id */
  questionnaireTypesUpdate: QuestionnaireTypesUpdateResponse;
  /**
   * Registers a new user. The model accepts any arbitrary JSON object but must however match
   * the rules defined for the given tenant which ultimately depends on the jurisdiction and brand requirements.
   */
  registerUser?: Maybe<RegisterResponse>;
  /** removeIdentityRoleFromIdentityUser: Delete a Identity Role from Identity User */
  removeIdentityRoleFromIdentityUser: RemoveIdentityRoleFromIdentityUserResponse;
  /** removeRelatedIdentityRoleFromRole: Delete Related Identity Role */
  removeRelatedIdentityRoleFromRole: RemoveRelatedIdentityRoleFromRoleResponse;
  reportGenerate: GeneratedReportResponse;
  /** sessionContextClose: Close single active session context */
  sessionContextClose?: Maybe<Scalars['JSON']['output']>;
  /** sessionContextCloseAll: Close multiple active session contexts */
  sessionContextCloseAll?: Maybe<Scalars['JSON']['output']>;
  /** sessionContextCloseAllDelayed: Close multiple active session contexts after defined delay */
  sessionContextCloseAllDelayed?: Maybe<Scalars['JSON']['output']>;
  /** sessionLimitsContinuousCreate: Creates a new session limit on a specific user */
  sessionLimitsContinuousCreate?: Maybe<SessionLimitsContinuousCreateResponse>;
  /** sessionLimitsContinuousDelete: Delete an already created session limit on a specific user */
  sessionLimitsContinuousDelete?: Maybe<Scalars['JSON']['output']>;
  /** sessionLimitsContinuousReview: Creates a new session limit review on a specific user */
  sessionLimitsContinuousUpdate?: Maybe<SessionLimitsContinuousCreateResponse>;
  /** sessionLimitsCumulativeCreate: Creates a new session limit on a specific user */
  sessionLimitsCumulativeCreate?: Maybe<SessionLimitsCumulativeCreateResponse>;
  /** sessionLimitsCumulativeDelete: Delete an already created session limit on a specific user */
  sessionLimitsCumulativeDelete?: Maybe<Scalars['JSON']['output']>;
  /** sessionLimitsCumulativeReview: Creates a new session limit review on a specific user */
  sessionLimitsCumulativeUpdate?: Maybe<SessionLimitsContinuousCreateResponse>;
  /** setRealityCheckInterval: Set a Reality-Check Interval. */
  setRealityCheckInterval: RealityCheckSetIntervalResponse;
  /** sportsIntegrationConfigCreate: Creates a Integration Config */
  sportsIntegrationConfigCreate: SportsIntegrationConfigCreateResponse;
  /** sportsIntegrationConfigDelete: Delete an already created Integration Config by Id */
  sportsIntegrationConfigDelete?: Maybe<Scalars['JSON']['output']>;
  /** sportsIntegrationConfigUpdate: Updates a Integration Config by Id */
  sportsIntegrationConfigUpdate: SportsIntegrationConfigUpdateResponse;
  /** sportsProviderConfigCreate: Creates a Provider Config */
  sportsProviderConfigCreate: SportsProviderConfigCreateResponse;
  /** sportsProviderConfigDelete: Delete an already created Provider Config by Id */
  sportsProviderConfigDelete?: Maybe<Scalars['JSON']['output']>;
  /** sportsProviderConfigUpdate: Updates a Provider Config by Id */
  sportsProviderConfigUpdate: SportsProviderConfigUpdateResponse;
  /** stopRealityCheck: Stops a Reality-Check. */
  stopRealityCheck: RealityCheckStopResponse;
  /** submitUserAnswersToAssignedQuestionnaire: Submiting the answers of an assigned questionnaire */
  submitUserAnswersToAssignedQuestionnaire: SubmitUserAnswersToAssignedQuestionnaireUserResponse;
  /** suspensionLimitsCreate: Creates a new suspension limit on a specific user */
  suspensionLimitsCreate?: Maybe<SuspensionLimitsCreateResponse>;
  /** suspensionLimitsDelete: Delete an already created suspension limit on a specific user */
  suspensionLimitsDelete?: Maybe<Scalars['JSON']['output']>;
  /** sweepstakeTemplateCreate: Creates a Sweepstake Template */
  sweepstakeTemplateCreate: SweepstakeTemplateCreateResponse;
  /** sweepstakeTemplateUpdate: Updates a Sweepstake Template */
  sweepstakeTemplateUpdate: SweepstakeTemplateUpdateResponse;
  /** tagCategoryCreate: Creates a tag category */
  tagCategoryCreate?: Maybe<Scalars['JSON']['output']>;
  /** tagCategoryUpdate: Update a tag category */
  tagCategoryUpdate?: Maybe<TagCategoryUpdateResponse>;
  /** tagCreate: Creates a Tag */
  tagCreate?: Maybe<Scalars['JSON']['output']>;
  /** tagObjectCreate: Creates object tag */
  tagObjectCreate?: Maybe<Scalars['JSON']['output']>;
  /** tagObjectDelete: Deletes object tag */
  tagObjectDelete?: Maybe<Scalars['JSON']['output']>;
  /** tagUpdate: Update a tag */
  tagUpdate?: Maybe<TagUpdateResponse>;
  /** taskCreate: Creates a task. */
  taskCreate: TaskCreateResponse;
  /** taskCreateForBackOfficeUserId: Creates a task for a backoffice user id. */
  taskCreateForBackOfficeUserId: TaskCreateForBackOfficeUserIdResponse;
  /** taskDelete: Deletes a task. */
  taskDelete: TaskDeleteResponse;
  /** taskUpdate: Updates a task. */
  taskUpdate: TaskUpdateResponse;
  /** taskUpdateForBackOfficeUserId: Updates a task by id for a backoffice user. */
  taskUpdateForBackOfficeUserId: TaskUpdateForBackOfficeUserIdResponse;
  /** transactionLimitsCreate: Creates a new transaction limit on a specific user */
  transactionLimitsCreate?: Maybe<TransactionLimitsCreateResponse>;
  /** transactionLimitsDelete: Delete an already created transaction limit on a specific user */
  transactionLimitsDelete?: Maybe<Scalars['JSON']['output']>;
  /** transactionLimitsReview: Creates a new transaction limit review on a specific user */
  transactionLimitsUpdate?: Maybe<TransactionLimitsCreateResponse>;
  /** updateIdentityUser: Updates a Identity User by Id */
  updateIdentityUser: UpdateIdentityUserResponse;
  /** updateUserEmailAndUsername: Updates a user email and username by Id */
  updateUserEmailAndUsername: UserProfileUpdateResponse;
  /** userProfileBonusCancel: Cancels a user profile bonus */
  userProfileBonusCancel?: Maybe<Scalars['JSON']['output']>;
  /** userProfileBonusClaim: Claims a user profile bonus */
  userProfileBonusClaim?: Maybe<Scalars['JSON']['output']>;
  /** userProfileBonusForfeit: Cancels a user profile bonus */
  userProfileBonusForfeit?: Maybe<Scalars['JSON']['output']>;
  /** userProfileUpdate: Updates a user profile details by Id */
  userProfileUpdate: UserProfileUpdateResponse;
  /** userRestrictionCreate: Create A new User Restriction */
  userRestrictionCreate?: Maybe<UserRestrictionCreateResponse>;
  /** userRestrictionDelete: Remove A User Restriction */
  userRestrictionDelete?: Maybe<Scalars['JSON']['output']>;
  /** userSweepstakeAssign: Assigns a user profile sweepstakes from a sweepstakes template. */
  userSweepstakeAssign: UserSweepstakeAssignResponse;
  /** userSweepstakeClaim: Claims a user profile sweepstake. */
  userSweepstakeClaim: UserSweepstakeClaimResponse;
  /** userVerify: Verifies a user profile */
  userVerify?: Maybe<UserVerifyResponse>;
};


export type MutationAddIdentityRoleToIdentityUserArgs = {
  identityRoleId: Scalars['ID']['input'];
  identityUserId: Scalars['ID']['input'];
  xTenantID: Scalars['ID']['input'];
};


export type MutationAddRelatedIdentityRoleToRoleArgs = {
  id: Scalars['ID']['input'];
  identityRoleId: Scalars['ID']['input'];
  xTenantID: Scalars['ID']['input'];
};


export type MutationBonusTemplatesCreateArgs = {
  bonusTemplatesInfo?: InputMaybe<BonusTemplatesCreateInput>;
  xTenantID: Scalars['ID']['input'];
};


export type MutationBonusTemplatesUpdateArgs = {
  bonusTemplateId: Scalars['ID']['input'];
  bonusTemplateInfo?: InputMaybe<BonusTemplatesUpdateInput>;
  xTenantID: Scalars['ID']['input'];
};


export type MutationBonusTemplatesUserProfileCreateArgs = {
  bonusInfo?: InputMaybe<AssignBonusCreateInput>;
  userProfileId: Scalars['ID']['input'];
  xTenantID: Scalars['ID']['input'];
};


export type MutationCancelUserSweepstakeArgs = {
  userProfileId: Scalars['ID']['input'];
  userProfileSweepstakesId: Scalars['ID']['input'];
  xTenantID: Scalars['ID']['input'];
};


export type MutationCashierSessionCreateArgs = {
  launchArguments: CashierLaunchArguments;
  provider: Scalars['String']['input'];
  xTenantID: Scalars['String']['input'];
};


export type MutationCasinoGameConfigCreateArgs = {
  gameConfigCreateInfo?: InputMaybe<CasinoGameConfigCreateInput>;
  xTenantID: Scalars['ID']['input'];
};


export type MutationCasinoGameConfigDeleteArgs = {
  configId: Scalars['ID']['input'];
  xTenantID: Scalars['ID']['input'];
};


export type MutationCasinoGameConfigUpdateArgs = {
  configId: Scalars['ID']['input'];
  gameConfigUpdateInfo?: InputMaybe<CasinoGameConfigUpdateInput>;
  xTenantID: Scalars['ID']['input'];
};


export type MutationCasinoIntegrationConfigCreateArgs = {
  integrationConfigCreateInfo?: InputMaybe<CasinoIntegrationConfigCreateInput>;
  xTenantID: Scalars['ID']['input'];
};


export type MutationCasinoIntegrationConfigDeleteArgs = {
  configId: Scalars['ID']['input'];
  xTenantID: Scalars['ID']['input'];
};


export type MutationCasinoIntegrationConfigUpdateArgs = {
  configId: Scalars['ID']['input'];
  integrationConfigUpdateInfo?: InputMaybe<CasinoIntegrationConfigUpdateInput>;
  xTenantID: Scalars['ID']['input'];
};


export type MutationCasinoProviderConfigCreateArgs = {
  providerConfigCreateInfo?: InputMaybe<CasinoProviderConfigCreateInput>;
  xTenantID: Scalars['ID']['input'];
};


export type MutationCasinoProviderConfigDeleteArgs = {
  configId: Scalars['ID']['input'];
  xTenantID: Scalars['ID']['input'];
};


export type MutationCasinoProviderConfigUpdateArgs = {
  configId: Scalars['ID']['input'];
  providerConfigUpdateInfo?: InputMaybe<CasinoProviderConfigUpdateInput>;
  xTenantID: Scalars['ID']['input'];
};


export type MutationContinueRealityCheckArgs = {
  realityCheckId: Scalars['ID']['input'];
  userProfileId: Scalars['ID']['input'];
  xTenantID: Scalars['ID']['input'];
};


export type MutationCreateIdentityRoleArgs = {
  createIdentityRoleInput: CreateIdentityRoleInput;
  xTenantID: Scalars['ID']['input'];
};


export type MutationCreateIdentityUserArgs = {
  createIdentityUsersInput?: InputMaybe<CreateIdentityUserInput>;
  xTenantID: Scalars['ID']['input'];
};


export type MutationDeleteIdentityRoleArgs = {
  id: Scalars['ID']['input'];
  xTenantID: Scalars['ID']['input'];
};


export type MutationDocumentMetadataCreateArgs = {
  provider: Scalars['String']['input'];
  responseInfo?: InputMaybe<Scalars['JSON']['input']>;
  userProfileId: Scalars['ID']['input'];
  xTenantID: Scalars['ID']['input'];
};


export type MutationDocumentRequestArgs = {
  documentRequestInfo?: InputMaybe<DocumentRequestInput>;
  userProfileId: Scalars['ID']['input'];
  xTenantID: Scalars['ID']['input'];
};


export type MutationDocumentUpdateArgs = {
  documentId: Scalars['ID']['input'];
  documentInfo?: InputMaybe<DocumentUpdateInput>;
  userProfileId: Scalars['ID']['input'];
  xTenantID: Scalars['ID']['input'];
};


export type MutationDocumentVerificationArgs = {
  documentInfo?: InputMaybe<DocumentVerificationInput>;
  userProfileId: Scalars['ID']['input'];
  xTenantID: Scalars['ID']['input'];
};


export type MutationDocumentsArgs = {
  documentInfo?: InputMaybe<DocumentInput>;
  userProfileId: Scalars['ID']['input'];
  xTenantID: Scalars['ID']['input'];
};


export type MutationExtendSuspensionLimitArgs = {
  creatorName?: InputMaybe<CreatorName>;
  extendSuspensionLimitBodyRequest?: InputMaybe<ExtendSuspensionLimitInput>;
  limitId: Scalars['ID']['input'];
  userProfileId: Scalars['ID']['input'];
  xTenantID: Scalars['ID']['input'];
};


export type MutationForfeitUserSweepstakeArgs = {
  userProfileId: Scalars['ID']['input'];
  userProfileSweepstakesId: Scalars['ID']['input'];
  xTenantID: Scalars['ID']['input'];
};


export type MutationFreeBetAssignToUserArgs = {
  freeBetTemplateId: Scalars['ID']['input'];
  userProfileId: Scalars['ID']['input'];
  xTenantID: Scalars['ID']['input'];
};


export type MutationFreeBetCancelToUserArgs = {
  freeBetTemplateId: Scalars['ID']['input'];
  userProfileId: Scalars['ID']['input'];
  xTenantID: Scalars['ID']['input'];
};


export type MutationFreeBetTemplateCreateArgs = {
  freeBetTemplateCreateInfo?: InputMaybe<FreeBetTemplateCreateInput>;
  xTenantID: Scalars['ID']['input'];
};


export type MutationFreeBetTemplateUpdateArgs = {
  freeBetTemplateUpdateInfo?: InputMaybe<FreeBetTemplateUpdateInput>;
  templateId: Scalars['ID']['input'];
  xTenantID: Scalars['ID']['input'];
};


export type MutationFreeSpinAssignToUserArgs = {
  freeSpinTemplateId: Scalars['ID']['input'];
  userProfileId: Scalars['ID']['input'];
  xTenantID: Scalars['ID']['input'];
};


export type MutationFreeSpinCancelToUserArgs = {
  freeSpinTemplateId: Scalars['ID']['input'];
  userProfileId: Scalars['ID']['input'];
  xTenantID: Scalars['ID']['input'];
};


export type MutationFreeSpinTemplateCreateArgs = {
  freeSpinTemplateCreateInfo?: InputMaybe<FreeSpinTemplateCreateInput>;
  xTenantID: Scalars['ID']['input'];
};


export type MutationFreeSpinTemplateUpdateArgs = {
  freeSpinTemplateUpdateInfo?: InputMaybe<FreeSpinTemplateUpdateInput>;
  templateId: Scalars['ID']['input'];
  xTenantID: Scalars['ID']['input'];
};


export type MutationIgnoreUploadedDocumentArgs = {
  documentId: Scalars['ID']['input'];
  userProfileId: Scalars['ID']['input'];
  xTenantID: Scalars['ID']['input'];
};


export type MutationInovapayLaunchArgs = {
  payment: InovapayLaunchInput;
  type: InovapayMethodType;
  userProfileId: Scalars['String']['input'];
  xTenantID: Scalars['String']['input'];
};


export type MutationLaunchVerificationProviderArgs = {
  extraParameters?: InputMaybe<Scalars['JSON']['input']>;
  provider: Scalars['String']['input'];
  userProfileId: Scalars['ID']['input'];
  xTenantID: Scalars['ID']['input'];
};


export type MutationLivespinsLaunchUrlArgs = {
  launchInfo?: InputMaybe<LivespinsLaunchRequest>;
  userProfileId: Scalars['ID']['input'];
  xTenantID: Scalars['ID']['input'];
};


export type MutationManualAdjustmentCreateArgs = {
  adjustment?: InputMaybe<ManualAdjustment>;
  adjustmentType: AdjustmentType;
  walletId: Scalars['ID']['input'];
  xTenantID: Scalars['ID']['input'];
};


export type MutationManualAdjustmentReviewArgs = {
  reason?: InputMaybe<Scalars['String']['input']>;
  status: ReviewStatusType;
  transactionId: Scalars['ID']['input'];
  userProfileId: Scalars['ID']['input'];
  xTenantID: Scalars['ID']['input'];
};


export type MutationMaxWagerLimitsCreateArgs = {
  creatorName?: InputMaybe<CreatorName>;
  limitsMaxWagerCreate?: InputMaybe<LimitsMaxWagerCreateInput>;
  userProfileId: Scalars['ID']['input'];
  xTenantID: Scalars['ID']['input'];
};


export type MutationMaxWagerLimitsDeleteArgs = {
  creatorName?: InputMaybe<CreatorName>;
  limitId: Scalars['ID']['input'];
  userProfileId: Scalars['ID']['input'];
  xTenantID: Scalars['ID']['input'];
};


export type MutationMaxWagerLimitsUpdateArgs = {
  creatorName?: InputMaybe<CreatorName>;
  limitId: Scalars['ID']['input'];
  limitsMaxWagerReview?: InputMaybe<LimitsMaxWagerReview>;
  userProfileId: Scalars['ID']['input'];
  xTenantID: Scalars['ID']['input'];
};


export type MutationNoteCategoryCreateArgs = {
  noteCategoryCreateInfo?: InputMaybe<NoteCategoryCreateAndUpdateInput>;
  xTenantID: Scalars['ID']['input'];
};


export type MutationNoteCreateArgs = {
  noteCreateInfo?: InputMaybe<NoteCreateInput>;
  xTenantID: Scalars['ID']['input'];
};


export type MutationNoteUpdateArgs = {
  id: Scalars['ID']['input'];
  noteUpdateInfo?: InputMaybe<NoteUpdateInput>;
  xTenantID: Scalars['ID']['input'];
};


export type MutationNotesCategoryUpdateArgs = {
  id: Scalars['ID']['input'];
  noteCategoryUpdateInfo?: InputMaybe<NoteCategoryCreateAndUpdateInput>;
  xTenantID: Scalars['ID']['input'];
};


export type MutationNuveiLaunchArgs = {
  payment: NuveiLaunchInput;
  type: NuveiMethodType;
  userProfileId: Scalars['String']['input'];
  xTenantID: Scalars['String']['input'];
};


export type MutationPay4funLaunchArgs = {
  payment: Pay4FunLaunchInput;
  type: Pay4FunMethodType;
  userProfileId: Scalars['String']['input'];
  xTenantID: Scalars['String']['input'];
};


export type MutationPaymentIntegrationConfigCreateArgs = {
  integrationConfigCreateInfo?: InputMaybe<PaymentIntegrationConfigCreateInput>;
  xTenantID: Scalars['ID']['input'];
};


export type MutationPaymentIntegrationConfigDeleteArgs = {
  configId: Scalars['ID']['input'];
  xTenantID: Scalars['ID']['input'];
};


export type MutationPaymentIntegrationConfigUpdateArgs = {
  configId: Scalars['ID']['input'];
  integrationConfigUpdateInfo?: InputMaybe<PaymentIntegrationConfigUpdateInput>;
  xTenantID: Scalars['ID']['input'];
};


export type MutationPaymentMethodConfigCreateArgs = {
  paymentMethodConfigCreateInfo?: InputMaybe<PaymentConfigCreateInput>;
  xTenantID: Scalars['ID']['input'];
};


export type MutationPaymentMethodConfigDeleteArgs = {
  configId: Scalars['ID']['input'];
  xTenantID: Scalars['ID']['input'];
};


export type MutationPaymentMethodConfigUpdateArgs = {
  configId: Scalars['ID']['input'];
  paymentMethodConfigUpdateInfo?: InputMaybe<PaymentConfigUpdateInput>;
  xTenantID: Scalars['ID']['input'];
};


export type MutationPaymentProviderConfigCreateArgs = {
  providerConfigCreateInfo?: InputMaybe<PaymentProviderConfigCreateInput>;
  xTenantID: Scalars['ID']['input'];
};


export type MutationPaymentProviderConfigDeleteArgs = {
  configId: Scalars['ID']['input'];
  xTenantID: Scalars['ID']['input'];
};


export type MutationPaymentProviderConfigUpdateArgs = {
  configId: Scalars['ID']['input'];
  providerConfigUpdateInfo?: InputMaybe<PaymentProviderConfigUpdateInput>;
  xTenantID: Scalars['ID']['input'];
};


export type MutationPaymentsApproveArgs = {
  paymentsApproveBodyRequest: PaymentsApproveBodyRequest;
  xTenantID: Scalars['ID']['input'];
};


export type MutationPaymentsDenyArgs = {
  paymentsDenyBodyRequest: PaymentsDenyBodyRequest;
  xTenantID: Scalars['ID']['input'];
};


export type MutationQuestionnaireAssignToUserArgs = {
  questionnaireTemplateId: Scalars['ID']['input'];
  userProfileId: Scalars['ID']['input'];
  xTenantID: Scalars['ID']['input'];
};


export type MutationQuestionnaireStatusUpdateToUserArgs = {
  questionnaireId: Scalars['ID']['input'];
  questionnaireStatusUpdateToUserInfo?: InputMaybe<QuestionnaireStatusUpdateToUserInput>;
  userProfileId: Scalars['ID']['input'];
  xTenantID: Scalars['ID']['input'];
};


export type MutationQuestionnaireTemplatesCreateArgs = {
  questionnaireTemplatesCreateInfo?: InputMaybe<QuestionnaireTemplatesCreateInput>;
  xTenantID: Scalars['ID']['input'];
};


export type MutationQuestionnaireTemplatesDeleteArgs = {
  id: Scalars['ID']['input'];
  xTenantID: Scalars['ID']['input'];
};


export type MutationQuestionnaireTemplatesUpdateArgs = {
  id: Scalars['ID']['input'];
  questionnaireTemplatesUpdateInfo?: InputMaybe<QuestionnaireTemplatesUpdateInput>;
  xTenantID: Scalars['ID']['input'];
};


export type MutationQuestionnaireTemplatesUpdateByIdArgs = {
  id: Scalars['ID']['input'];
  questionnaireTemplatesUpdateByIdInfo?: InputMaybe<QuestionnaireTemplatesUpdateByIdInput>;
  xTenantID: Scalars['ID']['input'];
};


export type MutationQuestionnaireTypesCreateArgs = {
  questionnaireTypesCreateInfo?: InputMaybe<QuestionnaireTypesCreateInput>;
  xTenantID: Scalars['ID']['input'];
};


export type MutationQuestionnaireTypesUpdateArgs = {
  id: Scalars['ID']['input'];
  questionnaireTypesUpdateInfo?: InputMaybe<QuestionnaireTypesUpdateInput>;
  xTenantID: Scalars['ID']['input'];
};


export type MutationRegisterUserArgs = {
  model: Scalars['JSON']['input'];
  xTenantID: Scalars['String']['input'];
};


export type MutationRemoveIdentityRoleFromIdentityUserArgs = {
  identityRoleId: Scalars['ID']['input'];
  identityUserId: Scalars['ID']['input'];
  xTenantID: Scalars['ID']['input'];
};


export type MutationRemoveRelatedIdentityRoleFromRoleArgs = {
  id: Scalars['ID']['input'];
  identityRoleId: Scalars['ID']['input'];
  xTenantID: Scalars['ID']['input'];
};


export type MutationReportGenerateArgs = {
  parameters: Array<Scalars['JSON']['input']>;
  reportId: Scalars['String']['input'];
  xTenantID: Scalars['String']['input'];
};


export type MutationSessionContextCloseArgs = {
  sessionContextId: Scalars['ID']['input'];
  userProfileId: Scalars['ID']['input'];
  xTenantID: Scalars['ID']['input'];
};


export type MutationSessionContextCloseAllArgs = {
  closeSessionParams?: InputMaybe<CloseSessionParamsInput>;
  userProfileId: Scalars['ID']['input'];
  xTenantID: Scalars['ID']['input'];
};


export type MutationSessionContextCloseAllDelayedArgs = {
  closeSessionParams?: InputMaybe<DelayCloseSessionParamsInput>;
  userProfileId: Scalars['ID']['input'];
  xTenantID: Scalars['ID']['input'];
};


export type MutationSessionLimitsContinuousCreateArgs = {
  creatorName?: InputMaybe<CreatorName>;
  sessionLimitsCreate?: InputMaybe<SessionLimitsContinuousCreateInput>;
  userProfileId: Scalars['ID']['input'];
  xTenantID: Scalars['ID']['input'];
};


export type MutationSessionLimitsContinuousDeleteArgs = {
  creatorName?: InputMaybe<CreatorName>;
  limitId: Scalars['ID']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  userProfileId: Scalars['ID']['input'];
  xTenantID: Scalars['ID']['input'];
};


export type MutationSessionLimitsContinuousUpdateArgs = {
  creatorName?: InputMaybe<CreatorName>;
  limitId: Scalars['ID']['input'];
  sessionLimitsReview?: InputMaybe<SessionLimitsReview>;
  userProfileId: Scalars['ID']['input'];
  xTenantID: Scalars['ID']['input'];
};


export type MutationSessionLimitsCumulativeCreateArgs = {
  creatorName?: InputMaybe<CreatorName>;
  sessionLimitsCreate?: InputMaybe<SessionLimitsCumulativeCreateInput>;
  userProfileId: Scalars['ID']['input'];
  xTenantID: Scalars['ID']['input'];
};


export type MutationSessionLimitsCumulativeDeleteArgs = {
  creatorName?: InputMaybe<CreatorName>;
  limitId: Scalars['ID']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  userProfileId: Scalars['ID']['input'];
  xTenantID: Scalars['ID']['input'];
};


export type MutationSessionLimitsCumulativeUpdateArgs = {
  creatorName?: InputMaybe<CreatorName>;
  limitId: Scalars['ID']['input'];
  sessionLimitsReview?: InputMaybe<SessionLimitsReview>;
  userProfileId: Scalars['ID']['input'];
  xTenantID: Scalars['ID']['input'];
};


export type MutationSetRealityCheckIntervalArgs = {
  realityCheckSetIntervalBodyRequest?: InputMaybe<RealityCheckSetIntervalBodyRequest>;
  userProfileId: Scalars['ID']['input'];
  xTenantID: Scalars['ID']['input'];
};


export type MutationSportsIntegrationConfigCreateArgs = {
  integrationConfigCreateInfo?: InputMaybe<SportsIntegrationConfigCreateInput>;
  xTenantID: Scalars['ID']['input'];
};


export type MutationSportsIntegrationConfigDeleteArgs = {
  configId: Scalars['ID']['input'];
  xTenantID: Scalars['ID']['input'];
};


export type MutationSportsIntegrationConfigUpdateArgs = {
  configId: Scalars['ID']['input'];
  integrationConfigUpdateInfo?: InputMaybe<SportsIntegrationConfigUpdateInput>;
  xTenantID: Scalars['ID']['input'];
};


export type MutationSportsProviderConfigCreateArgs = {
  providerConfigCreateInfo?: InputMaybe<SportsProviderConfigCreateInput>;
  xTenantID: Scalars['ID']['input'];
};


export type MutationSportsProviderConfigDeleteArgs = {
  configId: Scalars['ID']['input'];
  xTenantID: Scalars['ID']['input'];
};


export type MutationSportsProviderConfigUpdateArgs = {
  configId: Scalars['ID']['input'];
  providerConfigUpdateInfo?: InputMaybe<SportsProviderConfigUpdateInput>;
  xTenantID: Scalars['ID']['input'];
};


export type MutationStopRealityCheckArgs = {
  realityCheckId: Scalars['ID']['input'];
  userProfileId: Scalars['ID']['input'];
  xTenantID: Scalars['ID']['input'];
};


export type MutationSubmitUserAnswersToAssignedQuestionnaireArgs = {
  questionnaireId: Scalars['ID']['input'];
  submitUserAnswersToAssignedQuestionnaireUserInfo?: InputMaybe<SubmitUserAnswersToAssignedQuestionnaireUserInput>;
  userProfileId: Scalars['ID']['input'];
  xTenantID: Scalars['ID']['input'];
};


export type MutationSuspensionLimitsCreateArgs = {
  creatorName?: InputMaybe<CreatorName>;
  suspensionLimitsCreate?: InputMaybe<SuspensionLimitsCreateInput>;
  userProfileId: Scalars['ID']['input'];
  xTenantID: Scalars['ID']['input'];
};


export type MutationSuspensionLimitsDeleteArgs = {
  creatorName?: InputMaybe<CreatorName>;
  limitId: Scalars['ID']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  userProfileId: Scalars['ID']['input'];
  xTenantID: Scalars['ID']['input'];
};


export type MutationSweepstakeTemplateCreateArgs = {
  sweepstakeTemplateCreateBodyRequest: SweepstakeTemplateCreateInput;
  xTenantID: Scalars['ID']['input'];
};


export type MutationSweepstakeTemplateUpdateArgs = {
  id: Scalars['ID']['input'];
  sweepstakeTemplateUpdateBodyRequest: SweepstakeTemplateUpdateInput;
  xTenantID: Scalars['ID']['input'];
};


export type MutationTagCategoryCreateArgs = {
  tagCategoryInfo?: InputMaybe<TagCategoryCreateInput>;
  xTenantID: Scalars['ID']['input'];
};


export type MutationTagCategoryUpdateArgs = {
  tagCategoryId: Scalars['ID']['input'];
  tagCategoryInfo?: InputMaybe<TagCategoryCreateInput>;
  xTenantID: Scalars['ID']['input'];
};


export type MutationTagCreateArgs = {
  tagInfo?: InputMaybe<TagCreateInput>;
  xTenantID: Scalars['ID']['input'];
};


export type MutationTagObjectCreateArgs = {
  tagObjectInfo?: InputMaybe<TagObjectCreateInput>;
  xTenantID: Scalars['ID']['input'];
};


export type MutationTagObjectDeleteArgs = {
  tagId: Scalars['ID']['input'];
  xTenantID: Scalars['ID']['input'];
};


export type MutationTagUpdateArgs = {
  tagId: Scalars['ID']['input'];
  tagInfo?: InputMaybe<TagUpdateInput>;
  xTenantID: Scalars['ID']['input'];
};


export type MutationTaskCreateArgs = {
  taskCreateBodyRequest?: InputMaybe<TaskCreateInput>;
  xTenantID: Scalars['ID']['input'];
};


export type MutationTaskCreateForBackOfficeUserIdArgs = {
  backOfficeUserId: Scalars['ID']['input'];
  taskCreateForBackOfficeUserIdBodyRequest?: InputMaybe<TaskCreateForBackOfficeUserIdInput>;
  xTenantID: Scalars['ID']['input'];
};


export type MutationTaskDeleteArgs = {
  taskId: Scalars['ID']['input'];
  xTenantID: Scalars['ID']['input'];
};


export type MutationTaskUpdateArgs = {
  taskId: Scalars['ID']['input'];
  taskUpdateBodyRequest?: InputMaybe<TaskUpdateInput>;
  xTenantID: Scalars['ID']['input'];
};


export type MutationTaskUpdateForBackOfficeUserIdArgs = {
  backOfficeUserId: Scalars['ID']['input'];
  taskId: Scalars['ID']['input'];
  taskUpdateForBackOfficeUserIdBodyRequest?: InputMaybe<TaskUpdateInput>;
  xTenantID: Scalars['ID']['input'];
};


export type MutationTransactionLimitsCreateArgs = {
  budgetLimitsCreateTransaction?: InputMaybe<BudgetLimitsCreateTransactionInput>;
  creatorName?: InputMaybe<CreatorName>;
  userProfileId: Scalars['ID']['input'];
  xTenantID: Scalars['ID']['input'];
};


export type MutationTransactionLimitsDeleteArgs = {
  creatorName?: InputMaybe<CreatorName>;
  limitId: Scalars['ID']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  userProfileId: Scalars['ID']['input'];
  xTenantID: Scalars['ID']['input'];
};


export type MutationTransactionLimitsUpdateArgs = {
  budgetLimitsReview?: InputMaybe<BudgetLimitsReview>;
  creatorName?: InputMaybe<CreatorName>;
  limitId: Scalars['ID']['input'];
  userProfileId: Scalars['ID']['input'];
  xTenantID: Scalars['ID']['input'];
};


export type MutationUpdateIdentityUserArgs = {
  identityUserId: Scalars['ID']['input'];
  updateIdentityUsersInput?: InputMaybe<UpdateIdentityUsersInput>;
  xTenantID: Scalars['ID']['input'];
};


export type MutationUpdateUserEmailAndUsernameArgs = {
  updateEmailAndUsernameInput?: InputMaybe<UpdateUserEmailAndUsernameInput>;
  updater?: InputMaybe<Scalars['String']['input']>;
  userProfileId: Scalars['ID']['input'];
  xTenantID: Scalars['ID']['input'];
};


export type MutationUserProfileBonusCancelArgs = {
  userProfileBonusId: Scalars['ID']['input'];
  userProfileId: Scalars['ID']['input'];
  xTenantID: Scalars['ID']['input'];
};


export type MutationUserProfileBonusClaimArgs = {
  userProfileBonusId: Scalars['ID']['input'];
  userProfileId: Scalars['ID']['input'];
  xTenantID: Scalars['ID']['input'];
};


export type MutationUserProfileBonusForfeitArgs = {
  userProfileBonusId: Scalars['ID']['input'];
  userProfileId: Scalars['ID']['input'];
  xTenantID: Scalars['ID']['input'];
};


export type MutationUserProfileUpdateArgs = {
  userProfileId: Scalars['ID']['input'];
  userProfileUpdateInfo?: InputMaybe<UserProfilesUpdateInput>;
  xTenantID: Scalars['ID']['input'];
};


export type MutationUserRestrictionCreateArgs = {
  userProfileId: Scalars['ID']['input'];
  userRestrictionInfo?: InputMaybe<UserRestrictionCreateInput>;
  xTenantID: Scalars['ID']['input'];
};


export type MutationUserRestrictionDeleteArgs = {
  externalId: Scalars['ID']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  userProfileId: Scalars['ID']['input'];
  xTenantID: Scalars['ID']['input'];
};


export type MutationUserSweepstakeAssignArgs = {
  userProfileId: Scalars['ID']['input'];
  userSweepstakeAssignBodyRequest: UserSweepstakeAssignInput;
  xTenantID: Scalars['ID']['input'];
};


export type MutationUserSweepstakeClaimArgs = {
  userProfileId: Scalars['ID']['input'];
  userProfileSweepstakesId: Scalars['ID']['input'];
  xTenantID: Scalars['ID']['input'];
};


export type MutationUserVerifyArgs = {
  userProfileId: Scalars['ID']['input'];
  userVerifyInfo?: InputMaybe<VerifyUserProfileInput>;
  xTenantID: Scalars['ID']['input'];
};

/** An object with a globally unique `ID`. */
export type Node = {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
};

export type Note = {
  __typename?: 'Note';
  createdBy?: Maybe<Scalars['String']['output']>;
  createdOn?: Maybe<Scalars['String']['output']>;
  creator?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  isImportant?: Maybe<Scalars['Boolean']['output']>;
  isPinned?: Maybe<Scalars['Boolean']['output']>;
  lastUpdatedBy?: Maybe<Scalars['String']['output']>;
  lastUpdatedOn?: Maybe<Scalars['String']['output']>;
  lastUpdater?: Maybe<Scalars['String']['output']>;
  noteCategory?: Maybe<NoteCategory>;
  objectNoteGroups?: Maybe<ObjectNotesGroup>;
  objectNotes?: Maybe<Array<Maybe<ObjectNotes>>>;
  value?: Maybe<Scalars['String']['output']>;
};

export type NoteCategory = {
  __typename?: 'NoteCategory';
  createdBy?: Maybe<Scalars['String']['output']>;
  createdOn?: Maybe<Scalars['String']['output']>;
  creator?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  displayCls?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  lastUpdatedBy?: Maybe<Scalars['String']['output']>;
  lastUpdatedOn?: Maybe<Scalars['String']['output']>;
  lastUpdater?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type NoteCategoryCreateAndUpdateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  displayCls?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type NoteCategoryCreateResponse = {
  __typename?: 'NoteCategoryCreateResponse';
  data?: Maybe<NoteCategoryCreateSuccess>;
  error?: Maybe<Array<NotesValidationErrors>>;
};

export type NoteCategoryCreateSuccess = {
  __typename?: 'NoteCategoryCreateSuccess';
  id: Scalars['ID']['output'];
};

export type NoteCreateInput = {
  isImportant?: InputMaybe<Scalars['Boolean']['input']>;
  isPinned?: InputMaybe<Scalars['Boolean']['input']>;
  noteCategoryId?: InputMaybe<Scalars['String']['input']>;
  objectNotes?: InputMaybe<Array<InputMaybe<ObjectNotesInput>>>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type NoteCreateResponse = {
  __typename?: 'NoteCreateResponse';
  data?: Maybe<NoteCreateSuccess>;
  error?: Maybe<Array<NotesValidationErrors>>;
};

export type NoteCreateSuccess = {
  __typename?: 'NoteCreateSuccess';
  id: Scalars['ID']['output'];
};

export type NoteUpdateInput = {
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  isImportant?: InputMaybe<Scalars['Boolean']['input']>;
  isPinned?: InputMaybe<Scalars['Boolean']['input']>;
  noteCategoryId?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type NoteUpdateResponse = {
  __typename?: 'NoteUpdateResponse';
  data?: Maybe<NoteUpdateSuccess>;
  error?: Maybe<Array<NotesValidationErrors>>;
};

export type NoteUpdateSuccess = {
  __typename?: 'NoteUpdateSuccess';
  id: Scalars['ID']['output'];
};

export type NotesCategoryUpdateResponse = {
  __typename?: 'NotesCategoryUpdateResponse';
  data?: Maybe<NotesCategoryUpdateSuccess>;
  error?: Maybe<Array<NotesValidationErrors>>;
};

export type NotesCategoryUpdateSuccess = {
  __typename?: 'NotesCategoryUpdateSuccess';
  id: Scalars['ID']['output'];
};

export type NotesValidationErrors = FieldError | GenericError;

export type NuveiLaunchInput = {
  backUrl?: InputMaybe<Scalars['String']['input']>;
  currencyCode: Scalars['String']['input'];
  deepLinkUrl?: InputMaybe<Scalars['String']['input']>;
  errorUrl?: InputMaybe<Scalars['String']['input']>;
  externalPaymentMethodId?: InputMaybe<Scalars['String']['input']>;
  sessionContextId?: InputMaybe<Scalars['String']['input']>;
  successUrl?: InputMaybe<Scalars['String']['input']>;
};

export type NuveiLaunchResponse = {
  __typename?: 'NuveiLaunchResponse';
  sessionContextExternalId?: Maybe<Scalars['String']['output']>;
  sessionContextId?: Maybe<Scalars['String']['output']>;
  transactionId?: Maybe<Scalars['String']['output']>;
  withdrawalLaunchUrl?: Maybe<Scalars['String']['output']>;
};

export enum NuveiMethodType {
  Deposit = 'Deposit',
  Withdrawal = 'Withdrawal'
}

export type ObjectNotes = {
  __typename?: 'ObjectNotes';
  externalResource?: Maybe<Scalars['String']['output']>;
  externalResourceId?: Maybe<Scalars['String']['output']>;
};

export type ObjectNotesGroup = {
  __typename?: 'ObjectNotesGroup';
  NoteId?: Maybe<Scalars['Int']['output']>;
};

export type ObjectNotesInput = {
  externalResource?: InputMaybe<Scalars['String']['input']>;
  externalResourceId?: InputMaybe<Scalars['String']['input']>;
};

export type ObjectTagUpdated = {
  __typename?: 'ObjectTagUpdated';
  after?: Maybe<Endeavour_Tags_Object_Tags_Dot_Value2>;
  before?: Maybe<Endeavour_Tags_Object_Tags_Dot_Value>;
};

export type ObjectTagsObjectTagResponse = {
  __typename?: 'ObjectTagsObjectTagResponse';
  _fake?: Maybe<Scalars['String']['output']>;
};

export type ObjectTask = {
  externalResource?: InputMaybe<Scalars['String']['input']>;
  externalResourceId?: InputMaybe<Scalars['String']['input']>;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['Cursor']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['Cursor']['output']>;
};

export type PagedPaymentConfig = {
  __typename?: 'PagedPaymentConfig';
  count?: Maybe<Scalars['Float']['output']>;
  data?: Maybe<Array<Maybe<PaymentConfig>>>;
};

export type PagedPaymentIntegrationConfig = {
  __typename?: 'PagedPaymentIntegrationConfig';
  count?: Maybe<Scalars['Float']['output']>;
  data?: Maybe<Array<Maybe<PaymentIntegrationConfig>>>;
};

export type PagedPaymentProviderConfig = {
  __typename?: 'PagedPaymentProviderConfig';
  count?: Maybe<Scalars['Float']['output']>;
  data?: Maybe<Array<Maybe<PaymentProviderConfig>>>;
};

export type PagedTags = {
  __typename?: 'PagedTags';
  count?: Maybe<Scalars['Float']['output']>;
  data?: Maybe<Array<Maybe<Tags>>>;
};

export type PagedUserProfileBonus = {
  __typename?: 'PagedUserProfileBonus';
  count?: Maybe<Scalars['Float']['output']>;
  data?: Maybe<Array<Maybe<UserProfileBonus>>>;
};

export type PagedUserProfileFreeBet = {
  __typename?: 'PagedUserProfileFreeBet';
  count?: Maybe<Scalars['Float']['output']>;
  data?: Maybe<Array<Maybe<UserProfileFreeBet>>>;
};

export type PagedUserProfileFreeSpin = {
  __typename?: 'PagedUserProfileFreeSpin';
  count?: Maybe<Scalars['Float']['output']>;
  data?: Maybe<Array<Maybe<UserProfileFreeSpin>>>;
};

export type PagedUserProfileQuestionnaires = {
  __typename?: 'PagedUserProfileQuestionnaires';
  count?: Maybe<Scalars['Float']['output']>;
  data?: Maybe<Array<Maybe<UserProfileQuestionnaire>>>;
};

export type PaginatedNotes = {
  __typename?: 'PaginatedNotes';
  count?: Maybe<Scalars['Int']['output']>;
  data: Array<Note>;
  enableSubscription: Scalars['Boolean']['output'];
};

export type ParallelActivationsConfiguration = {
  __typename?: 'ParallelActivationsConfiguration';
  AreAllowed: Scalars['Boolean']['output'];
  IsInclusiveFiltering: Scalars['Boolean']['output'];
  JurisdictionCategories?: Maybe<Array<Scalars['String']['output']>>;
};

export type Pay4FunBank = {
  __typename?: 'Pay4FunBank';
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type Pay4FunLaunchInput = {
  /** The transaction amount */
  amount: Scalars['Float']['input'];
  /** The Bank Account. Required if PixKeyType is “DadosBancarios”. */
  bankAccount?: InputMaybe<Scalars['String']['input']>;
  /** The Bank Account Type. Required if PixKeyType is “DadosBancarios”. */
  bankAccountType?: InputMaybe<Scalars['String']['input']>;
  /** The Bank Branch. Required if PixKeyType is “DadosBancarios”. */
  bankBranch?: InputMaybe<Scalars['String']['input']>;
  /** The Bank Code. Required if PixKeyType is “DadosBancarios”. */
  bankCode?: InputMaybe<Scalars['String']['input']>;
  /** The context */
  context?: InputMaybe<Scalars['String']['input']>;
  /** Currency code of the transaction */
  currencyCode: Scalars['String']['input'];
  /** The users email */
  email: Scalars['String']['input'];
  /** The URL where we will redirect in case of a unsuccessful request */
  errorUrl: Scalars['String']['input'];
  /** The Payment Methods External Id */
  externalPaymentMethodId: Scalars['String']['input'];
  /** Language code relayed to play4fun */
  languageCode: Scalars['String']['input'];
  /** The users mobile number without prefix */
  mobileNumberNumber?: InputMaybe<Scalars['String']['input']>;
  /** The users mobile number prefix (country code) */
  mobileNumberPrefix?: InputMaybe<Scalars['String']['input']>;
  /** The pix key type. Required for withdrawals. */
  pixKeyType?: InputMaybe<Scalars['String']['input']>;
  /** Session context ID retrieved from the cashier launch endpoint */
  sessionContextId: Scalars['ID']['input'];
  /** The URL where we will redirect in case of a successful request */
  successUrl: Scalars['String']['input'];
};

export type Pay4FunLaunchResponse = {
  __typename?: 'Pay4FunLaunchResponse';
  cashierLaunchUrl?: Maybe<Scalars['String']['output']>;
  sessionContextId?: Maybe<Scalars['ID']['output']>;
};

export enum Pay4FunMethodType {
  Deposit = 'Deposit',
  Withdrawal = 'Withdrawal'
}

export type PaymentConfig = {
  __typename?: 'PaymentConfig';
  createdBy?: Maybe<Scalars['String']['output']>;
  createdOn?: Maybe<Scalars['String']['output']>;
  creator?: Maybe<Scalars['String']['output']>;
  currencyCode?: Maybe<Scalars['String']['output']>;
  externalAccountId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  lastUpdatedBy?: Maybe<Scalars['String']['output']>;
  lastUpdatedOn?: Maybe<Scalars['String']['output']>;
  lastUpdater?: Maybe<Scalars['String']['output']>;
  launchContext?: Maybe<Scalars['String']['output']>;
  maxAmount?: Maybe<Scalars['Float']['output']>;
  /** @deprecated This fields was removed in favour of maxAmount */
  maxDepositAmount?: Maybe<Scalars['Float']['output']>;
  /** @deprecated This fields was removed in favour of maxAmount */
  maxWithdrawAmount?: Maybe<Scalars['Float']['output']>;
  mediatorId?: Maybe<Scalars['String']['output']>;
  minAmount?: Maybe<Scalars['Float']['output']>;
  /** @deprecated This fields was removed in favour of minAmount */
  minDepositAmount?: Maybe<Scalars['Float']['output']>;
  /** @deprecated This fields was removed in favour of minAmount */
  minWithdrawAmount?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  paymentMethodExternalId?: Maybe<Scalars['String']['output']>;
  paymentType?: Maybe<Scalars['String']['output']>;
  providerConfig?: Maybe<PaymentProviderConfig>;
  providerConfigId?: Maybe<Scalars['String']['output']>;
};


export type PaymentConfigProviderConfigArgs = {
  xTenantID: Scalars['ID']['input'];
};

export type PaymentConfigCValidationErrors = FieldError | GenericError;

export type PaymentConfigCreateInput = {
  currencyCode?: InputMaybe<Scalars['String']['input']>;
  externalAccountId?: InputMaybe<Scalars['String']['input']>;
  maxAmount?: InputMaybe<Scalars['Float']['input']>;
  maxDepositAmount?: InputMaybe<Scalars['Float']['input']>;
  maxWithdrawAmount?: InputMaybe<Scalars['Float']['input']>;
  minAmount?: InputMaybe<Scalars['Float']['input']>;
  minDepositAmount?: InputMaybe<Scalars['Float']['input']>;
  minWithdrawAmount?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  paymentMethodExternalId?: InputMaybe<Scalars['String']['input']>;
  paymentType?: InputMaybe<Scalars['String']['input']>;
  providerConfigId?: InputMaybe<Scalars['String']['input']>;
};

export type PaymentConfigUpdateInput = {
  currencyCode?: InputMaybe<Scalars['String']['input']>;
  externalAccountId?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  maxAmount?: InputMaybe<Scalars['Float']['input']>;
  maxDepositAmount?: InputMaybe<Scalars['Float']['input']>;
  maxWithdrawAmount?: InputMaybe<Scalars['Float']['input']>;
  minAmount?: InputMaybe<Scalars['Float']['input']>;
  minDepositAmount?: InputMaybe<Scalars['Float']['input']>;
  minWithdrawAmount?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  paymentMethodExternalId?: InputMaybe<Scalars['String']['input']>;
  paymentType?: InputMaybe<Scalars['String']['input']>;
  providerConfigId?: InputMaybe<Scalars['String']['input']>;
};

export type PaymentIntegrationConfig = {
  __typename?: 'PaymentIntegrationConfig';
  createdBy?: Maybe<Scalars['String']['output']>;
  createdOn?: Maybe<Scalars['String']['output']>;
  creator?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  integrationId?: Maybe<Scalars['String']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  jurisdictionCodes: Array<Maybe<Scalars['String']['output']>>;
  lastUpdatedBy?: Maybe<Scalars['String']['output']>;
  lastUpdatedOn?: Maybe<Scalars['String']['output']>;
  lastUpdater?: Maybe<Scalars['String']['output']>;
  mediatorId?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type PaymentIntegrationConfigCreateInput = {
  integrationId?: InputMaybe<Scalars['String']['input']>;
  jurisdictionCodes: Array<InputMaybe<Scalars['String']['input']>>;
  mediatorId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type PaymentIntegrationConfigCreateResponse = {
  __typename?: 'PaymentIntegrationConfigCreateResponse';
  data?: Maybe<PaymentIntegrationConfigCreateSuccess>;
  error?: Maybe<Array<PaymentConfigCValidationErrors>>;
};

export type PaymentIntegrationConfigCreateSuccess = {
  __typename?: 'PaymentIntegrationConfigCreateSuccess';
  id: Scalars['ID']['output'];
};

export type PaymentIntegrationConfigUpdateInput = {
  integrationId?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  jurisdictionCodes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type PaymentIntegrationConfigUpdateResponse = {
  __typename?: 'PaymentIntegrationConfigUpdateResponse';
  data?: Maybe<PaymentIntegrationConfig>;
  error?: Maybe<Array<PaymentConfigCValidationErrors>>;
};

export type PaymentMethod = {
  __typename?: 'PaymentMethod';
  /** Contains any extraneous properties defined on the content model */
  additionalProperties?: Maybe<Scalars['JSON']['output']>;
  feePercentage?: Maybe<Scalars['Int']['output']>;
  feeType?: Maybe<Scalars['String']['output']>;
  feeValue?: Maybe<Scalars['Int']['output']>;
  filters: Filters;
  id: Scalars['ID']['output'];
  image?: Maybe<Scalars['String']['output']>;
  integrationId: Scalars['String']['output'];
  isRepeatTransactionAvailable: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  /** @deprecated This field is no longer necessary */
  paymentMethodsId: Scalars['String']['output'];
  presetAmountsOnly: Scalars['Boolean']['output'];
  providerId: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type PaymentMethodConfigCreateResponse = {
  __typename?: 'PaymentMethodConfigCreateResponse';
  data?: Maybe<PaymentMethodConfigCreateSuccess>;
  error?: Maybe<Array<PaymentConfigCValidationErrors>>;
};

export type PaymentMethodConfigCreateSuccess = {
  __typename?: 'PaymentMethodConfigCreateSuccess';
  id: Scalars['ID']['output'];
};

export type PaymentMethodConfigUpdateResponse = {
  __typename?: 'PaymentMethodConfigUpdateResponse';
  data?: Maybe<PaymentConfig>;
  error?: Maybe<Array<PaymentConfigCValidationErrors>>;
};

export type PaymentMethodFilter = {
  slug?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type PaymentProviderConfig = {
  __typename?: 'PaymentProviderConfig';
  createdBy?: Maybe<Scalars['String']['output']>;
  createdOn?: Maybe<Scalars['String']['output']>;
  creator?: Maybe<Scalars['String']['output']>;
  currencyCode?: Maybe<Scalars['String']['output']>;
  externalAccountId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  integrationConfig?: Maybe<PaymentIntegrationConfig>;
  integrationConfigId?: Maybe<Scalars['String']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  lastUpdatedBy?: Maybe<Scalars['String']['output']>;
  lastUpdatedOn?: Maybe<Scalars['String']['output']>;
  lastUpdater?: Maybe<Scalars['String']['output']>;
  launchContext?: Maybe<Scalars['String']['output']>;
  maxDepositAmount?: Maybe<Scalars['Float']['output']>;
  maxWithdrawAmount?: Maybe<Scalars['Float']['output']>;
  mediatorId?: Maybe<Scalars['String']['output']>;
  minDepositAmount?: Maybe<Scalars['Float']['output']>;
  minWithdrawAmount?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  paymentType?: Maybe<Scalars['String']['output']>;
};


export type PaymentProviderConfigIntegrationConfigArgs = {
  xTenantID: Scalars['ID']['input'];
};

export type PaymentProviderConfigCreateInput = {
  currencyCode?: InputMaybe<Scalars['String']['input']>;
  externalAccountId?: InputMaybe<Scalars['String']['input']>;
  integrationConfigId?: InputMaybe<Scalars['String']['input']>;
  launchContext?: InputMaybe<Scalars['String']['input']>;
  maxDepositAmount?: InputMaybe<Scalars['Float']['input']>;
  maxWithdrawAmount?: InputMaybe<Scalars['Float']['input']>;
  minDepositAmount?: InputMaybe<Scalars['Float']['input']>;
  minWithdrawAmount?: InputMaybe<Scalars['Float']['input']>;
};

export type PaymentProviderConfigCreateResponse = {
  __typename?: 'PaymentProviderConfigCreateResponse';
  data?: Maybe<PaymentProviderConfigCreateSuccess>;
  error?: Maybe<Array<PaymentConfigCValidationErrors>>;
};

export type PaymentProviderConfigCreateSuccess = {
  __typename?: 'PaymentProviderConfigCreateSuccess';
  id: Scalars['ID']['output'];
};

export type PaymentProviderConfigUpdateInput = {
  currencyCode?: InputMaybe<Scalars['String']['input']>;
  externalAccountId?: InputMaybe<Scalars['String']['input']>;
  integrationConfigId?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  launchContext?: InputMaybe<Scalars['String']['input']>;
  maxDepositAmount?: InputMaybe<Scalars['Float']['input']>;
  maxWithdrawAmount?: InputMaybe<Scalars['Float']['input']>;
  mediatorId?: InputMaybe<Scalars['String']['input']>;
  minDepositAmount?: InputMaybe<Scalars['Float']['input']>;
  minWithdrawAmount?: InputMaybe<Scalars['Float']['input']>;
};

export type PaymentProviderConfigUpdateResponse = {
  __typename?: 'PaymentProviderConfigUpdateResponse';
  data?: Maybe<PaymentProviderConfig>;
  error?: Maybe<Array<PaymentConfigCValidationErrors>>;
};

export type PaymentsApproveBodyRequest = {
  reason?: InputMaybe<Scalars['String']['input']>;
  transactionId?: InputMaybe<Scalars['ID']['input']>;
};

export type PaymentsApproveResponse = {
  __typename?: 'PaymentsApproveResponse';
  data?: Maybe<PaymentsApproveSuccess>;
  error?: Maybe<Array<PaymentsTypesValidationErrors>>;
};

export type PaymentsApproveSuccess = {
  __typename?: 'PaymentsApproveSuccess';
  errorCode?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type PaymentsDenyBodyRequest = {
  reason?: InputMaybe<Scalars['String']['input']>;
  transactionId?: InputMaybe<Scalars['ID']['input']>;
};

export type PaymentsDenyResponse = {
  __typename?: 'PaymentsDenyResponse';
  data?: Maybe<PaymentsDenySuccess>;
  error?: Maybe<Array<PaymentsTypesValidationErrors>>;
};

export type PaymentsDenySuccess = {
  __typename?: 'PaymentsDenySuccess';
  errorCode?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type PaymentsTypesValidationErrors = FieldError | GenericError;

export type Permission = {
  __typename?: 'Permission';
  resource?: Maybe<Scalars['String']['output']>;
  verbs?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type Person = {
  __typename?: 'Person';
  additionalName?: Maybe<Scalars['String']['output']>;
  address?: Maybe<Address>;
  birthDate?: Maybe<Scalars['String']['output']>;
  birthPlace?: Maybe<Address>;
  deathDate?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  familyName?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Scalars['String']['output']>;
  givenName?: Maybe<Scalars['String']['output']>;
  honorificPrefix?: Maybe<Scalars['String']['output']>;
  honorificSuffix?: Maybe<Scalars['String']['output']>;
  jobTitle?: Maybe<Scalars['String']['output']>;
  knowsLanguage?: Maybe<Scalars['String']['output']>;
  mobileNumber?: Maybe<TelephoneNumber>;
  nationality?: Maybe<Scalars['String']['output']>;
  permanentAddress?: Maybe<Address>;
  protectedTaxId?: Maybe<Scalars['Boolean']['output']>;
  spouseId?: Maybe<Scalars['String']['output']>;
  taxId?: Maybe<Scalars['String']['output']>;
  telephoneNumber?: Maybe<TelephoneNumber>;
  vatId?: Maybe<Scalars['String']['output']>;
};

export type ProductMarketingAllowed = {
  __typename?: 'ProductMarketingAllowed';
  bingo?: Maybe<VerticalProductMarketingAllowed>;
  casino?: Maybe<VerticalProductMarketingAllowed>;
  lottery?: Maybe<VerticalProductMarketingAllowed>;
  poker?: Maybe<VerticalProductMarketingAllowed>;
  sports?: Maybe<VerticalProductMarketingAllowed>;
};

export type ProductMetadata = {
  __typename?: 'ProductMetadata';
  alternativeName?: Maybe<Scalars['String']['output']>;
  brand?: Maybe<BrandMetadata>;
  category?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  image?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  review?: Maybe<ReviewMetadata>;
};

export type ProfileRiskScoresStatus = {
  __typename?: 'ProfileRiskScoresStatus';
  affordability?: Maybe<Scalars['String']['output']>;
  aml?: Maybe<Scalars['String']['output']>;
  responsibleGaming?: Maybe<Scalars['String']['output']>;
  saferGambling?: Maybe<Scalars['String']['output']>;
};

export type ProfileVerificationStatus = {
  __typename?: 'ProfileVerificationStatus';
  code?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type Query = Node & {
  __typename?: 'Query';
  /** adminCategories: Gets all the Admin Categories */
  adminCategories: Array<Maybe<AdminCategory>>;
  /** Reads and enables pagination through a set of `FeedType`. */
  allFeedTypes?: Maybe<FeedTypesConnection>;
  /** Reads a set of `FeedType`. */
  allFeedTypesList?: Maybe<Array<FeedType>>;
  /** Reads and enables pagination through a set of `UserActivity`. */
  allUserActivities?: Maybe<UserActivitiesConnection>;
  /** Reads a set of `UserActivity`. */
  allUserActivitiesList?: Maybe<Array<UserActivity>>;
  /** assignedQuestionnaireByIdToUser: Gets Assigned Questionnaire by id for as user profie */
  assignedQuestionnaireByIdToUser: UserProfileQuestionnaire;
  boLanguages: Array<Languages>;
  /** bonusTemplates: Gets all bonus templates */
  bonusTemplates: BonusPagedTemplates;
  /** casinoGameConfigs: Gets all Game Configs */
  casinoGameConfigs: CasinoPagedGameConfig;
  /** casinoGameConfigsById: Gets Game Configs by Id */
  casinoGameConfigsById: CasinoGameConfig;
  /** casinoIntegrationConfigById: Gets Integration Configs by Id */
  casinoIntegrationConfigById: CasinoIntegrationConfig;
  /** casinoIntegrationConfigs: Gets all Game Configs */
  casinoIntegrationConfigs: CasinoPagedIntegrationConfig;
  /** casinoProviderConfigs: Gets all Provider Configs */
  casinoProviderConfigs: CasinoPagedProviderConfig;
  /** casinoProviderConfigsById: Gets Provider Configs by Id */
  casinoProviderConfigsById: CasinoProviderConfig;
  /** documentVerificationData: Returns a single data verification document information */
  documentVerificationData: Scalars['JSON']['output'];
  /** documentVerifications: Returns document verifications associated with a document. */
  documentVerifications: Array<Maybe<DocumentVerification>>;
  empty?: Maybe<Scalars['String']['output']>;
  /** freeBetConfigurationSchema: Gets freeBet Configuration schema */
  freeBetConfigurationSchema: Scalars['JSON']['output'];
  /** freeBetTemplates: Gets all free bet templates */
  freeBetTemplates: FreeBetPagedTemplates;
  /** freeSpinConfigurationSchema: Gets freeSpin Configuration schema */
  freeSpinConfigurationSchema: Scalars['JSON']['output'];
  /** freeSpinTemplates: Gets all free spin templates */
  freeSpinTemplates: FreeSpinPagedTemplates;
  /** gameCategories: Gets all the Game Categories */
  gameCategories: Array<Maybe<GameCategory>>;
  getAllDocuments: Array<Maybe<Documents>>;
  /** getTasks: Gets all tasks for a backoffice user. */
  getBackOfficeUserTasks: TaskResponse;
  /** getBackOfficeUserTasksById: Gets a task by id for a backoffice user. */
  getBackOfficeUserTasksById: TaskTemplate;
  getBannerCollections: Array<BannerCollection>;
  getBoTenantConfig?: Maybe<BoTenantConfigResponse>;
  getBoTenantList?: Maybe<BoTenantListResponse>;
  getBoTranslations: Array<TranslationsBo>;
  getCategoriesByLobbyId: Array<Maybe<GameCategoriesByLobbyId>>;
  getCmsGameSearch: Array<Game>;
  getDocumentBySlug?: Maybe<Documents>;
  /** Returns the enabled geolocation provider for the user/tenant/skin */
  getEnhancedGeolocationProvider: GeoProviderConfig;
  getFooterCollections?: Maybe<Array<Maybe<FooterCollection>>>;
  getGameSearch: Array<Game>;
  getGamesByCategory?: Maybe<GamesResponse>;
  getGamesByCategoryList?: Maybe<GamesResponse>;
  getGamesBySlugs?: Maybe<Array<Game>>;
  getGamesLobby: Array<Maybe<Lobby>>;
  getGamesLobbyById: Array<Maybe<Lobby>>;
  getLaunchURLs?: Maybe<Array<GameLaunchResponse>>;
  getPaymentMethods: Array<Maybe<PaymentMethod>>;
  /** getRealityCheck: Gets a Reality-Check. */
  getRealityCheck: RealityCheckResponse;
  /** getRealityCheckConfig: Get Reality-Check config. */
  getRealityCheckConfig: RealityCheckConfigResponse;
  /** getRealityCheckConfigs: Get Reality-Check configs. */
  getRealityCheckConfigs: RealityCheckConfigsResponse;
  getSimilarGames: Array<Game>;
  getSportsLaunchUrl?: Maybe<SportsLaunchResponse>;
  /** getTasks: Gets all tasks. */
  getTasks: TaskResponse;
  /** getTasksById: Gets tasks by id. */
  getTasksById: TaskTemplate;
  getTranslations: Array<Translations>;
  /** identityRoleById: Gets Identity Role by ID */
  identityRoleById?: Maybe<IdentityRolesValues>;
  /** identityRoles: Gets all Identity Roles */
  identityRoles: Array<Maybe<IdentityRolesValues>>;
  /** identityRolesByUserId: Gets all Identity Roles by User Id */
  identityRolesByUserId: Array<Maybe<IdentityRolesValues>>;
  /** identityUsers: Gets all Identity Users */
  identityUsers: AdministrationPagedIdentityUsers;
  /** identityUsersById: Gets Identity Users by Id */
  identityUsersById: AdministrationConfig;
  insightsSheets: Array<InsightsSheet>;
  insightsToken: InsightsTokenDetails;
  /** Checks whether the user is allowed to access the site from their IP address location */
  isGeoLocationAllowed?: Maybe<GeoLocationResponse>;
  livespinsLaunchUrl?: Maybe<LivespinsLaunchResponse>;
  metatags?: Maybe<MetaTags>;
  /** Fetches an object given its globally unique `ID`. */
  node?: Maybe<Node>;
  /** The root query type must be a `Node` to work well with Relay 1 mutations. This just resolves to `query`. */
  nodeId: Scalars['ID']['output'];
  /** notes: Get notes */
  notes?: Maybe<PaginatedNotes>;
  /** notes: Get notes by id */
  notesById: Note;
  /** notesCategories: Get all note categories */
  notesCategories?: Maybe<Array<NoteCategory>>;
  /** notesCategoriesById: Get note categories by id */
  notesCategoriesById: NoteCategory;
  /** Returns the list of banks to withdraw from */
  pay4funBanks: Array<Pay4FunBank>;
  /** paymentIntegrationConfigById: Gets Integration Configs by Id */
  paymentIntegrationConfigById: PaymentIntegrationConfig;
  /** paymentIntegrationConfigs: Gets all payments Configs */
  paymentIntegrationConfigs: PagedPaymentIntegrationConfig;
  /** paymentMethodById: Gets Payment Configs by Id */
  paymentMethodById: PaymentConfig;
  /** paymentMethodConfigs: Gets all Payment Configs */
  paymentMethodConfigs: PagedPaymentConfig;
  /** paymentProviderConfigs: Gets all Provider Configs */
  paymentProviderConfigs: PagedPaymentProviderConfig;
  /** paymentProviderConfigsById: Gets Provider Configs by Id */
  paymentProviderConfigsById: PaymentProviderConfig;
  /** permissions: Gets entrypoints permissions data */
  permissions?: Maybe<Array<Maybe<Permission>>>;
  productMetadata?: Maybe<ProductMetadata>;
  /**
   * Exposes the root query type nested one level down. This is helpful for Relay 1
   * which can only query top level fields if they are in a particular form.
   */
  query: Query;
  /** questionnaireTemplates: Gets all Questionnaire Templates */
  questionnaireTemplates: QuestionnaireTemplatesListResponse;
  /** questionnaireTemplatesById: Gets Questionnaire Template by id */
  questionnaireTemplatesById: QuestionnaireTemplatesValues;
  /** questionnaireTypeById: Gets Questionnaire Types by id */
  questionnaireTypeById: QuestionnaireTypesValues;
  /** questionnaireTypes: Gets all Questionnaire Types */
  questionnaireTypes: QuestionnaireTypesListResponse;
  /** relatedIdentityRoles: Gets all Related Identity Roles */
  relatedIdentityRoles: Array<Maybe<IdentityRolesValues>>;
  reportHierarchy: Array<ReportHierarchyItem>;
  reportParameters: Array<ReportParameter>;
  /** sessionContext: Returns A list active session contexts */
  sessionContext: Array<Maybe<SessionContextResponse>>;
  /** sportsIntegrationConfigById: Gets Integration Configs by Id */
  sportsIntegrationConfigById: SportsIntegrationConfig;
  /** sportsIntegrationConfigs: Gets all Game Configs */
  sportsIntegrationConfigs: SportsPagedIntegrationConfig;
  /** sportsProviderConfigs: Gets all Provider Configs */
  sportsProviderConfigs: SportsPagedProviderConfig;
  /** sportsProviderConfigsById: Gets Provider Configs by Id */
  sportsProviderConfigsById: SportsProviderConfig;
  /** sweepstakeTemplates: Gets all Sweepstake Templates */
  sweepstakeTemplates: SweepstakeTemplatesListResponse;
  /** tags: Gets all Tags */
  tags?: Maybe<PagedTags>;
  /** tagsById: List of objects tagged by tagId */
  tagsById: Array<Maybe<TagsObject>>;
  /** tagsCategories: Gets all Tags Categories */
  tagsCategories: Array<Maybe<TagCategory>>;
  /** tagsCategoryById: Get tag category by id */
  tagsCategoryById: TagCategory;
  /** tagsObject: Get object tags */
  tagsObject?: Maybe<Array<TagsObject>>;
  /** Gets the policy configuration for the tenant specified */
  tenantConfiguration?: Maybe<TenantConfiguration>;
  /** Reads a single `UserActivity` using its globally unique `ID`. */
  userActivity?: Maybe<UserActivity>;
  userActivityById?: Maybe<UserActivity>;
  /** userBonuses: Gets all user profile bonuses */
  userBonuses: PagedUserProfileBonus;
  /** userBudgetLimits: Returns the complete data list of a specific user created budget limits */
  userBudgetLimits?: Maybe<Array<Maybe<BudgetLimitsTransactionResponse>>>;
  userCombinedLimits?: Maybe<Array<Maybe<CombinedLimits>>>;
  /** userDataVerifications: Retrieves Data Verifications associated with a user profile */
  userDataVerifications?: Maybe<Array<UserDataVerifications>>;
  /** userDocuments: Returns documents associated with a user profile */
  userDocuments?: Maybe<Array<Document>>;
  /** userFreeBet: Gets user free bet */
  userFreeBet?: Maybe<PagedUserProfileFreeBet>;
  /** userFreeSpin: Gets user free spin */
  userFreeSpin?: Maybe<PagedUserProfileFreeSpin>;
  /** userMaxWagerLimits: Returns the data list of a specific user created max wagered limits */
  userMaxWagerLimits: Array<LimitsMaxWagerResponse>;
  /** userMonitorings: Returns monitorings associated with a user profile */
  userMonitorings?: Maybe<Array<Monitoring>>;
  /** userProfile: Returns the data of a specific user profile */
  userProfile: UserProfile;
  /** userQuestionnaires: Gets all Questionnaires for a user profile */
  userQuestionnaires: PagedUserProfileQuestionnaires;
  /** userRestrictions: Returns the data of a specific user restrictions */
  userRestrictions?: Maybe<Array<Maybe<UserRestriction>>>;
  /** userScores: Returns the scores associated with a user profile */
  userScores?: Maybe<Array<Scores>>;
  /** userSuspensionLimits: Returns the data list of a specific user created suspension limits */
  userSuspensionLimits: Array<SuspensionLimitsResponse>;
  /** userSweepstakes: Gets all user profile sweepstakes. */
  userSweepstakes: UserSweepstakeResponse;
  /** Reads and enables pagination through a set of `UserTransaction`. */
  userTransactions?: Maybe<UserTransactionsConnection>;
  /** userWallets: Returns the user wallet lists */
  userWallets: Array<Maybe<UserWallets>>;
  /** usersSessionLimitsContinuous: Returns the data list of a specific user created session limits */
  usersSessionLimitsContinuous: Array<SessionLimitsContinuousResponse>;
  /** usersSessionLimitsCumulative: Returns the data list of a specific user created session limits */
  usersSessionLimitsCumulative: Array<SessionLimitsCumulativeResponse>;
  /** Retrieves a wallet transaction for the given user profile id and wallet id */
  walletTransaction?: Maybe<Transaction>;
};


export type QueryAdminCategoriesArgs = {
  xTenantID: Scalars['ID']['input'];
};


export type QueryAllFeedTypesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<FeedTypeCondition>;
  filter?: InputMaybe<FeedTypeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedTypesOrderBy>>;
};


export type QueryAllFeedTypesListArgs = {
  condition?: InputMaybe<FeedTypeCondition>;
  filter?: InputMaybe<FeedTypeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedTypesOrderBy>>;
};


export type QueryAllUserActivitiesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<UserActivityCondition>;
  filter?: InputMaybe<UserActivityFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UserActivitiesOrderBy>>;
};


export type QueryAllUserActivitiesListArgs = {
  condition?: InputMaybe<UserActivityCondition>;
  filter?: InputMaybe<UserActivityFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UserActivitiesOrderBy>>;
};


export type QueryAssignedQuestionnaireByIdToUserArgs = {
  questionnaireId: Scalars['ID']['input'];
  userProfileId: Scalars['ID']['input'];
  xTenantID: Scalars['ID']['input'];
};


export type QueryBonusTemplatesArgs = {
  filter?: InputMaybe<Array<InputMaybe<AggregateFilters>>>;
  pageLimit?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  xTenantID: Scalars['ID']['input'];
};


export type QueryCasinoGameConfigsArgs = {
  filter?: InputMaybe<Array<InputMaybe<AggregateFilters>>>;
  pageLimit?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  xTenantID: Scalars['ID']['input'];
};


export type QueryCasinoGameConfigsByIdArgs = {
  configId: Scalars['ID']['input'];
  xTenantID: Scalars['ID']['input'];
};


export type QueryCasinoIntegrationConfigByIdArgs = {
  configId: Scalars['ID']['input'];
  xTenantID: Scalars['ID']['input'];
};


export type QueryCasinoIntegrationConfigsArgs = {
  filter?: InputMaybe<Array<InputMaybe<AggregateFilters>>>;
  pageLimit?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  xTenantID: Scalars['ID']['input'];
};


export type QueryCasinoProviderConfigsArgs = {
  filter?: InputMaybe<Array<InputMaybe<AggregateFilters>>>;
  pageLimit?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  xTenantID: Scalars['ID']['input'];
};


export type QueryCasinoProviderConfigsByIdArgs = {
  configId: Scalars['ID']['input'];
  xTenantID: Scalars['ID']['input'];
};


export type QueryDocumentVerificationDataArgs = {
  documentId: Scalars['ID']['input'];
  userProfileId: Scalars['ID']['input'];
  xTenantID: Scalars['ID']['input'];
};


export type QueryDocumentVerificationsArgs = {
  documentId: Scalars['ID']['input'];
  userProfileId: Scalars['ID']['input'];
  xTenantID: Scalars['ID']['input'];
};


export type QueryFreeBetConfigurationSchemaArgs = {
  mediatorId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryFreeBetTemplatesArgs = {
  filter?: InputMaybe<Array<InputMaybe<AggregateFilters>>>;
  pageLimit?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  xTenantID: Scalars['ID']['input'];
};


export type QueryFreeSpinConfigurationSchemaArgs = {
  mediatorId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryFreeSpinTemplatesArgs = {
  filter?: InputMaybe<Array<InputMaybe<AggregateFilters>>>;
  pageLimit?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  xTenantID: Scalars['ID']['input'];
};


export type QueryGameCategoriesArgs = {
  xTenantID: Scalars['ID']['input'];
};


export type QueryGetAllDocumentsArgs = {
  locale: Scalars['String']['input'];
  skinId: Scalars['String']['input'];
  xTenantID: Scalars['String']['input'];
};


export type QueryGetBackOfficeUserTasksArgs = {
  backOfficeUserId: Scalars['ID']['input'];
  filter?: InputMaybe<Array<InputMaybe<AggregateFilters>>>;
  pageLimit?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  xTenantID: Scalars['ID']['input'];
};


export type QueryGetBackOfficeUserTasksByIdArgs = {
  backOfficeUserId: Scalars['ID']['input'];
  taskId: Scalars['ID']['input'];
  xTenantID: Scalars['ID']['input'];
};


export type QueryGetBannerCollectionsArgs = {
  locale: Scalars['String']['input'];
  skinId: Scalars['String']['input'];
  xTenantID: Scalars['String']['input'];
};


export type QueryGetBoTenantConfigArgs = {
  xTenantID: Scalars['String']['input'];
};


export type QueryGetBoTenantListArgs = {
  xTenantID: Scalars['String']['input'];
};


export type QueryGetBoTranslationsArgs = {
  locale: Scalars['String']['input'];
};


export type QueryGetCategoriesByLobbyIdArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  lobbyId: Scalars['String']['input'];
  locale: Scalars['String']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  skinId: Scalars['String']['input'];
  xTenantID: Scalars['String']['input'];
};


export type QueryGetCmsGameSearchArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: Scalars['String']['input'];
  searchTerm: Scalars['String']['input'];
  skinId: Scalars['String']['input'];
  xTenantID: Scalars['String']['input'];
};


export type QueryGetDocumentBySlugArgs = {
  locale: Scalars['String']['input'];
  skinId: Scalars['String']['input'];
  slug: Scalars['String']['input'];
  version?: InputMaybe<Scalars['String']['input']>;
  xTenantID: Scalars['String']['input'];
};


export type QueryGetEnhancedGeolocationProviderArgs = {
  skinId: Scalars['String']['input'];
  userProfileId: Scalars['String']['input'];
  xTenantID: Scalars['String']['input'];
};


export type QueryGetFooterCollectionsArgs = {
  locale: Scalars['String']['input'];
  skinId: Scalars['String']['input'];
  xTenantID: Scalars['String']['input'];
};


export type QueryGetGameSearchArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: Scalars['String']['input'];
  searchTerm: Scalars['String']['input'];
  skinId: Scalars['String']['input'];
  xTenantID: Scalars['String']['input'];
};


export type QueryGetGamesByCategoryArgs = {
  gamesCategoryId: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: Scalars['String']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  skinId: Scalars['String']['input'];
  xTenantID: Scalars['String']['input'];
};


export type QueryGetGamesByCategoryListArgs = {
  gamesCategoryId: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: Scalars['String']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  skinId: Scalars['String']['input'];
  xTenantID: Scalars['String']['input'];
};


export type QueryGetGamesBySlugsArgs = {
  locale: Scalars['String']['input'];
  skinId: Scalars['String']['input'];
  slugs: Array<Scalars['String']['input']>;
  xTenantID: Scalars['String']['input'];
};


export type QueryGetGamesLobbyArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: Scalars['String']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  skinId: Scalars['String']['input'];
  xTenantID: Scalars['String']['input'];
};


export type QueryGetGamesLobbyByIdArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  lobbyId: Scalars['String']['input'];
  locale: Scalars['String']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  skinId: Scalars['String']['input'];
  xTenantID: Scalars['String']['input'];
};


export type QueryGetLaunchUrLsArgs = {
  details: GameLaunchDetails;
  games: Array<GameLaunchGame>;
  sessionExpiresIn?: InputMaybe<Scalars['Int']['input']>;
  skinId: Scalars['String']['input'];
  xTenantID: Scalars['String']['input'];
};


export type QueryGetPaymentMethodsArgs = {
  filter: PaymentMethodFilter;
  locale: Scalars['String']['input'];
  skinId: Scalars['String']['input'];
  xTenantID: Scalars['String']['input'];
};


export type QueryGetRealityCheckArgs = {
  realityCheckId: Scalars['ID']['input'];
  userProfileId: Scalars['ID']['input'];
  xTenantID: Scalars['ID']['input'];
};


export type QueryGetRealityCheckConfigArgs = {
  id: Scalars['ID']['input'];
  userProfileId: Scalars['ID']['input'];
  xTenantID: Scalars['ID']['input'];
};


export type QueryGetRealityCheckConfigsArgs = {
  userProfileId: Scalars['ID']['input'];
  xTenantID: Scalars['ID']['input'];
};


export type QueryGetSimilarGamesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: Scalars['String']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  skinId: Scalars['String']['input'];
  slugs: Array<Scalars['String']['input']>;
  xTenantID: Scalars['String']['input'];
};


export type QueryGetSportsLaunchUrlArgs = {
  details: SportsLaunchRequest;
  sportsbookIntegration: Scalars['String']['input'];
  xTenantID: Scalars['String']['input'];
};


export type QueryGetTasksArgs = {
  externalResource?: InputMaybe<Scalars['String']['input']>;
  externalResourceId?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Array<InputMaybe<AggregateFilters>>>;
  pageLimit?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  xTenantID: Scalars['ID']['input'];
};


export type QueryGetTasksByIdArgs = {
  taskId: Scalars['ID']['input'];
  xTenantID: Scalars['ID']['input'];
};


export type QueryGetTranslationsArgs = {
  locale: Scalars['String']['input'];
  skinId: Scalars['String']['input'];
  xTenantID: Scalars['String']['input'];
};


export type QueryIdentityRoleByIdArgs = {
  id: Scalars['ID']['input'];
  xTenantID: Scalars['ID']['input'];
};


export type QueryIdentityRolesArgs = {
  xTenantID: Scalars['ID']['input'];
};


export type QueryIdentityRolesByUserIdArgs = {
  identityUserId: Scalars['ID']['input'];
  xTenantID: Scalars['ID']['input'];
};


export type QueryIdentityUsersArgs = {
  filter?: InputMaybe<Array<InputMaybe<AggregateFilters>>>;
  pageLimit?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  xTenantID: Scalars['ID']['input'];
};


export type QueryIdentityUsersByIdArgs = {
  identityUserId: Scalars['ID']['input'];
  xTenantID: Scalars['ID']['input'];
};


export type QueryInsightsSheetsArgs = {
  insightsToken?: InputMaybe<Scalars['String']['input']>;
  xTenantID: Scalars['String']['input'];
};


export type QueryInsightsTokenArgs = {
  xTenantID: Scalars['String']['input'];
};


export type QueryIsGeoLocationAllowedArgs = {
  xTenantID: Scalars['String']['input'];
};


export type QueryLivespinsLaunchUrlArgs = {
  launchInfo?: InputMaybe<LivespinsLaunchRequest>;
  userProfileId: Scalars['ID']['input'];
  xTenantID: Scalars['ID']['input'];
};


export type QueryMetatagsArgs = {
  id: Scalars['String']['input'];
  locale: Scalars['String']['input'];
  skinId: Scalars['String']['input'];
  xTenantID: Scalars['String']['input'];
};


export type QueryNodeArgs = {
  nodeId: Scalars['ID']['input'];
};


export type QueryNotesArgs = {
  externalResource?: InputMaybe<Scalars['String']['input']>;
  externalResourceId?: InputMaybe<Scalars['ID']['input']>;
  filter?: InputMaybe<Array<InputMaybe<AggregateFilters>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  xTenantID: Scalars['ID']['input'];
};


export type QueryNotesByIdArgs = {
  id: Scalars['ID']['input'];
  xTenantID: Scalars['ID']['input'];
};


export type QueryNotesCategoriesArgs = {
  xTenantID: Scalars['ID']['input'];
};


export type QueryNotesCategoriesByIdArgs = {
  id: Scalars['ID']['input'];
  xTenantID: Scalars['ID']['input'];
};


export type QueryPay4funBanksArgs = {
  sessionContextId: Scalars['String']['input'];
  xTenantID: Scalars['String']['input'];
};


export type QueryPaymentIntegrationConfigByIdArgs = {
  configId: Scalars['ID']['input'];
  xTenantID: Scalars['ID']['input'];
};


export type QueryPaymentIntegrationConfigsArgs = {
  filter?: InputMaybe<Array<InputMaybe<AggregateFilters>>>;
  pageLimit?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  xTenantID: Scalars['ID']['input'];
};


export type QueryPaymentMethodByIdArgs = {
  configId: Scalars['ID']['input'];
  xTenantID: Scalars['ID']['input'];
};


export type QueryPaymentMethodConfigsArgs = {
  filter?: InputMaybe<Array<InputMaybe<AggregateFilters>>>;
  pageLimit?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  xTenantID: Scalars['ID']['input'];
};


export type QueryPaymentProviderConfigsArgs = {
  filter?: InputMaybe<Array<InputMaybe<AggregateFilters>>>;
  pageLimit?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  xTenantID: Scalars['ID']['input'];
};


export type QueryPaymentProviderConfigsByIdArgs = {
  configId: Scalars['ID']['input'];
  xTenantID: Scalars['ID']['input'];
};


export type QueryPermissionsArgs = {
  userRoles: Array<InputMaybe<Scalars['String']['input']>>;
  xTenantID: Scalars['ID']['input'];
};


export type QueryProductMetadataArgs = {
  id: Scalars['String']['input'];
  locale: Scalars['String']['input'];
  skinId: Scalars['String']['input'];
  xTenantID: Scalars['String']['input'];
};


export type QueryQuestionnaireTemplatesArgs = {
  filter?: InputMaybe<Array<InputMaybe<AggregateFilters>>>;
  pageLimit?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  xTenantID: Scalars['ID']['input'];
};


export type QueryQuestionnaireTemplatesByIdArgs = {
  id: Scalars['ID']['input'];
  xTenantID: Scalars['ID']['input'];
};


export type QueryQuestionnaireTypeByIdArgs = {
  id: Scalars['ID']['input'];
  xTenantID: Scalars['ID']['input'];
};


export type QueryQuestionnaireTypesArgs = {
  filter?: InputMaybe<Array<InputMaybe<AggregateFilters>>>;
  pageLimit?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  xTenantID: Scalars['ID']['input'];
};


export type QueryRelatedIdentityRolesArgs = {
  identityRoleId: Scalars['ID']['input'];
  xTenantID: Scalars['ID']['input'];
};


export type QueryReportHierarchyArgs = {
  xTenantID: Scalars['String']['input'];
};


export type QueryReportParametersArgs = {
  reportId: Scalars['String']['input'];
  xTenantID: Scalars['String']['input'];
};


export type QuerySessionContextArgs = {
  userProfileId: Scalars['ID']['input'];
  xTenantID: Scalars['ID']['input'];
};


export type QuerySportsIntegrationConfigByIdArgs = {
  configId: Scalars['ID']['input'];
  xTenantID: Scalars['ID']['input'];
};


export type QuerySportsIntegrationConfigsArgs = {
  filter?: InputMaybe<Array<InputMaybe<AggregateFilters>>>;
  pageLimit?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  xTenantID: Scalars['ID']['input'];
};


export type QuerySportsProviderConfigsArgs = {
  filter?: InputMaybe<Array<InputMaybe<AggregateFilters>>>;
  pageLimit?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  xTenantID: Scalars['ID']['input'];
};


export type QuerySportsProviderConfigsByIdArgs = {
  configId: Scalars['ID']['input'];
  xTenantID: Scalars['ID']['input'];
};


export type QuerySweepstakeTemplatesArgs = {
  filter?: InputMaybe<Array<InputMaybe<AggregateFilters>>>;
  pageLimit?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  xTenantID: Scalars['ID']['input'];
};


export type QueryTagsArgs = {
  filter?: InputMaybe<Array<InputMaybe<AggregateFilters>>>;
  pageLimit?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  xTenantID: Scalars['ID']['input'];
};


export type QueryTagsByIdArgs = {
  tagId: Scalars['ID']['input'];
  xTenantID: Scalars['ID']['input'];
};


export type QueryTagsCategoriesArgs = {
  xTenantID: Scalars['ID']['input'];
};


export type QueryTagsCategoryByIdArgs = {
  tagCategoryId: Scalars['ID']['input'];
  xTenantID: Scalars['ID']['input'];
};


export type QueryTagsObjectArgs = {
  externalResource: Scalars['String']['input'];
  externalResourceId: Scalars['String']['input'];
  xTenantID: Scalars['ID']['input'];
};


export type QueryTenantConfigurationArgs = {
  xTenantID: Scalars['ID']['input'];
};


export type QueryUserActivityArgs = {
  nodeId: Scalars['ID']['input'];
};


export type QueryUserActivityByIdArgs = {
  id: Scalars['String']['input'];
};


export type QueryUserBonusesArgs = {
  filter?: InputMaybe<Array<InputMaybe<AggregateFilters>>>;
  pageLimit?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  userProfileId: Scalars['ID']['input'];
  xTenantID: Scalars['ID']['input'];
};


export type QueryUserBudgetLimitsArgs = {
  userProfileId: Scalars['ID']['input'];
  xTenantID: Scalars['ID']['input'];
};


export type QueryUserCombinedLimitsArgs = {
  userProfileId: Scalars['ID']['input'];
  xTenantID: Scalars['ID']['input'];
};


export type QueryUserDataVerificationsArgs = {
  userProfileId: Scalars['ID']['input'];
  xTenantID: Scalars['ID']['input'];
};


export type QueryUserDocumentsArgs = {
  userProfileId: Scalars['ID']['input'];
  xTenantID: Scalars['ID']['input'];
};


export type QueryUserFreeBetArgs = {
  filter?: InputMaybe<Array<InputMaybe<AggregateFilters>>>;
  pageLimit?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  userProfileId: Scalars['ID']['input'];
  xTenantID: Scalars['ID']['input'];
};


export type QueryUserFreeSpinArgs = {
  filter?: InputMaybe<Array<InputMaybe<AggregateFilters>>>;
  pageLimit?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  userProfileId: Scalars['ID']['input'];
  xTenantID: Scalars['ID']['input'];
};


export type QueryUserMaxWagerLimitsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  userProfileId: Scalars['ID']['input'];
  xTenantID: Scalars['ID']['input'];
};


export type QueryUserMonitoringsArgs = {
  userProfileId: Scalars['ID']['input'];
  xTenantID: Scalars['ID']['input'];
};


export type QueryUserProfileArgs = {
  userProfileId: Scalars['ID']['input'];
  xTenantID: Scalars['ID']['input'];
};


export type QueryUserQuestionnairesArgs = {
  filter?: InputMaybe<Array<InputMaybe<AggregateFilters>>>;
  pageLimit?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  userProfileId: Scalars['ID']['input'];
  xTenantID: Scalars['ID']['input'];
};


export type QueryUserRestrictionsArgs = {
  userProfileId: Scalars['ID']['input'];
  xTenantID: Scalars['ID']['input'];
};


export type QueryUserScoresArgs = {
  userProfileId: Scalars['ID']['input'];
  xTenantID: Scalars['ID']['input'];
};


export type QueryUserSuspensionLimitsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  userProfileId: Scalars['ID']['input'];
  xTenantID: Scalars['ID']['input'];
};


export type QueryUserSweepstakesArgs = {
  filter?: InputMaybe<Array<InputMaybe<AggregateFilters>>>;
  pageLimit?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  userProfileId: Scalars['ID']['input'];
  xTenantID: Scalars['ID']['input'];
};


export type QueryUserTransactionsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  inDatefromEpoch?: InputMaybe<Scalars['BigInt']['input']>;
  inDatetoEpoch?: InputMaybe<Scalars['BigInt']['input']>;
  inMediatorType?: InputMaybe<Scalars['String']['input']>;
  inOperationType?: InputMaybe<Scalars['String']['input']>;
  inTenantId?: InputMaybe<Scalars['String']['input']>;
  inUserProfileId?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sortField?: InputMaybe<Scalars['String']['input']>;
  sortOrder?: InputMaybe<Scalars['String']['input']>;
};


export type QueryUserWalletsArgs = {
  userProfileId: Scalars['ID']['input'];
  xTenantID: Scalars['ID']['input'];
};


export type QueryUsersSessionLimitsContinuousArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  userProfileId: Scalars['ID']['input'];
  xTenantID: Scalars['ID']['input'];
};


export type QueryUsersSessionLimitsCumulativeArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  userProfileId: Scalars['ID']['input'];
  xTenantID: Scalars['ID']['input'];
};


export type QueryWalletTransactionArgs = {
  transactionId: Scalars['ID']['input'];
  userProfileId: Scalars['ID']['input'];
  xTenantID: Scalars['ID']['input'];
};

export type QuestionnaireStatusUpdateToUserInput = {
  status?: InputMaybe<UserProfileQuestionnaireStatus>;
};

export type QuestionnaireStatusUpdateToUserResponse = {
  __typename?: 'QuestionnaireStatusUpdateToUserResponse';
  data?: Maybe<Array<Maybe<UserProfileQuestionnaire>>>;
  error?: Maybe<Array<QuestionnaireTypesValidationErrors>>;
};

export type QuestionnaireTemplatesCreateInput = {
  additionalData?: InputMaybe<Scalars['JSON']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  questionnaireTypeId?: InputMaybe<Scalars['String']['input']>;
  questions?: InputMaybe<Scalars['JSON']['input']>;
  validFor?: InputMaybe<Scalars['String']['input']>;
};

export type QuestionnaireTemplatesCreateSuccess = {
  __typename?: 'QuestionnaireTemplatesCreateSuccess';
  id: Scalars['ID']['output'];
};

export type QuestionnaireTemplatesListResponse = {
  __typename?: 'QuestionnaireTemplatesListResponse';
  count?: Maybe<Scalars['Float']['output']>;
  data?: Maybe<Array<Maybe<QuestionnaireTemplatesValues>>>;
};

export type QuestionnaireTemplatesResponse = {
  __typename?: 'QuestionnaireTemplatesResponse';
  data?: Maybe<QuestionnaireTemplatesCreateSuccess>;
  error?: Maybe<Array<QuestionnaireTypesValidationErrors>>;
};

export type QuestionnaireTemplatesUpdateByIdInput = {
  additionalData?: InputMaybe<Scalars['String']['input']>;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  createdOn?: InputMaybe<Scalars['String']['input']>;
  creator?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isAssigned?: InputMaybe<Scalars['Boolean']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  lastUpdatedBy?: InputMaybe<Scalars['String']['input']>;
  lastUpdatedOn?: InputMaybe<Scalars['String']['input']>;
  lastUpdater?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  parentId?: InputMaybe<Scalars['String']['input']>;
  questionnaireTypeId?: InputMaybe<Scalars['String']['input']>;
  questionnaireTypeName?: InputMaybe<Scalars['String']['input']>;
  questions?: InputMaybe<Scalars['JSON']['input']>;
  validFor?: InputMaybe<Scalars['String']['input']>;
  version?: InputMaybe<Scalars['Int']['input']>;
};

export type QuestionnaireTemplatesUpdateInput = {
  additionalData?: InputMaybe<Scalars['JSON']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  questions?: InputMaybe<Scalars['JSON']['input']>;
  validFor?: InputMaybe<Scalars['String']['input']>;
};

export type QuestionnaireTemplatesValues = {
  __typename?: 'QuestionnaireTemplatesValues';
  additionalData?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  createdOn?: Maybe<Scalars['String']['output']>;
  creator?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isAssigned?: Maybe<Scalars['Boolean']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  lastUpdatedBy?: Maybe<Scalars['String']['output']>;
  lastUpdatedOn?: Maybe<Scalars['String']['output']>;
  lastUpdater?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  parentId?: Maybe<Scalars['String']['output']>;
  questionnaireTypeId?: Maybe<Scalars['String']['output']>;
  questionnaireTypeName?: Maybe<Scalars['String']['output']>;
  questions?: Maybe<Array<Maybe<Questions>>>;
  validFor?: Maybe<Scalars['String']['output']>;
  version?: Maybe<Scalars['Int']['output']>;
};

export type QuestionnaireTypesCreateInput = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type QuestionnaireTypesCreateSuccess = {
  __typename?: 'QuestionnaireTypesCreateSuccess';
  id: Scalars['ID']['output'];
};

export type QuestionnaireTypesListResponse = {
  __typename?: 'QuestionnaireTypesListResponse';
  count?: Maybe<Scalars['Float']['output']>;
  data?: Maybe<Array<Maybe<QuestionnaireTypesValues>>>;
};

export type QuestionnaireTypesResponse = {
  __typename?: 'QuestionnaireTypesResponse';
  data?: Maybe<QuestionnaireTypesCreateSuccess>;
  error?: Maybe<Array<QuestionnaireTypesValidationErrors>>;
};

export type QuestionnaireTypesUpdateInput = {
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type QuestionnaireTypesUpdateResponse = {
  __typename?: 'QuestionnaireTypesUpdateResponse';
  data?: Maybe<Array<Maybe<QuestionnaireTypesValues>>>;
  error?: Maybe<Array<QuestionnaireTypesValidationErrors>>;
};

export type QuestionnaireTypesValidationErrors = FieldError | GenericError;

export type QuestionnaireTypesValues = {
  __typename?: 'QuestionnaireTypesValues';
  createdBy?: Maybe<Scalars['String']['output']>;
  createdOn?: Maybe<Scalars['String']['output']>;
  creator?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  lastUpdatedBy?: Maybe<Scalars['String']['output']>;
  lastUpdatedOn?: Maybe<Scalars['String']['output']>;
  lastUpdater?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type Questions = {
  __typename?: 'Questions';
  answerChoices?: Maybe<Array<Maybe<AnswerChoices>>>;
  isOptional?: Maybe<Scalars['Boolean']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type QuestionsAndAnswers = {
  __typename?: 'QuestionsAndAnswers';
  answers?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  isOptional?: Maybe<Scalars['Boolean']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type RealityCheckConfigResponse = {
  __typename?: 'RealityCheckConfigResponse';
  data?: Maybe<RealityCheckConfigSuccess>;
};

export type RealityCheckConfigSuccess = {
  __typename?: 'RealityCheckConfigSuccess';
  createdBy?: Maybe<Scalars['String']['output']>;
  createdOn?: Maybe<Scalars['String']['output']>;
  creator?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  interval?: Maybe<Scalars['String']['output']>;
  lastUpdatedBy?: Maybe<Scalars['String']['output']>;
  lastUpdatedOn?: Maybe<Scalars['String']['output']>;
  lastUpdater?: Maybe<Scalars['String']['output']>;
  mediatorType?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  userProfileId: Scalars['ID']['output'];
};

export type RealityCheckConfigsResponse = {
  __typename?: 'RealityCheckConfigsResponse';
  data?: Maybe<Array<RealityCheckConfigSuccess>>;
};

export type RealityCheckContinueResponse = {
  __typename?: 'RealityCheckContinueResponse';
  data?: Maybe<RealityCheckContinueSuccess>;
  error?: Maybe<Array<RealityCheckValidationErrors>>;
};

export type RealityCheckContinueSuccess = {
  __typename?: 'RealityCheckContinueSuccess';
  /** TODO[JB]: Check with Riddler the API as it is not indicate the 204 response. */
  id: Scalars['ID']['output'];
};

export type RealityCheckResponse = {
  __typename?: 'RealityCheckResponse';
  data?: Maybe<RealityCheckSuccess>;
};

export type RealityCheckSetIntervalBodyRequest = {
  interval?: InputMaybe<Scalars['String']['input']>;
  mediatorType?: InputMaybe<Scalars['String']['input']>;
};

export type RealityCheckSetIntervalResponse = {
  __typename?: 'RealityCheckSetIntervalResponse';
  data: Scalars['ID']['output'];
  error?: Maybe<Array<RealityCheckValidationErrors>>;
};

export enum RealityCheckStatus {
  Active = 'Active',
  Ended = 'Ended',
  New = 'New',
  PendingAction = 'PendingAction',
  Stopped = 'Stopped'
}

export type RealityCheckStopResponse = {
  __typename?: 'RealityCheckStopResponse';
  data?: Maybe<RealityCheckStopSuccess>;
  error?: Maybe<Array<RealityCheckValidationErrors>>;
};

export type RealityCheckStopSuccess = {
  __typename?: 'RealityCheckStopSuccess';
  id: Scalars['ID']['output'];
};

export type RealityCheckSuccess = {
  __typename?: 'RealityCheckSuccess';
  elapsedCount?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  interval?: Maybe<Scalars['String']['output']>;
  mediatorType?: Maybe<Scalars['String']['output']>;
  sessionContextIds?: Maybe<Array<Scalars['ID']['output']>>;
  status?: Maybe<RealityCheckStatus>;
  tenantId: Scalars['ID']['output'];
  type?: Maybe<RealityCheckType>;
  userProfileId: Scalars['ID']['output'];
};

export enum RealityCheckType {
  MultiSessionContext = 'MultiSessionContext',
  PerSessionContext = 'PerSessionContext'
}

export type RealityCheckUpdate = {
  __typename?: 'RealityCheckUpdate';
  current?: Maybe<Endeavour_Dot_RealityCheck_Dot_Messaging_Dot_Contracts_Dot_RealityCheck_Dot_Events_Dot_RealityCheckUpdatedRealityCheck>;
  id?: Maybe<Scalars['String']['output']>;
  mediatorType?: Maybe<Scalars['String']['output']>;
  previous?: Maybe<Endeavour_Dot_RealityCheck_Dot_Messaging_Dot_Contracts_Dot_RealityCheck_Dot_Events_Dot_RealityCheckUpdatedRealityCheck2>;
  type?: Maybe<Scalars['String']['output']>;
};

export type RealityCheckValidationErrors = FieldError | GenericError;

export type RegisterErrors = FieldError | GenericError;

export type RegisterResponse = {
  __typename?: 'RegisterResponse';
  /** Legacy true/false value as to whether registration succeeded or not */
  data: Scalars['Boolean']['output'];
  error?: Maybe<Array<RegisterErrors>>;
  /** Further registration information such as the newly created userProfileId */
  registrationData?: Maybe<RegistrationResponse>;
};

export type RegistrationResponse = {
  __typename?: 'RegistrationResponse';
  userProfileId?: Maybe<Scalars['String']['output']>;
};

export type RemoveIdentityRoleFromIdentityUserResponse = {
  __typename?: 'RemoveIdentityRoleFromIdentityUserResponse';
  data?: Maybe<Scalars['JSON']['output']>;
  error?: Maybe<Array<IdentityValidationErrors>>;
};

export type RemoveRelatedIdentityRoleFromRoleResponse = {
  __typename?: 'RemoveRelatedIdentityRoleFromRoleResponse';
  data?: Maybe<Scalars['JSON']['output']>;
  error?: Maybe<Array<IdentityValidationErrors>>;
};

export type ReportHierarchyItem = {
  __typename?: 'ReportHierarchyItem';
  menuId: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  parentMenuId?: Maybe<Scalars['String']['output']>;
  reportId?: Maybe<Scalars['String']['output']>;
};

export type ReportParameter = {
  __typename?: 'ReportParameter';
  allowedValues?: Maybe<Array<Scalars['String']['output']>>;
  defaultText: Scalars['String']['output'];
  description: Scalars['String']['output'];
  groupType: ReportParameterGroupType;
  label: Scalars['String']['output'];
  optional: Scalars['Boolean']['output'];
  param: Scalars['String']['output'];
  paramType: Scalars['String']['output'];
  range?: Maybe<ReportParameterRange>;
};

export enum ReportParameterGroupType {
  Datepicker = 'DATEPICKER',
  Dropdown = 'DROPDOWN',
  NumberField = 'NUMBER_FIELD',
  Submit = 'SUBMIT',
  TextField = 'TEXT_FIELD'
}

export type ReportParameterRange = {
  __typename?: 'ReportParameterRange';
  max?: Maybe<Scalars['String']['output']>;
  min?: Maybe<Scalars['String']['output']>;
};

export enum RestrictionStatus {
  Active = 'Active',
  Deleted = 'Deleted',
  Expired = 'Expired',
  PendingActivation = 'PendingActivation'
}

export type ReviewMetadata = {
  __typename?: 'ReviewMetadata';
  author?: Maybe<AuthorMetadata>;
  name?: Maybe<Scalars['String']['output']>;
  rating?: Maybe<ReviewRating>;
  reviewBody?: Maybe<Scalars['String']['output']>;
};

export type ReviewRating = {
  __typename?: 'ReviewRating';
  bestValue?: Maybe<Scalars['String']['output']>;
  /** Rating values are represented by strings in JSON-LD schema */
  ratingValue?: Maybe<Scalars['String']['output']>;
  worstRating?: Maybe<Scalars['String']['output']>;
};

export enum ReviewStatusType {
  Completed = 'Completed',
  Declined = 'Declined'
}

export enum ScoreStatus {
  High = 'High',
  Low = 'Low',
  Medium = 'Medium',
  NotSet = 'NotSet',
  Unknown = 'Unknown'
}

export type Scores = {
  __typename?: 'Scores';
  additionalData?: Maybe<AdditionalDataScore>;
  createdOn?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  externalResource?: Maybe<Scalars['String']['output']>;
  externalResourceId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  mediatorId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<ScoreStatus>;
  type?: Maybe<Scalars['String']['output']>;
  userProfileId?: Maybe<Scalars['String']['output']>;
};

export type SessionContextConfiguration = {
  __typename?: 'SessionContextConfiguration';
  gaming?: Maybe<GamingConfiguration>;
  login?: Maybe<LoginConfiguration>;
};

export type SessionContextResponse = {
  __typename?: 'SessionContextResponse';
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  createdOn?: Maybe<Scalars['String']['output']>;
  currencyCode?: Maybe<Scalars['String']['output']>;
  deviceBrowser?: Maybe<Scalars['String']['output']>;
  deviceBrowserVersion?: Maybe<Scalars['String']['output']>;
  deviceEngine?: Maybe<Scalars['String']['output']>;
  deviceEngineVersion?: Maybe<Scalars['String']['output']>;
  deviceMobile?: Maybe<Scalars['String']['output']>;
  deviceOs?: Maybe<Scalars['String']['output']>;
  expiresOn?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  ipAddress?: Maybe<Scalars['String']['output']>;
  jurisdictionCode?: Maybe<Scalars['String']['output']>;
  loginSessionId?: Maybe<Scalars['String']['output']>;
  mediatorId?: Maybe<Scalars['String']['output']>;
  mediatorType?: Maybe<Scalars['String']['output']>;
  refreshesOn?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type SessionContextUpdate = {
  __typename?: 'SessionContextUpdate';
  current?: Maybe<Endeavour_Dot_SessionContext_Dot_Messaging_Dot_Contracts_Dot_SessionContexts_Dot_Events_Dot_SessionContextUpdatedSessionContext>;
  id?: Maybe<Scalars['String']['output']>;
  mediatorId?: Maybe<Scalars['String']['output']>;
  mediatorType?: Maybe<Scalars['String']['output']>;
  previous?: Maybe<Endeavour_Dot_SessionContext_Dot_Messaging_Dot_Contracts_Dot_SessionContexts_Dot_Events_Dot_SessionContextUpdatedSessionContext2>;
  type?: Maybe<Scalars['String']['output']>;
};

export type SessionLimitsContinuousCreateErrors = FieldError | GenericError;

export type SessionLimitsContinuousCreateInput = {
  createdBy?: InputMaybe<Scalars['String']['input']>;
  duration: Scalars['String']['input'];
  reason?: InputMaybe<Scalars['String']['input']>;
  sessionContextType: Scalars['String']['input'];
  triggerCooldown: Scalars['String']['input'];
};

export type SessionLimitsContinuousCreateResponse = {
  __typename?: 'SessionLimitsContinuousCreateResponse';
  data?: Maybe<CreateLimitContinuousSuccess>;
  error?: Maybe<Array<SessionLimitsContinuousCreateErrors>>;
};

export type SessionLimitsContinuousResponse = {
  __typename?: 'SessionLimitsContinuousResponse';
  activationStatus?: Maybe<ActivationStatus>;
  changeCooldown?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  createdOn?: Maybe<Scalars['String']['output']>;
  creator?: Maybe<Scalars['String']['output']>;
  duration?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isCancellable?: Maybe<Scalars['Boolean']['output']>;
  isPendingCancellation?: Maybe<Scalars['Boolean']['output']>;
  lastUpdatedOn?: Maybe<Scalars['String']['output']>;
  pendingChangeExpectedOn?: Maybe<Scalars['String']['output']>;
  sessionContextType?: Maybe<Scalars['String']['output']>;
  triggerCooldown?: Maybe<Scalars['String']['output']>;
  userProfileId?: Maybe<Scalars['ID']['output']>;
};

export type SessionLimitsCumulativeCreateErrors = FieldError | GenericError;

export type SessionLimitsCumulativeCreateInput = {
  createdBy?: InputMaybe<Scalars['String']['input']>;
  duration: Scalars['String']['input'];
  period: Scalars['String']['input'];
  reason?: InputMaybe<Scalars['String']['input']>;
  sessionContextType: Scalars['String']['input'];
};

export type SessionLimitsCumulativeCreateResponse = {
  __typename?: 'SessionLimitsCumulativeCreateResponse';
  data?: Maybe<SessionLimitsCumulativeCreateSuccess>;
  error?: Maybe<Array<SessionLimitsCumulativeCreateErrors>>;
};

export type SessionLimitsCumulativeCreateSuccess = {
  __typename?: 'SessionLimitsCumulativeCreateSuccess';
  id: Scalars['ID']['output'];
};

export type SessionLimitsCumulativeResponse = {
  __typename?: 'SessionLimitsCumulativeResponse';
  activationStatus?: Maybe<ActivationStatus>;
  changeCooldown?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  createdOn?: Maybe<Scalars['String']['output']>;
  creator?: Maybe<Scalars['String']['output']>;
  duration?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isCancellable?: Maybe<Scalars['Boolean']['output']>;
  isPendingCancellation?: Maybe<Scalars['Boolean']['output']>;
  lastUpdatedOn?: Maybe<Scalars['String']['output']>;
  pendingChangeExpectedOn?: Maybe<Scalars['String']['output']>;
  period?: Maybe<Scalars['String']['output']>;
  sessionContextType: Scalars['String']['output'];
  userProfileId?: Maybe<Scalars['ID']['output']>;
};

export type SessionLimitsReview = {
  confirmed?: InputMaybe<Scalars['Boolean']['input']>;
  sessionContextType?: InputMaybe<Scalars['String']['input']>;
};

export enum Side {
  Back = 'BACK',
  Front = 'FRONT',
  Notset = 'NOTSET'
}

export type SideBarItemLogic = {
  __typename?: 'SideBarItemLogic';
  iconDark?: Maybe<Scalars['String']['output']>;
  iconLight?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type SportsConfigValidationErrors = FieldError | GenericError;

export type SportsGameConfigCreateInput = {
  currencyCode?: InputMaybe<Scalars['String']['input']>;
  gameExternalAlternateId?: InputMaybe<Scalars['String']['input']>;
  gameExternalId?: InputMaybe<Scalars['String']['input']>;
  gameVariantExternalId?: InputMaybe<Scalars['String']['input']>;
  jurisdictionCategory?: InputMaybe<Scalars['String']['input']>;
  maxBetAmount?: InputMaybe<Scalars['Float']['input']>;
  minBetAmount?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  providerConfigId?: InputMaybe<Scalars['String']['input']>;
  reportingCategory?: InputMaybe<Scalars['String']['input']>;
  studio?: InputMaybe<Scalars['String']['input']>;
  theoreticalRTP?: InputMaybe<Scalars['Float']['input']>;
};

export type SportsIntegrationConfig = {
  __typename?: 'SportsIntegrationConfig';
  createdBy?: Maybe<Scalars['String']['output']>;
  createdOn?: Maybe<Scalars['String']['output']>;
  creator?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  integrationId?: Maybe<Scalars['String']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  jurisdictionCodes: Array<Maybe<Scalars['String']['output']>>;
  lastUpdatedBy?: Maybe<Scalars['String']['output']>;
  lastUpdatedOn?: Maybe<Scalars['String']['output']>;
  lastUpdater?: Maybe<Scalars['String']['output']>;
  mediatorId?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type SportsIntegrationConfigCreateInput = {
  integrationId?: InputMaybe<Scalars['String']['input']>;
  jurisdictionCodes: Array<InputMaybe<Scalars['String']['input']>>;
  mediatorId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type SportsIntegrationConfigCreateResponse = {
  __typename?: 'SportsIntegrationConfigCreateResponse';
  data?: Maybe<SportsIntegrationConfigCreateSuccess>;
  error?: Maybe<Array<SportsConfigValidationErrors>>;
};

export type SportsIntegrationConfigCreateSuccess = {
  __typename?: 'SportsIntegrationConfigCreateSuccess';
  id: Scalars['ID']['output'];
};

export type SportsIntegrationConfigUpdateInput = {
  integrationId?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  jurisdictionCodes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type SportsIntegrationConfigUpdateResponse = {
  __typename?: 'SportsIntegrationConfigUpdateResponse';
  data?: Maybe<SportsIntegrationConfig>;
  error?: Maybe<Array<SportsConfigValidationErrors>>;
};

export type SportsLaunchRequest = {
  currencyCode: Scalars['String']['input'];
  isDemoPlay: Scalars['Boolean']['input'];
  languageCode: Scalars['String']['input'];
  userProfileId?: InputMaybe<Scalars['String']['input']>;
};

export type SportsLaunchResponse = {
  __typename?: 'SportsLaunchResponse';
  countryCode: Scalars['String']['output'];
  currencyCode: Scalars['String']['output'];
  languageCode: Scalars['String']['output'];
  sessionContextId?: Maybe<Scalars['String']['output']>;
  url: Scalars['String']['output'];
};

export type SportsPagedIntegrationConfig = {
  __typename?: 'SportsPagedIntegrationConfig';
  count?: Maybe<Scalars['Float']['output']>;
  data?: Maybe<Array<Maybe<SportsIntegrationConfig>>>;
};

export type SportsPagedProviderConfig = {
  __typename?: 'SportsPagedProviderConfig';
  count?: Maybe<Scalars['Float']['output']>;
  data?: Maybe<Array<Maybe<SportsProviderConfig>>>;
};

export type SportsProviderConfig = {
  __typename?: 'SportsProviderConfig';
  bonusContributionRatio?: Maybe<Scalars['Float']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  createdOn?: Maybe<Scalars['String']['output']>;
  creator?: Maybe<Scalars['String']['output']>;
  currencyCode?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  integrationConfig?: Maybe<SportsIntegrationConfig>;
  integrationConfigId?: Maybe<Scalars['String']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  lastUpdatedBy?: Maybe<Scalars['String']['output']>;
  lastUpdatedOn?: Maybe<Scalars['String']['output']>;
  lastUpdater?: Maybe<Scalars['String']['output']>;
  launchContext?: Maybe<Scalars['String']['output']>;
  maxBetAmount?: Maybe<Scalars['Float']['output']>;
  mediatorId?: Maybe<Scalars['String']['output']>;
  minBetAmount?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};


export type SportsProviderConfigIntegrationConfigArgs = {
  xTenantID: Scalars['ID']['input'];
};

export type SportsProviderConfigCreateInput = {
  bonusContributionRatio?: InputMaybe<Scalars['Float']['input']>;
  currencyCode?: InputMaybe<Scalars['String']['input']>;
  integrationConfigId?: InputMaybe<Scalars['String']['input']>;
  launchContext?: InputMaybe<Scalars['String']['input']>;
  maxBetAmount?: InputMaybe<Scalars['Float']['input']>;
  minBetAmount?: InputMaybe<Scalars['Float']['input']>;
};

export type SportsProviderConfigCreateResponse = {
  __typename?: 'SportsProviderConfigCreateResponse';
  data?: Maybe<SportsProviderConfigCreateSuccess>;
  error?: Maybe<Array<SportsConfigValidationErrors>>;
};

export type SportsProviderConfigCreateSuccess = {
  __typename?: 'SportsProviderConfigCreateSuccess';
  id: Scalars['ID']['output'];
};

export type SportsProviderConfigUpdateInput = {
  bonusContributionRatio?: InputMaybe<Scalars['Float']['input']>;
  currencyCode?: InputMaybe<Scalars['String']['input']>;
  integrationConfigId?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  launchContext?: InputMaybe<Scalars['String']['input']>;
  maxBetAmount?: InputMaybe<Scalars['Float']['input']>;
  minBetAmount?: InputMaybe<Scalars['Float']['input']>;
};

export type SportsProviderConfigUpdateResponse = {
  __typename?: 'SportsProviderConfigUpdateResponse';
  data?: Maybe<SportsProviderConfig>;
  error?: Maybe<Array<SportsConfigValidationErrors>>;
};

/** A filter to be used against String fields. All fields are combined with a logical ‘and.’ */
export type StringFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['String']['input']>;
  /** Not equal to the specified value, treating null like an ordinary value (case-insensitive). */
  distinctFromInsensitive?: InputMaybe<Scalars['String']['input']>;
  /** Ends with the specified string (case-sensitive). */
  endsWith?: InputMaybe<Scalars['String']['input']>;
  /** Ends with the specified string (case-insensitive). */
  endsWithInsensitive?: InputMaybe<Scalars['String']['input']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['String']['input']>;
  /** Equal to the specified value (case-insensitive). */
  equalToInsensitive?: InputMaybe<Scalars['String']['input']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['String']['input']>;
  /** Greater than the specified value (case-insensitive). */
  greaterThanInsensitive?: InputMaybe<Scalars['String']['input']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['String']['input']>;
  /** Greater than or equal to the specified value (case-insensitive). */
  greaterThanOrEqualToInsensitive?: InputMaybe<Scalars['String']['input']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Included in the specified list (case-insensitive). */
  inInsensitive?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Contains the specified string (case-sensitive). */
  includes?: InputMaybe<Scalars['String']['input']>;
  /** Contains the specified string (case-insensitive). */
  includesInsensitive?: InputMaybe<Scalars['String']['input']>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['String']['input']>;
  /** Less than the specified value (case-insensitive). */
  lessThanInsensitive?: InputMaybe<Scalars['String']['input']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['String']['input']>;
  /** Less than or equal to the specified value (case-insensitive). */
  lessThanOrEqualToInsensitive?: InputMaybe<Scalars['String']['input']>;
  /** Matches the specified pattern (case-sensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  like?: InputMaybe<Scalars['String']['input']>;
  /** Matches the specified pattern (case-insensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  likeInsensitive?: InputMaybe<Scalars['String']['input']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['String']['input']>;
  /** Equal to the specified value, treating null like an ordinary value (case-insensitive). */
  notDistinctFromInsensitive?: InputMaybe<Scalars['String']['input']>;
  /** Does not end with the specified string (case-sensitive). */
  notEndsWith?: InputMaybe<Scalars['String']['input']>;
  /** Does not end with the specified string (case-insensitive). */
  notEndsWithInsensitive?: InputMaybe<Scalars['String']['input']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['String']['input']>;
  /** Not equal to the specified value (case-insensitive). */
  notEqualToInsensitive?: InputMaybe<Scalars['String']['input']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Not included in the specified list (case-insensitive). */
  notInInsensitive?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Does not contain the specified string (case-sensitive). */
  notIncludes?: InputMaybe<Scalars['String']['input']>;
  /** Does not contain the specified string (case-insensitive). */
  notIncludesInsensitive?: InputMaybe<Scalars['String']['input']>;
  /** Does not match the specified pattern (case-sensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  notLike?: InputMaybe<Scalars['String']['input']>;
  /** Does not match the specified pattern (case-insensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  notLikeInsensitive?: InputMaybe<Scalars['String']['input']>;
  /** Does not start with the specified string (case-sensitive). */
  notStartsWith?: InputMaybe<Scalars['String']['input']>;
  /** Does not start with the specified string (case-insensitive). */
  notStartsWithInsensitive?: InputMaybe<Scalars['String']['input']>;
  /** Starts with the specified string (case-sensitive). */
  startsWith?: InputMaybe<Scalars['String']['input']>;
  /** Starts with the specified string (case-insensitive). */
  startsWithInsensitive?: InputMaybe<Scalars['String']['input']>;
};

export type SubmitUserAnswersToAssignedQuestionnaireCreateSuccess = {
  __typename?: 'SubmitUserAnswersToAssignedQuestionnaireCreateSuccess';
  id: Scalars['ID']['output'];
};

export type SubmitUserAnswersToAssignedQuestionnaireUserInput = {
  questionsAndAnswers?: InputMaybe<Scalars['JSON']['input']>;
};

export type SubmitUserAnswersToAssignedQuestionnaireUserResponse = {
  __typename?: 'SubmitUserAnswersToAssignedQuestionnaireUserResponse';
  data?: Maybe<SubmitUserAnswersToAssignedQuestionnaireCreateSuccess>;
  error?: Maybe<Array<QuestionnaireTypesValidationErrors>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type Subscription = {
  __typename?: 'Subscription';
  activityFeed?: Maybe<FeedEvent>;
  /** Reads and enables pagination through a set of `FeedType`. (live) */
  allFeedTypes?: Maybe<FeedTypesConnection>;
  /** Reads a set of `FeedType`. (live) */
  allFeedTypesList?: Maybe<Array<FeedType>>;
  /** Reads and enables pagination through a set of `UserActivity`. (live) */
  allUserActivities?: Maybe<UserActivitiesConnection>;
  /** Reads a set of `UserActivity`. (live) */
  allUserActivitiesList?: Maybe<Array<UserActivity>>;
  bonusUserProfileBonusesUpdated?: Maybe<BonusUserProfileBonusesUpdate>;
  documentsUpdated?: Maybe<DocumentsUpdated>;
  limitsUpdated?: Maybe<LimitsUpdate>;
  maxLimitLimitsUpdated?: Maybe<LimitsMaxWageringUpdate>;
  /** Fetches an object given its globally unique `ID`. (live) */
  node?: Maybe<Node>;
  /** The root query type must be a `Node` to work well with Relay 1 mutations. This just resolves to `query`. (live) */
  nodeId: Scalars['ID']['output'];
  objectTagUpdated?: Maybe<ObjectTagUpdated>;
  /**
   * Exposes the root query type nested one level down. This is helpful for Relay 1
   * which can only query top level fields if they are in a particular form. (live)
   */
  query: Query;
  realityCheckUpdated?: Maybe<RealityCheckUpdate>;
  sessionContextUpdated?: Maybe<SessionContextUpdate>;
  sessionLimitsContinuousUpdated?: Maybe<LimitsSessionContinuousUpdate>;
  sessionLimitsCumulativeUpdated?: Maybe<LimitsSessionCumulativeUpdate>;
  suspensionLimitsUpdated?: Maybe<LimitsSuspensionUpdate>;
  /** Reads a single `UserActivity` using its globally unique `ID`. (live) */
  userActivity?: Maybe<UserActivity>;
  /**  (live) */
  userActivityById?: Maybe<UserActivity>;
  userDataVerificationsUpdated?: Maybe<UserDataVerificationsUpdated>;
  userFreeBetsUpdated?: Maybe<UserFreeBetsUpdated>;
  userFreeSpinsUpdated?: Maybe<UserFreeSpinsUpdated>;
  userNotesEditUpdated?: Maybe<UserNotesEditUpdated>;
  userNotesUpdated?: Maybe<UserNotesUpdated>;
  userProfileUpdated?: Maybe<UserProfileUpdate>;
  userRestrictionsUpdated?: Maybe<UserRestrictionsUpdated>;
  userSweepstakesUpdated?: Maybe<UserSweepstakesUpdated>;
  walletUpdated?: Maybe<WalletUpdated>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionActivityFeedArgs = {
  feedType?: InputMaybe<Array<Scalars['String']['input']>>;
  tenantId: Scalars['String']['input'];
  userProfileId?: InputMaybe<Scalars['ID']['input']>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllFeedTypesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<FeedTypeCondition>;
  filter?: InputMaybe<FeedTypeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedTypesOrderBy>>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllFeedTypesListArgs = {
  condition?: InputMaybe<FeedTypeCondition>;
  filter?: InputMaybe<FeedTypeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedTypesOrderBy>>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllUserActivitiesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<UserActivityCondition>;
  filter?: InputMaybe<UserActivityFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UserActivitiesOrderBy>>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllUserActivitiesListArgs = {
  condition?: InputMaybe<UserActivityCondition>;
  filter?: InputMaybe<UserActivityFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UserActivitiesOrderBy>>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionBonusUserProfileBonusesUpdatedArgs = {
  userProfileId: Scalars['String']['input'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionDocumentsUpdatedArgs = {
  tenantId: Scalars['ID']['input'];
  userProfileId: Scalars['ID']['input'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionLimitsUpdatedArgs = {
  userProfileId: Scalars['String']['input'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionMaxLimitLimitsUpdatedArgs = {
  userProfileId: Scalars['String']['input'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionNodeArgs = {
  nodeId: Scalars['ID']['input'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionObjectTagUpdatedArgs = {
  objectId: Scalars['String']['input'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionRealityCheckUpdatedArgs = {
  currentStatus?: InputMaybe<Array<Scalars['String']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  previousStatus?: InputMaybe<Array<Scalars['String']['input']>>;
  type?: InputMaybe<Scalars['String']['input']>;
  userProfileId: Scalars['String']['input'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionSessionContextUpdatedArgs = {
  currentStatus?: InputMaybe<Array<Scalars['String']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  previousStatus?: InputMaybe<Array<Scalars['String']['input']>>;
  type?: InputMaybe<Scalars['String']['input']>;
  userProfileId: Scalars['String']['input'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionSessionLimitsContinuousUpdatedArgs = {
  userProfileId: Scalars['String']['input'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionSessionLimitsCumulativeUpdatedArgs = {
  userProfileId: Scalars['String']['input'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionSuspensionLimitsUpdatedArgs = {
  userProfileId: Scalars['String']['input'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionUserActivityArgs = {
  nodeId: Scalars['ID']['input'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionUserActivityByIdArgs = {
  id: Scalars['String']['input'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionUserDataVerificationsUpdatedArgs = {
  tenantId: Scalars['ID']['input'];
  userProfileId: Scalars['ID']['input'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionUserFreeBetsUpdatedArgs = {
  tenantId: Scalars['ID']['input'];
  userProfileId: Scalars['ID']['input'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionUserFreeSpinsUpdatedArgs = {
  tenantId: Scalars['ID']['input'];
  userProfileId: Scalars['ID']['input'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionUserNotesEditUpdatedArgs = {
  tenantId: Scalars['ID']['input'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionUserNotesUpdatedArgs = {
  externalResource: Scalars['String']['input'];
  externalResourceId: Scalars['ID']['input'];
  tenantId: Scalars['ID']['input'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionUserProfileUpdatedArgs = {
  id: Scalars['String']['input'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionUserRestrictionsUpdatedArgs = {
  tenantId: Scalars['ID']['input'];
  userProfileId: Scalars['ID']['input'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionUserSweepstakesUpdatedArgs = {
  tenantId: Scalars['ID']['input'];
  userProfileId: Scalars['ID']['input'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionWalletUpdatedArgs = {
  userProfileId: Scalars['String']['input'];
};

export type SuspensionLimitsCreateInput = {
  createdBy?: InputMaybe<Scalars['String']['input']>;
  duration?: InputMaybe<Scalars['String']['input']>;
  intent: SuspensionLimitsType;
  reason?: InputMaybe<Scalars['String']['input']>;
};

export type SuspensionLimitsCreateResponse = {
  __typename?: 'SuspensionLimitsCreateResponse';
  data?: Maybe<SuspensionLimitsCreateSuccess>;
  error?: Maybe<Array<SuspensionLimitsValidationErrors>>;
};

export type SuspensionLimitsCreateSuccess = {
  __typename?: 'SuspensionLimitsCreateSuccess';
  id: Scalars['ID']['output'];
};

export type SuspensionLimitsResponse = {
  __typename?: 'SuspensionLimitsResponse';
  activationStatus?: Maybe<ActivationStatus>;
  cancellableAfter?: Maybe<Scalars['String']['output']>;
  changeCooldown?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  createdOn?: Maybe<Scalars['String']['output']>;
  creator?: Maybe<Scalars['String']['output']>;
  duration?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  intent?: Maybe<Scalars['String']['output']>;
  isCancellable?: Maybe<Scalars['Boolean']['output']>;
  isPendingCancellation?: Maybe<Scalars['Boolean']['output']>;
  lastUpdatedOn?: Maybe<Scalars['String']['output']>;
  pendingChangeExpectedOn?: Maybe<Scalars['String']['output']>;
  userProfileId?: Maybe<Scalars['ID']['output']>;
};

export enum SuspensionLimitsType {
  Selfexclusion = 'SELFEXCLUSION',
  Timeout = 'TIMEOUT'
}

export type SuspensionLimitsValidationErrors = FieldError | GenericError;

export type SweepstakeTemplate = {
  __typename?: 'SweepstakeTemplate';
  additionalProperties?: Maybe<Scalars['JSON']['output']>;
  claimableFor?: Maybe<Scalars['String']['output']>;
  claimedFor?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  createdOn?: Maybe<Scalars['String']['output']>;
  creator?: Maybe<Scalars['String']['output']>;
  currencyCode?: Maybe<Scalars['String']['output']>;
  granting?: Maybe<Granting>;
  grantingPromotional?: Maybe<GrantingPromotional>;
  id?: Maybe<Scalars['String']['output']>;
  inProgressFor?: Maybe<Scalars['String']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isClaimable?: Maybe<Scalars['Boolean']['output']>;
  isUsed?: Maybe<Scalars['Boolean']['output']>;
  lastUpdatedBy?: Maybe<Scalars['String']['output']>;
  lastUpdatedOn?: Maybe<Scalars['String']['output']>;
  lastUpdater?: Maybe<Scalars['String']['output']>;
  mediatorType?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type SweepstakeTemplateCreateInput = {
  additionalProperties?: InputMaybe<Scalars['JSON']['input']>;
  claimableFor?: InputMaybe<Scalars['String']['input']>;
  claimedFor: Scalars['String']['input'];
  currencyCode: Scalars['String']['input'];
  granting: GrantingInput;
  grantingPromotional?: InputMaybe<GrantingPromotionalInput>;
  inProgressFor: Scalars['String']['input'];
  isClaimable: Scalars['Boolean']['input'];
  mediatorType?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type SweepstakeTemplateCreateResponse = {
  __typename?: 'SweepstakeTemplateCreateResponse';
  data?: Maybe<SweepstakeTemplateCreateSuccess>;
};

export type SweepstakeTemplateCreateSuccess = {
  __typename?: 'SweepstakeTemplateCreateSuccess';
  id: Scalars['ID']['output'];
};

export type SweepstakeTemplateUpdateInput = {
  additionalProperties?: InputMaybe<Scalars['JSON']['input']>;
  claimableFor?: InputMaybe<Scalars['String']['input']>;
  claimedFor: Scalars['String']['input'];
  currencyCode: Scalars['String']['input'];
  granting: GrantingInput;
  grantingPromotional?: InputMaybe<GrantingPromotionalInput>;
  inProgressFor: Scalars['String']['input'];
  isActive: Scalars['Boolean']['input'];
  isClaimable: Scalars['Boolean']['input'];
  mediatorType?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type SweepstakeTemplateUpdateResponse = {
  __typename?: 'SweepstakeTemplateUpdateResponse';
  data?: Maybe<SweepstakeTemplate>;
};

export type SweepstakeTemplatesListResponse = {
  __typename?: 'SweepstakeTemplatesListResponse';
  count?: Maybe<Scalars['Int']['output']>;
  data: Array<Maybe<SweepstakeTemplate>>;
};

export type TagCategory = {
  __typename?: 'TagCategory';
  createdBy?: Maybe<Scalars['String']['output']>;
  createdOn?: Maybe<Scalars['String']['output']>;
  creator?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  displayCls?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  lastUpdatedBy?: Maybe<Scalars['String']['output']>;
  lastUpdatedOn?: Maybe<Scalars['String']['output']>;
  lastUpdater?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type TagCategoryCreateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  displayCls?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type TagCategoryUpdateResponse = {
  __typename?: 'TagCategoryUpdateResponse';
  data?: Maybe<TagCategory>;
  error?: Maybe<Array<TagsValidationErrors>>;
};

export type TagCreateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  tagCategoryId?: InputMaybe<Scalars['String']['input']>;
  valueRegex?: InputMaybe<Scalars['String']['input']>;
};

export type TagObjectCreateInput = {
  externalResource?: InputMaybe<Scalars['String']['input']>;
  externalResourceId?: InputMaybe<Scalars['String']['input']>;
  tagId?: InputMaybe<Scalars['ID']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type TagUpdateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  tagCategoryId?: InputMaybe<Scalars['String']['input']>;
  valueRegex?: InputMaybe<Scalars['String']['input']>;
};

export type TagUpdateResponse = {
  __typename?: 'TagUpdateResponse';
  data?: Maybe<Tags>;
  error?: Maybe<Array<TagsValidationErrors>>;
};

export type Tags = {
  __typename?: 'Tags';
  createdBy?: Maybe<Scalars['String']['output']>;
  createdOn?: Maybe<Scalars['String']['output']>;
  creator?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  lastUpdatedBy?: Maybe<Scalars['String']['output']>;
  lastUpdatedOn?: Maybe<Scalars['String']['output']>;
  lastUpdater?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  tagCategory?: Maybe<TagCategory>;
  valueRegex?: Maybe<Scalars['String']['output']>;
};

export type TagsObject = {
  __typename?: 'TagsObject';
  createdBy?: Maybe<Scalars['String']['output']>;
  createdOn?: Maybe<Scalars['String']['output']>;
  creator?: Maybe<Scalars['String']['output']>;
  externalResource?: Maybe<Scalars['String']['output']>;
  externalResourceId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  lastUpdatedBy?: Maybe<Scalars['String']['output']>;
  lastUpdatedOn?: Maybe<Scalars['String']['output']>;
  lastUpdater?: Maybe<Scalars['String']['output']>;
  tag?: Maybe<Tags>;
  value?: Maybe<Scalars['String']['output']>;
};

export type TagsValidationErrors = FieldError | GenericError;

export type TaskCreateForBackOfficeUserIdInput = {
  dueOn?: InputMaybe<Scalars['String']['input']>;
  index?: InputMaybe<Scalars['Int']['input']>;
  objectTasks?: InputMaybe<Array<InputMaybe<ObjectTask>>>;
  priority?: InputMaybe<TaskPriority>;
  status?: InputMaybe<TaskStatus>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type TaskCreateForBackOfficeUserIdResponse = {
  __typename?: 'TaskCreateForBackOfficeUserIdResponse';
  data?: Maybe<TaskCreateForBackOfficeUserIdSuccess>;
  error?: Maybe<Array<TaskValidationErrors>>;
};

export type TaskCreateForBackOfficeUserIdSuccess = {
  __typename?: 'TaskCreateForBackOfficeUserIdSuccess';
  id: Scalars['ID']['output'];
};

export type TaskCreateInput = {
  dueOn?: InputMaybe<Scalars['String']['input']>;
  index?: InputMaybe<Scalars['Int']['input']>;
  objectTasks?: InputMaybe<Array<InputMaybe<ObjectTask>>>;
  priority?: InputMaybe<TaskPriority>;
  status?: InputMaybe<TaskStatus>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type TaskCreateResponse = {
  __typename?: 'TaskCreateResponse';
  data?: Maybe<TaskCreateSuccess>;
  error?: Maybe<Array<TaskValidationErrors>>;
};

export type TaskCreateSuccess = {
  __typename?: 'TaskCreateSuccess';
  id: Scalars['ID']['output'];
};

export type TaskDeleteResponse = {
  __typename?: 'TaskDeleteResponse';
  data?: Maybe<TaskTemplate>;
  error?: Maybe<Array<TaskValidationErrors>>;
};

export enum TaskPriority {
  Critical = 'Critical',
  High = 'High',
  Low = 'Low',
  Medium = 'Medium',
  NotSet = 'NotSet'
}

export enum TaskStatus {
  Assigned = 'Assigned',
  Done = 'Done',
  Flagged = 'Flagged',
  InProgress = 'InProgress',
  New = 'New'
}

export type TaskTemplate = {
  __typename?: 'TaskTemplate';
  createdBy?: Maybe<Scalars['String']['output']>;
  createdOn?: Maybe<Scalars['String']['output']>;
  creator?: Maybe<Scalars['String']['output']>;
  dueOn?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  index?: Maybe<Scalars['Int']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  lastUpdatedBy?: Maybe<Scalars['String']['output']>;
  lastUpdatedOn?: Maybe<Scalars['String']['output']>;
  lastUpdater?: Maybe<Scalars['String']['output']>;
  objectTasks?: Maybe<Array<Maybe<ObjectTaskType>>>;
  priority?: Maybe<PriorityTypes>;
  status?: Maybe<StatusTypes>;
  type?: Maybe<Scalars['String']['output']>;
};

export type TaskTemplateResponse = {
  __typename?: 'TaskTemplateResponse';
  data?: Maybe<TaskTemplate>;
  error?: Maybe<Array<TaskValidationErrors>>;
};

export type TaskUpdateForBackOfficeUserIdResponse = {
  __typename?: 'TaskUpdateForBackOfficeUserIdResponse';
  data?: Maybe<TaskTemplate>;
  error?: Maybe<Array<TaskValidationErrors>>;
};

export type TaskUpdateInput = {
  createdBy?: InputMaybe<Scalars['String']['input']>;
  createdOn?: InputMaybe<Scalars['String']['input']>;
  creator?: InputMaybe<Scalars['String']['input']>;
  dueOn?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  index?: InputMaybe<Scalars['Int']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  lastUpdatedBy?: InputMaybe<Scalars['String']['input']>;
  lastUpdatedOn?: InputMaybe<Scalars['String']['input']>;
  lastUpdater?: InputMaybe<Scalars['String']['input']>;
  objectTasks?: InputMaybe<Scalars['String']['input']>;
  priority?: InputMaybe<PriorityTypes>;
  status?: InputMaybe<StatusTypes>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type TaskUpdateResponse = {
  __typename?: 'TaskUpdateResponse';
  data?: Maybe<TaskTemplate>;
  error?: Maybe<Array<TaskValidationErrors>>;
};

export type TaskValidationErrors = FieldError | GenericError;

export type TelephoneNumber = {
  __typename?: 'TelephoneNumber';
  number?: Maybe<Scalars['String']['output']>;
  prefix?: Maybe<Scalars['String']['output']>;
};

export type TelephoneNumberInput = {
  number?: InputMaybe<Scalars['String']['input']>;
  prefix?: InputMaybe<Scalars['String']['input']>;
};

export type TenantConfigBo = {
  __typename?: 'TenantConfigBo';
  currencies?: Maybe<Array<Maybe<TenantCurrency>>>;
  jurisdictions?: Maybe<Array<Maybe<TenantJurisdiction>>>;
  knownLanguages?: Maybe<Array<Maybe<KnownLanguage>>>;
  name?: Maybe<Scalars['String']['output']>;
  sideBarItemLogic?: Maybe<SideBarItemLogic>;
  tenantId?: Maybe<Scalars['String']['output']>;
};

export type TenantConfigBoError = {
  __typename?: 'TenantConfigBoError';
  message?: Maybe<Scalars['String']['output']>;
};

export type TenantConfiguration = {
  __typename?: 'TenantConfiguration';
  jurisdictionCode?: Maybe<Scalars['String']['output']>;
  limits?: Maybe<LimitsConfiguration>;
  products?: Maybe<Array<Scalars['String']['output']>>;
  sessionContext?: Maybe<SessionContextConfiguration>;
  verification?: Maybe<VerificationConfiguration>;
};

export type TenantCurrency = {
  __typename?: 'TenantCurrency';
  code: Scalars['String']['output'];
};

export type TenantJurisdiction = {
  __typename?: 'TenantJurisdiction';
  code: Scalars['String']['output'];
};

export type TenantListBo = {
  __typename?: 'TenantListBo';
  avatar?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  tenantId?: Maybe<Scalars['String']['output']>;
};

export type Thumbnail = {
  __typename?: 'Thumbnail';
  /** Contains any extraneous properties defined on the content model */
  additionalProperties?: Maybe<Scalars['JSON']['output']>;
  asset?: Maybe<Scalars['String']['output']>;
  background?: Maybe<Scalars['String']['output']>;
  filters: Filters;
  id?: Maybe<Scalars['ID']['output']>;
  layout?: Maybe<Layout>;
  logo?: Maybe<Scalars['String']['output']>;
  sort?: Maybe<Scalars['Int']['output']>;
};

export type Transaction = {
  __typename?: 'Transaction';
  additionalProperties?: Maybe<Scalars['JSON']['output']>;
  amount?: Maybe<Scalars['Float']['output']>;
  balanceAmount?: Maybe<Scalars['Float']['output']>;
  beforeBalanceAmount?: Maybe<Scalars['Float']['output']>;
  currencyCode: Scalars['String']['output'];
  errorCode?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  mediatorId?: Maybe<Scalars['String']['output']>;
  mediatorType?: Maybe<Scalars['String']['output']>;
  operationType: TransactionOperationType;
  reason?: Maybe<Scalars['String']['output']>;
  referenceId?: Maybe<Scalars['String']['output']>;
  sessionContextId?: Maybe<Scalars['String']['output']>;
  status: TransactionStatus;
  transactionBatchId?: Maybe<Scalars['String']['output']>;
  walletId: Scalars['String']['output'];
  walletType?: Maybe<Scalars['String']['output']>;
};

export type TransactionLimitsCreateErrors = FieldError | GenericError;

export type TransactionLimitsCreateResponse = {
  __typename?: 'TransactionLimitsCreateResponse';
  data?: Maybe<TransactionLimitsCreateSuccess>;
  error?: Maybe<Array<TransactionLimitsCreateErrors>>;
};

export type TransactionLimitsCreateSuccess = {
  __typename?: 'TransactionLimitsCreateSuccess';
  id: Scalars['ID']['output'];
};

export enum TransactionOperationType {
  Credit = 'Credit',
  Debit = 'Debit'
}

/** Information about pagination in a connection. */
export type TransactionPageInfo = {
  __typename?: 'TransactionPageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['Cursor']['output']>;
  /**
   * When paginating forwards, are there more items?
   * If totalCount is equal to maxCount then this value will be truthy
   * regardless of whether there are more items or not.
   */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['Cursor']['output']>;
};

export enum TransactionStatus {
  Authorized = 'Authorized',
  Canceled = 'Canceled',
  Completed = 'Completed',
  Declined = 'Declined',
  Failed = 'Failed',
  New = 'New',
  PendingAuthorization = 'PendingAuthorization',
  PendingReversal = 'PendingReversal',
  PendingValidation = 'PendingValidation',
  Rejected = 'Rejected',
  Reversed = 'Reversed',
  Submitted = 'Submitted'
}

export type Translations = {
  __typename?: 'Translations';
  content: Scalars['String']['output'];
  key: Scalars['String']['output'];
};

export type TranslationsBo = {
  __typename?: 'TranslationsBo';
  content: Scalars['String']['output'];
  key: Scalars['String']['output'];
};

/** A filter to be used against UUID fields. All fields are combined with a logical ‘and.’ */
export type UuidFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['UUID']['input']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['UUID']['input']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['UUID']['input']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['UUID']['input']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['UUID']['input']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['UUID']['input']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['UUID']['input']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['UUID']['input']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type UpdateIdentityUserResponse = {
  __typename?: 'UpdateIdentityUserResponse';
  data?: Maybe<Array<Maybe<AdministrationIdentityRolesByIdentityUsersConfig>>>;
  error?: Maybe<Array<IdentityValidationErrors>>;
};

export type UpdateIdentityUsersInput = {
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateUserEmailAndUsernameInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

/** A connection to a list of `UserActivity` values. */
export type UserActivitiesConnection = {
  __typename?: 'UserActivitiesConnection';
  /** A list of edges which contains the `UserActivity` and cursor to aid in pagination. */
  edges: Array<UserActivitiesEdge>;
  /** A list of `UserActivity` objects. */
  nodes: Array<Maybe<UserActivity>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `UserActivity` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `UserActivity` edge in the connection. */
export type UserActivitiesEdge = {
  __typename?: 'UserActivitiesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `UserActivity` at the end of the edge. */
  node?: Maybe<UserActivity>;
};

/** Methods to use when ordering `UserActivity`. */
export enum UserActivitiesOrderBy {
  DisplayCategoryAsc = 'DISPLAY_CATEGORY_ASC',
  DisplayCategoryDesc = 'DISPLAY_CATEGORY_DESC',
  FeedTypeAsc = 'FEED_TYPE_ASC',
  FeedTypeDesc = 'FEED_TYPE_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IsPerformanceTestAsc = 'IS_PERFORMANCE_TEST_ASC',
  IsPerformanceTestDesc = 'IS_PERFORMANCE_TEST_DESC',
  IsTestAsc = 'IS_TEST_ASC',
  IsTestDesc = 'IS_TEST_DESC',
  LastUpdatedOnAsc = 'LAST_UPDATED_ON_ASC',
  LastUpdatedOnDesc = 'LAST_UPDATED_ON_DESC',
  MetaDataAsc = 'META_DATA_ASC',
  MetaDataDesc = 'META_DATA_DESC',
  Natural = 'NATURAL',
  PayloadAsc = 'PAYLOAD_ASC',
  PayloadDesc = 'PAYLOAD_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  TenantIdAsc = 'TENANT_ID_ASC',
  TenantIdDesc = 'TENANT_ID_DESC',
  TimestampAsc = 'TIMESTAMP_ASC',
  TimestampDesc = 'TIMESTAMP_DESC',
  UserProfileIdAsc = 'USER_PROFILE_ID_ASC',
  UserProfileIdDesc = 'USER_PROFILE_ID_DESC'
}

export type UserActivity = Node & {
  __typename?: 'UserActivity';
  /** @deprecated No longer used */
  displayCategory?: Maybe<Scalars['String']['output']>;
  feedType: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isPerformanceTest: Scalars['Boolean']['output'];
  isTest: Scalars['Boolean']['output'];
  lastUpdatedOn?: Maybe<Scalars['Datetime']['output']>;
  metaData: Scalars['JSON']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  payload: Scalars['JSON']['output'];
  tenantId: Scalars['String']['output'];
  timestamp?: Maybe<Scalars['String']['output']>;
  userProfileId: Scalars['UUID']['output'];
};

/**
 * A condition to be used against `UserActivity` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type UserActivityCondition = {
  /** Checks for equality with the object’s `displayCategory` field. */
  displayCategory?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `feedType` field. */
  feedType?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `isPerformanceTest` field. */
  isPerformanceTest?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `isTest` field. */
  isTest?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `lastUpdatedOn` field. */
  lastUpdatedOn?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `metaData` field. */
  metaData?: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `payload` field. */
  payload?: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `tenantId` field. */
  tenantId?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `timestamp` field. */
  timestamp?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `userProfileId` field. */
  userProfileId?: InputMaybe<Scalars['UUID']['input']>;
};

/** A filter to be used against `UserActivity` object types. All fields are combined with a logical ‘and.’ */
export type UserActivityFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<UserActivityFilter>>;
  /** Filter by the object’s `displayCategory` field. */
  displayCategory?: InputMaybe<StringFilter>;
  /** Filter by the object’s `feedType` field. */
  feedType?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<StringFilter>;
  /** Filter by the object’s `isPerformanceTest` field. */
  isPerformanceTest?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isTest` field. */
  isTest?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `lastUpdatedOn` field. */
  lastUpdatedOn?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `metaData` field. */
  metaData?: InputMaybe<JsonFilter>;
  /** Negates the expression. */
  not?: InputMaybe<UserActivityFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<UserActivityFilter>>;
  /** Filter by the object’s `payload` field. */
  payload?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `tenantId` field. */
  tenantId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `timestamp` field. */
  timestamp?: InputMaybe<StringFilter>;
  /** Filter by the object’s `userProfileId` field. */
  userProfileId?: InputMaybe<UuidFilter>;
};

export type UserDataVerifications = {
  __typename?: 'UserDataVerifications';
  additionalData?: Maybe<Scalars['JSON']['output']>;
  breakdown?: Maybe<Scalars['JSON']['output']>;
  createdOn?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  externalResource?: Maybe<Scalars['String']['output']>;
  externalResourceId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  intent?: Maybe<Scalars['String']['output']>;
  mediatorId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<UserDataVerificationsStatus>;
  type?: Maybe<Scalars['String']['output']>;
  userProfileId?: Maybe<Scalars['String']['output']>;
};

export enum UserDataVerificationsStatus {
  Failed = 'Failed',
  NotMatched = 'NotMatched',
  NotSet = 'NotSet',
  Pending = 'Pending',
  Verified = 'Verified',
  VerifiedPartial = 'VerifiedPartial'
}

export type UserInputError = {
  __typename?: 'UserInputError';
  code?: Maybe<Scalars['String']['output']>;
  key?: Maybe<Scalars['String']['output']>;
};

export type UserNotesEditUpdated = {
  __typename?: 'UserNotesEditUpdated';
  after?: Maybe<Endeavour_Notes_Notes_Dot_Value2>;
  before?: Maybe<Endeavour_Notes_Notes_Dot_Value>;
  op?: Maybe<Scalars['String']['output']>;
  source?: Maybe<Io_Dot_Debezium_Dot_Connector_Dot_Postgresql_Dot_Source>;
  transaction?: Maybe<Endeavour_Notes_Notes_Slash__Slash_Properties_Slash_Transaction_Slash_OneOf_Slash_1>;
  tsMs?: Maybe<Scalars['BigInt']['output']>;
};

export type UserNotesUpdated = {
  __typename?: 'UserNotesUpdated';
  after?: Maybe<Endeavour_Notes_Object_Notes_Dot_Value2>;
  before?: Maybe<Endeavour_Notes_Object_Notes_Dot_Value>;
  op?: Maybe<Scalars['String']['output']>;
  source?: Maybe<Io_Dot_Debezium_Dot_Connector_Dot_Postgresql_Dot_Source>;
  transaction?: Maybe<Endeavour_Notes_Object_Notes_Slash__Slash_Properties_Slash_Transaction_Slash_OneOf_Slash_1>;
  tsMs?: Maybe<Scalars['BigInt']['output']>;
};

/** Link tags to profile */
export type UserProfile = {
  __typename?: 'UserProfile';
  accountStatus?: Maybe<Scalars['String']['output']>;
  compliance?: Maybe<Compliance>;
  consents?: Maybe<Consents>;
  createdOn?: Maybe<Scalars['String']['output']>;
  externalIds?: Maybe<Scalars['JSON']['output']>;
  identityProviderExternalId?: Maybe<Scalars['String']['output']>;
  isTestUser?: Maybe<Scalars['Boolean']['output']>;
  jurisdictionCode?: Maybe<Scalars['String']['output']>;
  kpIs?: Maybe<Kpis>;
  lastLogins?: Maybe<Array<Maybe<LastLogin>>>;
  lastUpdatedOn?: Maybe<Scalars['String']['output']>;
  marketing?: Maybe<Marketing>;
  optedInCampaignIdentifiers?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  riskLevel?: Maybe<Scalars['Int']['output']>;
  tags: Array<ObjectTagsObjectTagResponse>;
  user?: Maybe<Person>;
  username?: Maybe<Scalars['String']['output']>;
};


/** Link tags to profile */
export type UserProfileTagsArgs = {
  profileId: Scalars['ID']['input'];
  xTenantID: Scalars['String']['input'];
};

export type UserProfileBonus = {
  __typename?: 'UserProfileBonus';
  balanceAmount?: Maybe<Scalars['Float']['output']>;
  bonusTemplateId?: Maybe<Scalars['String']['output']>;
  consumeTypeStrategy?: Maybe<ConsumeTypeStrategy>;
  consumingZeroAmount?: Maybe<Scalars['Float']['output']>;
  content: BonusContent;
  conversionMaxAmount?: Maybe<Scalars['Float']['output']>;
  conversionMinAmount?: Maybe<Scalars['Float']['output']>;
  convertedAmount?: Maybe<Scalars['Float']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  createdOn?: Maybe<Scalars['String']['output']>;
  creator?: Maybe<Scalars['String']['output']>;
  currencyCode?: Maybe<Scalars['String']['output']>;
  expiresOn?: Maybe<Scalars['String']['output']>;
  gameConfigFilterType?: Maybe<ConfigFilterType>;
  gameConfigIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  grantedAmount?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isClaimable?: Maybe<Scalars['Boolean']['output']>;
  lastUpdatedBy?: Maybe<Scalars['String']['output']>;
  lastUpdatedOn?: Maybe<Scalars['String']['output']>;
  lastUpdater?: Maybe<Scalars['String']['output']>;
  lockedAmount?: Maybe<Scalars['Float']['output']>;
  mediatorType?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  providerConfigFilterType?: Maybe<ConfigFilterType>;
  providerConfigIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  status?: Maybe<UserProfileBonusStatus>;
  userProfileId?: Maybe<Scalars['String']['output']>;
  wageredAmount?: Maybe<Scalars['Float']['output']>;
  wageringContributionStrategy?: Maybe<WageringContributionStrategy>;
  wageringRequirementAmount?: Maybe<Scalars['Float']['output']>;
  walletId?: Maybe<Scalars['String']['output']>;
};


export type UserProfileBonusContentArgs = {
  locale: Scalars['String']['input'];
  skinId: Scalars['String']['input'];
  xTenantID: Scalars['ID']['input'];
};

export enum UserProfileBonusStatus {
  Assigned = 'Assigned',
  Available = 'Available',
  Cancelled = 'Cancelled',
  Claimed = 'Claimed',
  Consumed = 'Consumed',
  Converted = 'Converted',
  Expired = 'Expired',
  Forfeited = 'Forfeited',
  Fulfilled = 'Fulfilled',
  InProgress = 'InProgress',
  Rejected = 'Rejected'
}

export type UserProfileFreeBet = {
  __typename?: 'UserProfileFreeBet';
  balanceAmount?: Maybe<Scalars['Float']['output']>;
  bonusTemplateId?: Maybe<Scalars['String']['output']>;
  consumedGrantedAmount?: Maybe<Scalars['Float']['output']>;
  content: FreeBetContent;
  conversionMaxAmount?: Maybe<Scalars['Float']['output']>;
  convertedAmount?: Maybe<Scalars['Float']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  createdOn?: Maybe<Scalars['String']['output']>;
  creator?: Maybe<Scalars['String']['output']>;
  currencyCode?: Maybe<Scalars['String']['output']>;
  expiresOn?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  externalProperties?: Maybe<Scalars['JSON']['output']>;
  freeBetTemplateId?: Maybe<Scalars['String']['output']>;
  grantedAmount?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  lastUpdatedBy?: Maybe<Scalars['String']['output']>;
  lastUpdatedOn?: Maybe<Scalars['String']['output']>;
  lastUpdater?: Maybe<Scalars['String']['output']>;
  mediatorId?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  status?: Maybe<UserProfileFreeBetStatus>;
  userProfileId?: Maybe<Scalars['String']['output']>;
  walletId?: Maybe<Scalars['String']['output']>;
};


export type UserProfileFreeBetContentArgs = {
  locale: Scalars['String']['input'];
  skinId: Scalars['String']['input'];
  xTenantID: Scalars['ID']['input'];
};

export enum UserProfileFreeBetStatus {
  Assigned = 'Assigned',
  Cancelled = 'Cancelled',
  Consumed = 'Consumed',
  Converted = 'Converted',
  Expired = 'Expired',
  Fulfilled = 'Fulfilled',
  InProgress = 'InProgress'
}

export type UserProfileFreeSpin = {
  __typename?: 'UserProfileFreeSpin';
  balanceAmount?: Maybe<Scalars['Float']['output']>;
  bonusTemplateId?: Maybe<Scalars['String']['output']>;
  consumedGrantedAmount?: Maybe<Scalars['Float']['output']>;
  content: FreeSpinContent;
  conversionMaxAmount?: Maybe<Scalars['Float']['output']>;
  convertedAmount?: Maybe<Scalars['Float']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  createdOn?: Maybe<Scalars['String']['output']>;
  creator?: Maybe<Scalars['String']['output']>;
  currencyCode?: Maybe<Scalars['String']['output']>;
  expiresOn?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  externalProperties?: Maybe<Scalars['JSON']['output']>;
  freeSpinsTemplateId?: Maybe<Scalars['String']['output']>;
  gameConfigId?: Maybe<Scalars['String']['output']>;
  grantedAmount?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  lastUpdatedBy?: Maybe<Scalars['String']['output']>;
  lastUpdatedOn?: Maybe<Scalars['String']['output']>;
  lastUpdater?: Maybe<Scalars['String']['output']>;
  mediatorId?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  status?: Maybe<UserProfileFreeSpinStatus>;
  userProfileId?: Maybe<Scalars['String']['output']>;
  walletId?: Maybe<Scalars['String']['output']>;
};


export type UserProfileFreeSpinContentArgs = {
  locale: Scalars['String']['input'];
  skinId: Scalars['String']['input'];
  xTenantID: Scalars['ID']['input'];
};

export enum UserProfileFreeSpinStatus {
  Assigned = 'Assigned',
  Cancelled = 'Cancelled',
  Consumed = 'Consumed',
  Converted = 'Converted',
  Expired = 'Expired',
  Fulfilled = 'Fulfilled',
  InProgress = 'InProgress'
}

export type UserProfileQuestionnaire = {
  __typename?: 'UserProfileQuestionnaire';
  createdBy?: Maybe<Scalars['String']['output']>;
  createdOn?: Maybe<Scalars['String']['output']>;
  creator?: Maybe<Scalars['String']['output']>;
  externalResource?: Maybe<Scalars['String']['output']>;
  externalResourceId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  lastUpdatedBy?: Maybe<Scalars['String']['output']>;
  lastUpdatedOn?: Maybe<Scalars['String']['output']>;
  lastUpdater?: Maybe<Scalars['String']['output']>;
  mediatorId?: Maybe<Scalars['String']['output']>;
  questionnaireTemplateId?: Maybe<Scalars['String']['output']>;
  questionnaireTemplateName?: Maybe<Scalars['String']['output']>;
  questionnaireTypeName?: Maybe<Scalars['String']['output']>;
  questionsAndAnswers?: Maybe<Array<Maybe<QuestionsAndAnswers>>>;
  status?: Maybe<UserProfileQuestionnaireStatus>;
  userProfileId?: Maybe<Scalars['String']['output']>;
  validFor?: Maybe<Scalars['String']['output']>;
  version?: Maybe<Scalars['String']['output']>;
};

export enum UserProfileQuestionnaireStatus {
  Expired = 'Expired',
  Rejected = 'Rejected',
  Requested = 'Requested',
  Submitted = 'Submitted',
  Verified = 'Verified'
}

export type UserProfileUpdate = {
  __typename?: 'UserProfileUpdate';
  current?: Maybe<Endeavour_Dot_UserProfile_Dot_Messaging_Dot_Contracts_Dot_Events_Dot_UserProfile>;
  id?: Maybe<Scalars['String']['output']>;
};

export type UserProfileUpdateErrors = FieldError | GenericError;

export type UserProfileUpdateResponse = {
  __typename?: 'UserProfileUpdateResponse';
  data?: Maybe<UserProfile>;
  error?: Maybe<Array<UserProfileUpdateErrors>>;
};

export type UserProfilesAddressInput = {
  addressCountry?: InputMaybe<Scalars['String']['input']>;
  addressLocality?: InputMaybe<Scalars['String']['input']>;
  addressRegion?: InputMaybe<Scalars['String']['input']>;
  postOfficeBoxNumber?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  streetAddress?: InputMaybe<Scalars['String']['input']>;
};

export enum UserProfilesEnumsAcceptedStatus {
  Accepted = 'Accepted',
  NotSet = 'NotSet',
  Pending = 'Pending',
  Rejected = 'Rejected'
}

export enum UserProfilesEnumsAccountStatus {
  Active = 'Active',
  Blocked = 'Blocked',
  Deactivated = 'Deactivated',
  Deregistered = 'Deregistered',
  Dormant = 'Dormant',
  Inactive = 'Inactive',
  New = 'New',
  SelfExcluded = 'SelfExcluded',
  Suspended = 'Suspended'
}

export enum UserProfilesEnumsOptOutStatus {
  Accepted = 'Accepted',
  NotSet = 'NotSet',
  OptedOut = 'OptedOut',
  Pending = 'Pending'
}

export enum UserProfilesEnumsRiskScoresStatus {
  High = 'High',
  Low = 'Low',
  Medium = 'Medium',
  NotSet = 'NotSet',
  Unknown = 'Unknown'
}

export enum UserProfilesEnumsVerificationStatus {
  Failed = 'Failed',
  NotMatched = 'NotMatched',
  NotSet = 'NotSet',
  Pending = 'Pending',
  Verified = 'Verified',
  VerifiedPartial = 'VerifiedPartial'
}

export type UserProfilesPersonInput = {
  additionalName?: InputMaybe<Scalars['String']['input']>;
  address?: InputMaybe<UserProfilesAddressInput>;
  birthDate?: InputMaybe<Scalars['String']['input']>;
  birthPlace?: InputMaybe<UserProfilesAddressInput>;
  deathDate?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  familyName?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  givenName?: InputMaybe<Scalars['String']['input']>;
  honorificPrefix?: InputMaybe<Scalars['String']['input']>;
  honorificSuffix?: InputMaybe<Scalars['String']['input']>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  knowsLanguage?: InputMaybe<Scalars['String']['input']>;
  mobileNumber?: InputMaybe<TelephoneNumberInput>;
  nationality?: InputMaybe<Scalars['String']['input']>;
  permanentAddress?: InputMaybe<UserProfilesAddressInput>;
  spouseId?: InputMaybe<Scalars['String']['input']>;
  taxId?: InputMaybe<Scalars['String']['input']>;
  telephoneNumber?: InputMaybe<TelephoneNumberInput>;
  vatId?: InputMaybe<Scalars['String']['input']>;
};

export type UserProfilesUpdateInput = {
  accountStatus?: InputMaybe<Scalars['String']['input']>;
  adverseMediaVerificationStatus?: InputMaybe<Scalars['String']['input']>;
  ageVerificationStatus?: InputMaybe<Scalars['String']['input']>;
  basicCookiesAllowed?: InputMaybe<Scalars['Boolean']['input']>;
  cookiePolicyVersionAccepted?: InputMaybe<Scalars['String']['input']>;
  functionalCookiesAllowed?: InputMaybe<Scalars['Boolean']['input']>;
  idVerificationStatus?: InputMaybe<Scalars['String']['input']>;
  identityProviderExternalId?: InputMaybe<Scalars['String']['input']>;
  isCallAllowed?: InputMaybe<Scalars['Boolean']['input']>;
  /** Deprecated fields to avoid breaking changes. */
  isCookiePolicyAccepted?: InputMaybe<Scalars['Boolean']['input']>;
  isDepositAllowed?: InputMaybe<Scalars['Boolean']['input']>;
  isEmailAllowed?: InputMaybe<Scalars['Boolean']['input']>;
  isLoginAllowed?: InputMaybe<Scalars['Boolean']['input']>;
  isMarketingAllowed?: InputMaybe<Scalars['Boolean']['input']>;
  isPostalMailAllowed?: InputMaybe<Scalars['Boolean']['input']>;
  isPrivacyPolicyAccepted?: InputMaybe<Scalars['Boolean']['input']>;
  isPromotionsAllowed?: InputMaybe<Scalars['Boolean']['input']>;
  isPushNotificationAllowed?: InputMaybe<Scalars['Boolean']['input']>;
  isSMSAllowed?: InputMaybe<Scalars['Boolean']['input']>;
  isSecurityPolicyAccepted?: InputMaybe<Scalars['Boolean']['input']>;
  isTermsAndConditionsAccepted?: InputMaybe<Scalars['Boolean']['input']>;
  isWageringAllowed?: InputMaybe<Scalars['Boolean']['input']>;
  isWithdrawAllowed?: InputMaybe<Scalars['Boolean']['input']>;
  marketingAllowedFrom?: InputMaybe<Scalars['String']['input']>;
  marketingAllowedTo?: InputMaybe<Scalars['String']['input']>;
  multipleAccountCheckStatus?: InputMaybe<Scalars['String']['input']>;
  performanceCookiesAllowed?: InputMaybe<Scalars['Boolean']['input']>;
  privacyPolicyVersionAccepted?: InputMaybe<Scalars['String']['input']>;
  riskLevel?: InputMaybe<Scalars['Int']['input']>;
  securityPolicyVersionAccepted?: InputMaybe<Scalars['String']['input']>;
  termsAndConditionsVersionAccepted?: InputMaybe<Scalars['String']['input']>;
  user?: InputMaybe<UserProfilesPersonInput>;
  vatCountryAlpha2Code?: InputMaybe<Scalars['String']['input']>;
};

export type UserRestriction = {
  __typename?: 'UserRestriction';
  createdOn?: Maybe<Scalars['String']['output']>;
  endsOn?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  externalResource?: Maybe<Scalars['String']['output']>;
  externalResourceId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isCancellable?: Maybe<Scalars['Boolean']['output']>;
  lastUpdatedOn?: Maybe<Scalars['String']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
  restrictDeposit?: Maybe<Scalars['Boolean']['output']>;
  restrictLogin?: Maybe<Scalars['Boolean']['output']>;
  restrictMarketing?: Maybe<Scalars['Boolean']['output']>;
  restrictPromotions?: Maybe<Scalars['Boolean']['output']>;
  restrictWagering?: Maybe<Scalars['Boolean']['output']>;
  restrictWithdraw?: Maybe<Scalars['Boolean']['output']>;
  startsOn?: Maybe<Scalars['String']['output']>;
  status?: Maybe<RestrictionStatus>;
};

export type UserRestrictionCreateErrors = FieldError | GenericError;

export type UserRestrictionCreateInput = {
  endsAfter?: InputMaybe<Scalars['String']['input']>;
  endsOn?: InputMaybe<Scalars['String']['input']>;
  externalResource: Scalars['String']['input'];
  externalResourceId: Scalars['String']['input'];
  isCancellable?: InputMaybe<Scalars['Boolean']['input']>;
  reason: Scalars['String']['input'];
  reasonInfo?: InputMaybe<Scalars['String']['input']>;
  restrictDeposit?: InputMaybe<Scalars['Boolean']['input']>;
  restrictLogin?: InputMaybe<Scalars['Boolean']['input']>;
  restrictMarketing?: InputMaybe<Scalars['Boolean']['input']>;
  restrictPromotions?: InputMaybe<Scalars['Boolean']['input']>;
  restrictWagering?: InputMaybe<Scalars['Boolean']['input']>;
  restrictWithdraw?: InputMaybe<Scalars['Boolean']['input']>;
  startsOn?: InputMaybe<Scalars['String']['input']>;
};

export type UserRestrictionCreateResponse = {
  __typename?: 'UserRestrictionCreateResponse';
  data?: Maybe<UserRestrictionCreateSuccess>;
  error?: Maybe<Array<UserRestrictionCreateErrors>>;
};

export type UserRestrictionCreateSuccess = {
  __typename?: 'UserRestrictionCreateSuccess';
  id: Scalars['ID']['output'];
};

export type UserRestrictionsUpdated = {
  __typename?: 'UserRestrictionsUpdated';
  after?: Maybe<Endeavour_Restrictions_Restrictions_Dot_Value2>;
  before?: Maybe<Endeavour_Restrictions_Restrictions_Dot_Value>;
  op?: Maybe<Scalars['String']['output']>;
  source?: Maybe<Io_Dot_Debezium_Dot_Connector_Dot_Postgresql_Dot_Source>;
  transaction?: Maybe<Endeavour_Restrictions_Restrictions_Slash__Slash_Properties_Slash_Transaction_Slash_OneOf_Slash_1>;
  tsMs?: Maybe<Scalars['BigInt']['output']>;
};

export type UserSweepstake = {
  __typename?: 'UserSweepstake';
  additionalProperties?: Maybe<Scalars['JSON']['output']>;
  balanceAmount?: Maybe<Scalars['Float']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  createdOn?: Maybe<Scalars['String']['output']>;
  creator?: Maybe<Scalars['String']['output']>;
  currencyCode?: Maybe<Scalars['String']['output']>;
  expiresOn?: Maybe<Scalars['String']['output']>;
  grantedAmount?: Maybe<Scalars['Float']['output']>;
  grantedPromotionalAmount?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isClaimable?: Maybe<Scalars['Boolean']['output']>;
  lastUpdatedBy?: Maybe<Scalars['String']['output']>;
  lastUpdatedOn?: Maybe<Scalars['String']['output']>;
  lastUpdater?: Maybe<Scalars['String']['output']>;
  mediatorType?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  status?: Maybe<UserSweepstakeStatus>;
  sweepstakesTemplateId?: Maybe<Scalars['ID']['output']>;
  userProfileId?: Maybe<Scalars['ID']['output']>;
  walletId?: Maybe<Scalars['ID']['output']>;
};

export type UserSweepstakeAssignInput = {
  amount: Scalars['Float']['input'];
  sweepstakesTemplateId: Scalars['String']['input'];
};

export type UserSweepstakeAssignResponse = {
  __typename?: 'UserSweepstakeAssignResponse';
  data?: Maybe<UserSweepstakeAssignSuccess>;
};

export type UserSweepstakeAssignSuccess = {
  __typename?: 'UserSweepstakeAssignSuccess';
  id: Scalars['ID']['output'];
};

export type UserSweepstakeCancelResponse = {
  __typename?: 'UserSweepstakeCancelResponse';
  data?: Maybe<UserSweepstakeCancelSuccess>;
};

export type UserSweepstakeCancelSuccess = {
  __typename?: 'UserSweepstakeCancelSuccess';
  id: Scalars['ID']['output'];
};

export type UserSweepstakeClaimResponse = {
  __typename?: 'UserSweepstakeClaimResponse';
  data?: Maybe<UserSweepstakeClaimSuccess>;
};

export type UserSweepstakeClaimSuccess = {
  __typename?: 'UserSweepstakeClaimSuccess';
  id: Scalars['ID']['output'];
};

export type UserSweepstakeForfeitResponse = {
  __typename?: 'UserSweepstakeForfeitResponse';
  data?: Maybe<UserSweepstakeForfeitSuccess>;
};

export type UserSweepstakeForfeitSuccess = {
  __typename?: 'UserSweepstakeForfeitSuccess';
  id: Scalars['ID']['output'];
};

export type UserSweepstakeResponse = {
  __typename?: 'UserSweepstakeResponse';
  count: Scalars['Int']['output'];
  data?: Maybe<Array<UserSweepstake>>;
};

export enum UserSweepstakeStatus {
  Assigned = 'Assigned',
  Available = 'Available',
  Cancelled = 'Cancelled',
  Claimed = 'Claimed',
  Exhausted = 'Exhausted',
  Expired = 'Expired',
  Forfeited = 'Forfeited',
  InProgress = 'InProgress'
}

export type UserTransaction = {
  __typename?: 'UserTransaction';
  additionalProperties?: Maybe<Scalars['JSON']['output']>;
  balanceAmount?: Maybe<Scalars['BigFloat']['output']>;
  createdOn?: Maybe<Scalars['String']['output']>;
  currencyCode?: Maybe<Scalars['String']['output']>;
  geoCity?: Maybe<Scalars['String']['output']>;
  geoCountry?: Maybe<Scalars['String']['output']>;
  geoRegion?: Maybe<Scalars['String']['output']>;
  ipAddress?: Maybe<Scalars['String']['output']>;
  lastUpdatedOn?: Maybe<Scalars['String']['output']>;
  mediatorId?: Maybe<Scalars['String']['output']>;
  mediatorType?: Maybe<Scalars['String']['output']>;
  operationType?: Maybe<Scalars['String']['output']>;
  previousBalanceAmount?: Maybe<Scalars['BigFloat']['output']>;
  sessionContextId?: Maybe<Scalars['String']['output']>;
  tenantId?: Maybe<Scalars['String']['output']>;
  transactionAmount?: Maybe<Scalars['BigFloat']['output']>;
  transactionBatchExternalId?: Maybe<Scalars['String']['output']>;
  transactionBatchId?: Maybe<Scalars['String']['output']>;
  transactionId?: Maybe<Scalars['String']['output']>;
  transactionStatus?: Maybe<Scalars['String']['output']>;
  userProfileId?: Maybe<Scalars['String']['output']>;
  walletId?: Maybe<Scalars['String']['output']>;
  walletType?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of `UserTransaction` values. */
export type UserTransactionsConnection = {
  __typename?: 'UserTransactionsConnection';
  /** A list of edges which contains the `UserTransaction` and cursor to aid in pagination. */
  edges: Array<UserTransactionsEdge>;
  /**
   * The maximum count that `totalCount` can reach before accuracy is compromised.
   *
   * If displaying a paginator UI element, its recommended you use only next/previous page buttons
   * and you display `Showing x of many` for the result count above this max count
   */
  maxCount: Scalars['Int']['output'];
  /** Information to aid in pagination. */
  pageInfo: TransactionPageInfo;
  /**
   * The count of `UserTransaction` you could get from the connection up to the `maxCount`.
   * Results beyond `maxCount` can still be fetched using the `after` cursor however the total count value
   * you receive is the count of results after the cursor.
   */
  totalCount: Scalars['Int']['output'];
};

/** A `UserTransaction` edge in the connection. */
export type UserTransactionsEdge = {
  __typename?: 'UserTransactionsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `UserTransaction` at the end of the edge. */
  node?: Maybe<UserTransaction>;
};

export type UserVerifyErrors = FieldError | GenericError;

export type UserVerifyResponse = {
  __typename?: 'UserVerifyResponse';
  data?: Maybe<Scalars['Boolean']['output']>;
  error?: Maybe<Array<UserVerifyErrors>>;
};

export type UserWallets = {
  __typename?: 'UserWallets';
  aggregates?: Maybe<Scalars['JSON']['output']>;
  balanceAmount?: Maybe<Scalars['Float']['output']>;
  currencyCode?: Maybe<Scalars['String']['output']>;
  expiresOn?: Maybe<Scalars['String']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isLocked?: Maybe<Scalars['Boolean']['output']>;
  jurisdictionCode?: Maybe<Scalars['String']['output']>;
  restrictions?: Maybe<Scalars['JSON']['output']>;
  templateName?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  userProfileId?: Maybe<Scalars['String']['output']>;
  walletId?: Maybe<Scalars['String']['output']>;
};

export type VerificationConfiguration = {
  __typename?: 'VerificationConfiguration';
  categories?: Maybe<Array<Scalars['String']['output']>>;
  documents?: Maybe<Array<DocumentConfiguration>>;
  types?: Maybe<DocumentTypeConfiguration>;
};

export type VerificationProviderLaunchResponse = {
  __typename?: 'VerificationProviderLaunchResponse';
  token?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export enum VerificationStatus {
  Expired = 'Expired',
  Ignored = 'Ignored',
  Pending = 'Pending',
  Rejected = 'Rejected',
  Verified = 'Verified'
}

export type VerifyUserProfileInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type VerticalProductMarketingAllowed = {
  __typename?: 'VerticalProductMarketingAllowed';
  callAllowed?: Maybe<UserProfilesEnumsOptOutStatus>;
  emailAllowed?: Maybe<UserProfilesEnumsOptOutStatus>;
  postalMailAllowed?: Maybe<UserProfilesEnumsOptOutStatus>;
  pushNotificationAllowed?: Maybe<UserProfilesEnumsOptOutStatus>;
  smsAllowed?: Maybe<UserProfilesEnumsOptOutStatus>;
};

export type WageringContributionStrategy = {
  __typename?: 'WageringContributionStrategy';
  type?: Maybe<WageringContributionType>;
  value?: Maybe<Scalars['Float']['output']>;
};

export type WageringContributionStrategyInput = {
  type?: InputMaybe<WageringContributionType>;
  value?: InputMaybe<Scalars['Float']['input']>;
};

export enum WageringContributionType {
  CapAmount = 'CapAmount',
  Ratio = 'Ratio'
}

export type WalletUpdated = {
  __typename?: 'WalletUpdated';
  currencyCode?: Maybe<Scalars['String']['output']>;
  current?: Maybe<WalletUpdatedSnapshot>;
  jurisdictionCode?: Maybe<Scalars['String']['output']>;
  previous?: Maybe<WalletUpdatedSnapshot>;
  transactionId?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  userProfileId?: Maybe<Scalars['String']['output']>;
  walletId?: Maybe<Scalars['String']['output']>;
};

export type WalletUpdatedMarkers = {
  __typename?: 'WalletUpdatedMarkers';
  additionalProperties?: Maybe<Scalars['JSON']['output']>;
};

export type WalletUpdatedRestrictions = {
  __typename?: 'WalletUpdatedRestrictions';
  additionalProperties?: Maybe<Scalars['JSON']['output']>;
};

export type WalletUpdatedSnapshot = {
  __typename?: 'WalletUpdatedSnapshot';
  aggregates?: Maybe<Scalars['JSON']['output']>;
  balanceAmount?: Maybe<Scalars['Float']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isLocked?: Maybe<Scalars['Boolean']['output']>;
  lastUpdatedOn?: Maybe<Scalars['String']['output']>;
  markers?: Maybe<WalletUpdatedMarkers>;
  restrictions?: Maybe<WalletUpdatedRestrictions>;
};

export type Endeavour_Bonuses_User_Profile_Bonuses_Dot_Value = {
  __typename?: 'endeavour_bonuses_user_profile_bonuses_DOT_Value';
  balanceAmount?: Maybe<Scalars['Float']['output']>;
  bonusTemplateId?: Maybe<Scalars['String']['output']>;
  claimableFor?: Maybe<Scalars['String']['output']>;
  claimedFor?: Maybe<Scalars['String']['output']>;
  consumeTypeStrategy?: Maybe<Scalars['String']['output']>;
  consumingZeroAmount?: Maybe<Scalars['Float']['output']>;
  conversionMaxAmount?: Maybe<Scalars['Float']['output']>;
  conversionMinAmount?: Maybe<Scalars['Float']['output']>;
  convertedAmount?: Maybe<Scalars['Float']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  createdOn?: Maybe<Scalars['String']['output']>;
  creator?: Maybe<Scalars['String']['output']>;
  currencyCode?: Maybe<Scalars['String']['output']>;
  expiresOn?: Maybe<Scalars['String']['output']>;
  grantedAmount?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  inProgressFor?: Maybe<Scalars['String']['output']>;
  isClaimable?: Maybe<Scalars['Boolean']['output']>;
  lastUpdatedBy?: Maybe<Scalars['String']['output']>;
  lastUpdatedOn?: Maybe<Scalars['String']['output']>;
  lastUpdater?: Maybe<Scalars['String']['output']>;
  lockedAmount?: Maybe<Scalars['Float']['output']>;
  mediatorId?: Maybe<Scalars['String']['output']>;
  mediatorType?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  tenantId?: Maybe<Scalars['String']['output']>;
  userProfileId?: Maybe<Scalars['String']['output']>;
  wageredAmount?: Maybe<Scalars['Float']['output']>;
  wageringContributionStrategy?: Maybe<Scalars['String']['output']>;
  wageringRequirementAmount?: Maybe<Scalars['Float']['output']>;
  walletId?: Maybe<Scalars['String']['output']>;
};

export type Endeavour_Bonuses_User_Profile_Bonuses_Dot_Value2 = {
  __typename?: 'endeavour_bonuses_user_profile_bonuses_DOT_Value2';
  balanceAmount?: Maybe<Scalars['Float']['output']>;
  bonusTemplateId?: Maybe<Scalars['String']['output']>;
  claimableFor?: Maybe<Scalars['String']['output']>;
  claimedFor?: Maybe<Scalars['String']['output']>;
  consumeTypeStrategy?: Maybe<Scalars['String']['output']>;
  consumingZeroAmount?: Maybe<Scalars['Float']['output']>;
  conversionMaxAmount?: Maybe<Scalars['Float']['output']>;
  conversionMinAmount?: Maybe<Scalars['Float']['output']>;
  convertedAmount?: Maybe<Scalars['Float']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  createdOn?: Maybe<Scalars['String']['output']>;
  creator?: Maybe<Scalars['String']['output']>;
  currencyCode?: Maybe<Scalars['String']['output']>;
  expiresOn?: Maybe<Scalars['String']['output']>;
  grantedAmount?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  inProgressFor?: Maybe<Scalars['String']['output']>;
  isClaimable?: Maybe<Scalars['Boolean']['output']>;
  lastUpdatedBy?: Maybe<Scalars['String']['output']>;
  lastUpdatedOn?: Maybe<Scalars['String']['output']>;
  lastUpdater?: Maybe<Scalars['String']['output']>;
  lockedAmount?: Maybe<Scalars['Float']['output']>;
  mediatorId?: Maybe<Scalars['String']['output']>;
  mediatorType?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  tenantId?: Maybe<Scalars['String']['output']>;
  userProfileId?: Maybe<Scalars['String']['output']>;
  wageredAmount?: Maybe<Scalars['Float']['output']>;
  wageringContributionStrategy?: Maybe<Scalars['String']['output']>;
  wageringRequirementAmount?: Maybe<Scalars['Float']['output']>;
  walletId?: Maybe<Scalars['String']['output']>;
};

export type Endeavour_Free_Bets_User_Profile_Free_Bets_Dot_Value = {
  __typename?: 'endeavour_free_bets_user_profile_free_bets_DOT_Value';
  assignedFor?: Maybe<Scalars['String']['output']>;
  balanceAmount?: Maybe<Scalars['Float']['output']>;
  bonusTemplateId?: Maybe<Scalars['String']['output']>;
  consumedGrantedAmount?: Maybe<Scalars['Float']['output']>;
  conversionMaxAmount?: Maybe<Scalars['Float']['output']>;
  convertedAmount?: Maybe<Scalars['Float']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  createdOn?: Maybe<Scalars['String']['output']>;
  creator?: Maybe<Scalars['String']['output']>;
  currencyCode?: Maybe<Scalars['String']['output']>;
  expiresOn?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  externalProperties?: Maybe<Scalars['String']['output']>;
  freeBetTemplateId?: Maybe<Scalars['String']['output']>;
  grantedAmount?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  lastUpdatedBy?: Maybe<Scalars['String']['output']>;
  lastUpdatedOn?: Maybe<Scalars['String']['output']>;
  lastUpdater?: Maybe<Scalars['String']['output']>;
  mediatorId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  tenantId?: Maybe<Scalars['String']['output']>;
  userProfileId?: Maybe<Scalars['String']['output']>;
  walletId?: Maybe<Scalars['String']['output']>;
};

export type Endeavour_Free_Bets_User_Profile_Free_Bets_Dot_Value2 = {
  __typename?: 'endeavour_free_bets_user_profile_free_bets_DOT_Value2';
  assignedFor?: Maybe<Scalars['String']['output']>;
  balanceAmount?: Maybe<Scalars['Float']['output']>;
  bonusTemplateId?: Maybe<Scalars['String']['output']>;
  consumedGrantedAmount?: Maybe<Scalars['Float']['output']>;
  conversionMaxAmount?: Maybe<Scalars['Float']['output']>;
  convertedAmount?: Maybe<Scalars['Float']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  createdOn?: Maybe<Scalars['String']['output']>;
  creator?: Maybe<Scalars['String']['output']>;
  currencyCode?: Maybe<Scalars['String']['output']>;
  expiresOn?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  externalProperties?: Maybe<Scalars['String']['output']>;
  freeBetTemplateId?: Maybe<Scalars['String']['output']>;
  grantedAmount?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  lastUpdatedBy?: Maybe<Scalars['String']['output']>;
  lastUpdatedOn?: Maybe<Scalars['String']['output']>;
  lastUpdater?: Maybe<Scalars['String']['output']>;
  mediatorId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  tenantId?: Maybe<Scalars['String']['output']>;
  userProfileId?: Maybe<Scalars['String']['output']>;
  walletId?: Maybe<Scalars['String']['output']>;
};

export type Endeavour_Free_Spins_User_Profile_Free_Spins_Dot_Value = {
  __typename?: 'endeavour_free_spins_user_profile_free_spins_DOT_Value';
  assignedFor?: Maybe<Scalars['String']['output']>;
  balanceAmount?: Maybe<Scalars['Float']['output']>;
  betAmount?: Maybe<Scalars['Float']['output']>;
  bonusTemplateId?: Maybe<Scalars['String']['output']>;
  consumedGrantedAmount?: Maybe<Scalars['Float']['output']>;
  conversionMaxAmount?: Maybe<Scalars['Float']['output']>;
  convertedAmount?: Maybe<Scalars['Float']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  createdOn?: Maybe<Scalars['String']['output']>;
  creator?: Maybe<Scalars['String']['output']>;
  currencyCode?: Maybe<Scalars['String']['output']>;
  expiresOn?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  externalProperties?: Maybe<Scalars['String']['output']>;
  freeSpinsTemplateId?: Maybe<Scalars['String']['output']>;
  gameConfigId?: Maybe<Scalars['String']['output']>;
  grantedAmount?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  lastUpdatedBy?: Maybe<Scalars['String']['output']>;
  lastUpdatedOn?: Maybe<Scalars['String']['output']>;
  lastUpdater?: Maybe<Scalars['String']['output']>;
  mediatorId?: Maybe<Scalars['String']['output']>;
  spinsCount?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  tenantId?: Maybe<Scalars['String']['output']>;
  userProfileId?: Maybe<Scalars['String']['output']>;
  walletId?: Maybe<Scalars['String']['output']>;
};

export type Endeavour_Free_Spins_User_Profile_Free_Spins_Dot_Value2 = {
  __typename?: 'endeavour_free_spins_user_profile_free_spins_DOT_Value2';
  assignedFor?: Maybe<Scalars['String']['output']>;
  balanceAmount?: Maybe<Scalars['Float']['output']>;
  betAmount?: Maybe<Scalars['Float']['output']>;
  bonusTemplateId?: Maybe<Scalars['String']['output']>;
  consumedGrantedAmount?: Maybe<Scalars['Float']['output']>;
  conversionMaxAmount?: Maybe<Scalars['Float']['output']>;
  convertedAmount?: Maybe<Scalars['Float']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  createdOn?: Maybe<Scalars['String']['output']>;
  creator?: Maybe<Scalars['String']['output']>;
  currencyCode?: Maybe<Scalars['String']['output']>;
  expiresOn?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  externalProperties?: Maybe<Scalars['String']['output']>;
  freeSpinsTemplateId?: Maybe<Scalars['String']['output']>;
  gameConfigId?: Maybe<Scalars['String']['output']>;
  grantedAmount?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  lastUpdatedBy?: Maybe<Scalars['String']['output']>;
  lastUpdatedOn?: Maybe<Scalars['String']['output']>;
  lastUpdater?: Maybe<Scalars['String']['output']>;
  mediatorId?: Maybe<Scalars['String']['output']>;
  spinsCount?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  tenantId?: Maybe<Scalars['String']['output']>;
  userProfileId?: Maybe<Scalars['String']['output']>;
  walletId?: Maybe<Scalars['String']['output']>;
};

export type Endeavour_Limits_Continuous_Session_Limits_Dot_Value = {
  __typename?: 'endeavour_limits_continuous_session_limits_DOT_Value';
  activationStatus?: Maybe<Scalars['String']['output']>;
  changeCooldown?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  createdOn?: Maybe<Scalars['String']['output']>;
  creator?: Maybe<Scalars['String']['output']>;
  duration?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isCancellable?: Maybe<Scalars['Boolean']['output']>;
  isPendingCancellation?: Maybe<Scalars['Boolean']['output']>;
  lastUpdatedOn?: Maybe<Scalars['String']['output']>;
  pendingChangeExpectedOn?: Maybe<Scalars['String']['output']>;
  sessionContextType?: Maybe<Scalars['String']['output']>;
  tenantId?: Maybe<Scalars['String']['output']>;
  triggerCooldown?: Maybe<Scalars['String']['output']>;
  userProfileId?: Maybe<Scalars['String']['output']>;
};

export type Endeavour_Limits_Continuous_Session_Limits_Dot_Value2 = {
  __typename?: 'endeavour_limits_continuous_session_limits_DOT_Value2';
  activationStatus?: Maybe<Scalars['String']['output']>;
  changeCooldown?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  createdOn?: Maybe<Scalars['String']['output']>;
  creator?: Maybe<Scalars['String']['output']>;
  duration?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isCancellable?: Maybe<Scalars['Boolean']['output']>;
  isPendingCancellation?: Maybe<Scalars['Boolean']['output']>;
  lastUpdatedOn?: Maybe<Scalars['String']['output']>;
  pendingChangeExpectedOn?: Maybe<Scalars['String']['output']>;
  sessionContextType?: Maybe<Scalars['String']['output']>;
  tenantId?: Maybe<Scalars['String']['output']>;
  triggerCooldown?: Maybe<Scalars['String']['output']>;
  userProfileId?: Maybe<Scalars['String']['output']>;
};

export type Endeavour_Limits_Cumulative_Session_Limits_Dot_Value = {
  __typename?: 'endeavour_limits_cumulative_session_limits_DOT_Value';
  activationStatus?: Maybe<Scalars['String']['output']>;
  changeCooldown?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  createdOn?: Maybe<Scalars['String']['output']>;
  creator?: Maybe<Scalars['String']['output']>;
  duration?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isCancellable?: Maybe<Scalars['Boolean']['output']>;
  isPendingCancellation?: Maybe<Scalars['Boolean']['output']>;
  lastUpdatedOn?: Maybe<Scalars['String']['output']>;
  pendingChangeExpectedOn?: Maybe<Scalars['String']['output']>;
  period?: Maybe<Scalars['String']['output']>;
  sessionContextType?: Maybe<Scalars['String']['output']>;
  tenantId?: Maybe<Scalars['String']['output']>;
  userProfileId?: Maybe<Scalars['String']['output']>;
};

export type Endeavour_Limits_Cumulative_Session_Limits_Dot_Value2 = {
  __typename?: 'endeavour_limits_cumulative_session_limits_DOT_Value2';
  activationStatus?: Maybe<Scalars['String']['output']>;
  changeCooldown?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  createdOn?: Maybe<Scalars['String']['output']>;
  creator?: Maybe<Scalars['String']['output']>;
  duration?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isCancellable?: Maybe<Scalars['Boolean']['output']>;
  isPendingCancellation?: Maybe<Scalars['Boolean']['output']>;
  lastUpdatedOn?: Maybe<Scalars['String']['output']>;
  pendingChangeExpectedOn?: Maybe<Scalars['String']['output']>;
  period?: Maybe<Scalars['String']['output']>;
  sessionContextType?: Maybe<Scalars['String']['output']>;
  tenantId?: Maybe<Scalars['String']['output']>;
  userProfileId?: Maybe<Scalars['String']['output']>;
};

export type Endeavour_Limits_Max_Wager_Limits_Dot_Value = {
  __typename?: 'endeavour_limits_max_wager_limits_DOT_Value';
  activationStatus?: Maybe<Scalars['String']['output']>;
  amount?: Maybe<Scalars['Float']['output']>;
  changeCooldown?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  createdOn?: Maybe<Scalars['String']['output']>;
  creator?: Maybe<Scalars['String']['output']>;
  currencyCode?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isCancellable?: Maybe<Scalars['Boolean']['output']>;
  isPendingCancellation?: Maybe<Scalars['Boolean']['output']>;
  lastUpdatedOn?: Maybe<Scalars['String']['output']>;
  pendingChangeExpectedOn?: Maybe<Scalars['String']['output']>;
  tenantId?: Maybe<Scalars['String']['output']>;
  userProfileId?: Maybe<Scalars['String']['output']>;
};

export type Endeavour_Limits_Max_Wager_Limits_Dot_Value2 = {
  __typename?: 'endeavour_limits_max_wager_limits_DOT_Value2';
  activationStatus?: Maybe<Scalars['String']['output']>;
  amount?: Maybe<Scalars['Float']['output']>;
  changeCooldown?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  createdOn?: Maybe<Scalars['String']['output']>;
  creator?: Maybe<Scalars['String']['output']>;
  currencyCode?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isCancellable?: Maybe<Scalars['Boolean']['output']>;
  isPendingCancellation?: Maybe<Scalars['Boolean']['output']>;
  lastUpdatedOn?: Maybe<Scalars['String']['output']>;
  pendingChangeExpectedOn?: Maybe<Scalars['String']['output']>;
  tenantId?: Maybe<Scalars['String']['output']>;
  userProfileId?: Maybe<Scalars['String']['output']>;
};

export type Endeavour_Limits_Suspension_Limits_Dot_Value = {
  __typename?: 'endeavour_limits_suspension_limits_DOT_Value';
  activationStatus?: Maybe<Scalars['String']['output']>;
  changeCooldown?: Maybe<Scalars['String']['output']>;
  createdOn?: Maybe<Scalars['String']['output']>;
  duration?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  intent?: Maybe<Scalars['String']['output']>;
  isCancellable?: Maybe<Scalars['Boolean']['output']>;
  isPendingCancellation?: Maybe<Scalars['Boolean']['output']>;
  lastUpdatedOn?: Maybe<Scalars['String']['output']>;
  mediatorId?: Maybe<Scalars['String']['output']>;
  mediatorType?: Maybe<Scalars['String']['output']>;
  pendingChangeExpectedOn?: Maybe<Scalars['String']['output']>;
  tenantId?: Maybe<Scalars['String']['output']>;
  userProfileId?: Maybe<Scalars['String']['output']>;
};

export type Endeavour_Limits_Suspension_Limits_Dot_Value2 = {
  __typename?: 'endeavour_limits_suspension_limits_DOT_Value2';
  activationStatus?: Maybe<Scalars['String']['output']>;
  changeCooldown?: Maybe<Scalars['String']['output']>;
  createdOn?: Maybe<Scalars['String']['output']>;
  duration?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  intent?: Maybe<Scalars['String']['output']>;
  isCancellable?: Maybe<Scalars['Boolean']['output']>;
  isPendingCancellation?: Maybe<Scalars['Boolean']['output']>;
  lastUpdatedOn?: Maybe<Scalars['String']['output']>;
  mediatorId?: Maybe<Scalars['String']['output']>;
  mediatorType?: Maybe<Scalars['String']['output']>;
  pendingChangeExpectedOn?: Maybe<Scalars['String']['output']>;
  tenantId?: Maybe<Scalars['String']['output']>;
  userProfileId?: Maybe<Scalars['String']['output']>;
};

export type Endeavour_Limits_Transaction_Limits_Dot_Value = {
  __typename?: 'endeavour_limits_transaction_limits_DOT_Value';
  activationStatus?: Maybe<Scalars['String']['output']>;
  amount?: Maybe<Scalars['Float']['output']>;
  changeCooldown?: Maybe<Scalars['String']['output']>;
  createdOn?: Maybe<Scalars['BigInt']['output']>;
  currencyCode?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isCancellable?: Maybe<Scalars['Boolean']['output']>;
  isPendingCancellation?: Maybe<Scalars['Boolean']['output']>;
  lastUpdatedOn?: Maybe<Scalars['BigInt']['output']>;
  mediatorId?: Maybe<Scalars['String']['output']>;
  mediatorType?: Maybe<Scalars['String']['output']>;
  period?: Maybe<Scalars['String']['output']>;
  tenantId?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  userProfileId?: Maybe<Scalars['String']['output']>;
};

export type Endeavour_Limits_Transaction_Limits_Dot_Value2 = {
  __typename?: 'endeavour_limits_transaction_limits_DOT_Value2';
  activationStatus?: Maybe<Scalars['String']['output']>;
  amount?: Maybe<Scalars['Float']['output']>;
  changeCooldown?: Maybe<Scalars['String']['output']>;
  createdOn?: Maybe<Scalars['BigInt']['output']>;
  currencyCode?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isCancellable?: Maybe<Scalars['Boolean']['output']>;
  isPendingCancellation?: Maybe<Scalars['Boolean']['output']>;
  lastUpdatedOn?: Maybe<Scalars['BigInt']['output']>;
  mediatorId?: Maybe<Scalars['String']['output']>;
  mediatorType?: Maybe<Scalars['String']['output']>;
  period?: Maybe<Scalars['String']['output']>;
  tenantId?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  userProfileId?: Maybe<Scalars['String']['output']>;
};

export type Endeavour_Notes_Notes_Dot_Value = {
  __typename?: 'endeavour_notes_notes_DOT_Value';
  createdBy?: Maybe<Scalars['String']['output']>;
  createdOn?: Maybe<Scalars['String']['output']>;
  creator?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isImportant?: Maybe<Scalars['Boolean']['output']>;
  isPinned?: Maybe<Scalars['Boolean']['output']>;
  lastUpdatedBy?: Maybe<Scalars['String']['output']>;
  lastUpdatedOn?: Maybe<Scalars['String']['output']>;
  lastUpdater?: Maybe<Scalars['String']['output']>;
  noteCategoryId?: Maybe<Scalars['String']['output']>;
  tenantId?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type Endeavour_Notes_Notes_Dot_Value2 = {
  __typename?: 'endeavour_notes_notes_DOT_Value2';
  createdBy?: Maybe<Scalars['String']['output']>;
  createdOn?: Maybe<Scalars['String']['output']>;
  creator?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isImportant?: Maybe<Scalars['Boolean']['output']>;
  isPinned?: Maybe<Scalars['Boolean']['output']>;
  lastUpdatedBy?: Maybe<Scalars['String']['output']>;
  lastUpdatedOn?: Maybe<Scalars['String']['output']>;
  lastUpdater?: Maybe<Scalars['String']['output']>;
  noteCategoryId?: Maybe<Scalars['String']['output']>;
  tenantId?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type Endeavour_Notes_Notes_Slash__Slash_Properties_Slash_Transaction_Slash_OneOf_Slash_1 = {
  __typename?: 'endeavour_notes_notes_SLASH__SLASH_properties_SLASH_transaction_SLASH_oneOf_SLASH_1';
  dataCollectionOrder?: Maybe<Scalars['BigInt']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  totalOrder?: Maybe<Scalars['BigInt']['output']>;
};

export type Endeavour_Notes_Object_Notes_Dot_Value = {
  __typename?: 'endeavour_notes_object_notes_DOT_Value';
  externalResource?: Maybe<Scalars['String']['output']>;
  externalResourceId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  noteId?: Maybe<Scalars['String']['output']>;
  tenantId?: Maybe<Scalars['String']['output']>;
};

export type Endeavour_Notes_Object_Notes_Dot_Value2 = {
  __typename?: 'endeavour_notes_object_notes_DOT_Value2';
  externalResource?: Maybe<Scalars['String']['output']>;
  externalResourceId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  noteId?: Maybe<Scalars['String']['output']>;
  tenantId?: Maybe<Scalars['String']['output']>;
};

export type Endeavour_Notes_Object_Notes_Slash__Slash_Properties_Slash_Transaction_Slash_OneOf_Slash_1 = {
  __typename?: 'endeavour_notes_object_notes_SLASH__SLASH_properties_SLASH_transaction_SLASH_oneOf_SLASH_1';
  dataCollectionOrder?: Maybe<Scalars['BigInt']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  totalOrder?: Maybe<Scalars['BigInt']['output']>;
};

export type Endeavour_Restrictions_Restrictions_Dot_Value = {
  __typename?: 'endeavour_restrictions_restrictions_DOT_Value';
  createdOn?: Maybe<Scalars['String']['output']>;
  endsOn?: Maybe<Scalars['String']['output']>;
  externalResource?: Maybe<Scalars['String']['output']>;
  externalResourceId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  lastUpdatedOn?: Maybe<Scalars['String']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
  restrictDeposit?: Maybe<Scalars['Boolean']['output']>;
  restrictLogin?: Maybe<Scalars['Boolean']['output']>;
  restrictPromotions?: Maybe<Scalars['Boolean']['output']>;
  restrictWagering?: Maybe<Scalars['Boolean']['output']>;
  restrictWithdraw?: Maybe<Scalars['Boolean']['output']>;
  startsOn?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  tenantId?: Maybe<Scalars['String']['output']>;
  userProfileId?: Maybe<Scalars['String']['output']>;
};

export type Endeavour_Restrictions_Restrictions_Dot_Value2 = {
  __typename?: 'endeavour_restrictions_restrictions_DOT_Value2';
  createdOn?: Maybe<Scalars['String']['output']>;
  endsOn?: Maybe<Scalars['String']['output']>;
  externalResource?: Maybe<Scalars['String']['output']>;
  externalResourceId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  lastUpdatedOn?: Maybe<Scalars['String']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
  restrictDeposit?: Maybe<Scalars['Boolean']['output']>;
  restrictLogin?: Maybe<Scalars['Boolean']['output']>;
  restrictPromotions?: Maybe<Scalars['Boolean']['output']>;
  restrictWagering?: Maybe<Scalars['Boolean']['output']>;
  restrictWithdraw?: Maybe<Scalars['Boolean']['output']>;
  startsOn?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  tenantId?: Maybe<Scalars['String']['output']>;
  userProfileId?: Maybe<Scalars['String']['output']>;
};

export type Endeavour_Restrictions_Restrictions_Slash__Slash_Properties_Slash_Transaction_Slash_OneOf_Slash_1 = {
  __typename?: 'endeavour_restrictions_restrictions_SLASH__SLASH_properties_SLASH_transaction_SLASH_oneOf_SLASH_1';
  dataCollectionOrder?: Maybe<Scalars['BigInt']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  totalOrder?: Maybe<Scalars['BigInt']['output']>;
};

export type Endeavour_Sweepstakes_User_Profile_Sweepstakes_Dot_Value = {
  __typename?: 'endeavour_sweepstakes_user_profile_sweepstakes_DOT_Value';
  balanceAmount?: Maybe<Scalars['Float']['output']>;
  claimableFor?: Maybe<Scalars['String']['output']>;
  claimedFor?: Maybe<Scalars['String']['output']>;
  consumeTypeStrategy?: Maybe<Scalars['String']['output']>;
  consumingZeroAmount?: Maybe<Scalars['Float']['output']>;
  conversionMaxAmount?: Maybe<Scalars['Float']['output']>;
  conversionMinAmount?: Maybe<Scalars['Float']['output']>;
  convertedAmount?: Maybe<Scalars['Float']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  createdOn?: Maybe<Scalars['String']['output']>;
  creator?: Maybe<Scalars['String']['output']>;
  currencyCode?: Maybe<Scalars['String']['output']>;
  expiresOn?: Maybe<Scalars['String']['output']>;
  grantedAmount?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  inProgressFor?: Maybe<Scalars['String']['output']>;
  isClaimable?: Maybe<Scalars['Boolean']['output']>;
  lastUpdatedBy?: Maybe<Scalars['String']['output']>;
  lastUpdatedOn?: Maybe<Scalars['String']['output']>;
  lastUpdater?: Maybe<Scalars['String']['output']>;
  lockedAmount?: Maybe<Scalars['Float']['output']>;
  mediatorId?: Maybe<Scalars['String']['output']>;
  mediatorType?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  sweepstakeTemplateId?: Maybe<Scalars['String']['output']>;
  tenantId?: Maybe<Scalars['String']['output']>;
  userProfileId?: Maybe<Scalars['String']['output']>;
  wageredAmount?: Maybe<Scalars['Float']['output']>;
  wageringContributionStrategy?: Maybe<Scalars['String']['output']>;
  wageringRequirementAmount?: Maybe<Scalars['Float']['output']>;
  walletId?: Maybe<Scalars['String']['output']>;
};

export type Endeavour_Sweepstakes_User_Profile_Sweepstakes_Dot_Value2 = {
  __typename?: 'endeavour_sweepstakes_user_profile_sweepstakes_DOT_Value2';
  balanceAmount?: Maybe<Scalars['Float']['output']>;
  claimableFor?: Maybe<Scalars['String']['output']>;
  claimedFor?: Maybe<Scalars['String']['output']>;
  consumeTypeStrategy?: Maybe<Scalars['String']['output']>;
  consumingZeroAmount?: Maybe<Scalars['Float']['output']>;
  conversionMaxAmount?: Maybe<Scalars['Float']['output']>;
  conversionMinAmount?: Maybe<Scalars['Float']['output']>;
  convertedAmount?: Maybe<Scalars['Float']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  createdOn?: Maybe<Scalars['String']['output']>;
  creator?: Maybe<Scalars['String']['output']>;
  currencyCode?: Maybe<Scalars['String']['output']>;
  expiresOn?: Maybe<Scalars['String']['output']>;
  grantedAmount?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  inProgressFor?: Maybe<Scalars['String']['output']>;
  isClaimable?: Maybe<Scalars['Boolean']['output']>;
  lastUpdatedBy?: Maybe<Scalars['String']['output']>;
  lastUpdatedOn?: Maybe<Scalars['String']['output']>;
  lastUpdater?: Maybe<Scalars['String']['output']>;
  lockedAmount?: Maybe<Scalars['Float']['output']>;
  mediatorId?: Maybe<Scalars['String']['output']>;
  mediatorType?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  sweepstakeTemplateId?: Maybe<Scalars['String']['output']>;
  tenantId?: Maybe<Scalars['String']['output']>;
  userProfileId?: Maybe<Scalars['String']['output']>;
  wageredAmount?: Maybe<Scalars['Float']['output']>;
  wageringContributionStrategy?: Maybe<Scalars['String']['output']>;
  wageringRequirementAmount?: Maybe<Scalars['Float']['output']>;
  walletId?: Maybe<Scalars['String']['output']>;
};

export type Endeavour_Tags_Object_Tags_Dot_Value = {
  __typename?: 'endeavour_tags_object_tags_DOT_Value';
  domain?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  objectId?: Maybe<Scalars['String']['output']>;
  tagsId?: Maybe<Scalars['String']['output']>;
  tenantId?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type Endeavour_Tags_Object_Tags_Dot_Value2 = {
  __typename?: 'endeavour_tags_object_tags_DOT_Value2';
  domain?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  objectId?: Maybe<Scalars['String']['output']>;
  tagsId?: Maybe<Scalars['String']['output']>;
  tenantId?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type Endeavour_Verifications_Data_Verifications_Dot_Value = {
  __typename?: 'endeavour_verifications_data_verifications_DOT_Value';
  additionalData?: Maybe<Scalars['String']['output']>;
  breakdown?: Maybe<Scalars['String']['output']>;
  createdOn?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  externalResource?: Maybe<Scalars['String']['output']>;
  externalResourceId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  mediatorId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  tenantId?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  userProfileId?: Maybe<Scalars['String']['output']>;
};

export type Endeavour_Verifications_Data_Verifications_Dot_Value2 = {
  __typename?: 'endeavour_verifications_data_verifications_DOT_Value2';
  additionalData?: Maybe<Scalars['String']['output']>;
  breakdown?: Maybe<Scalars['String']['output']>;
  createdOn?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  externalResource?: Maybe<Scalars['String']['output']>;
  externalResourceId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  mediatorId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  tenantId?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  userProfileId?: Maybe<Scalars['String']['output']>;
};

export type Endeavour_Verifications_Documents_Dot_Value = {
  __typename?: 'endeavour_verifications_documents_DOT_Value';
  contentType?: Maybe<Scalars['String']['output']>;
  createdOn?: Maybe<Scalars['String']['output']>;
  dataSource?: Maybe<Scalars['String']['output']>;
  dataValue?: Maybe<Scalars['String']['output']>;
  documentNumber?: Maybe<Scalars['String']['output']>;
  expiresOn?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  fileName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  mediatorId?: Maybe<Scalars['String']['output']>;
  side?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  tenantId?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  userProfileId?: Maybe<Scalars['String']['output']>;
  verificationStatus?: Maybe<Scalars['String']['output']>;
};

export type Endeavour_Verifications_Documents_Dot_Value2 = {
  __typename?: 'endeavour_verifications_documents_DOT_Value2';
  contentType?: Maybe<Scalars['String']['output']>;
  createdOn?: Maybe<Scalars['String']['output']>;
  dataSource?: Maybe<Scalars['String']['output']>;
  dataValue?: Maybe<Scalars['String']['output']>;
  documentNumber?: Maybe<Scalars['String']['output']>;
  expiresOn?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  fileName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  mediatorId?: Maybe<Scalars['String']['output']>;
  side?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  tenantId?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  userProfileId?: Maybe<Scalars['String']['output']>;
  verificationStatus?: Maybe<Scalars['String']['output']>;
};

export type Endeavour_Verifications_Documents_Slash__Slash_Properties_Slash_Transaction_Slash_OneOf_Slash_1 = {
  __typename?: 'endeavour_verifications_documents_SLASH__SLASH_properties_SLASH_transaction_SLASH_oneOf_SLASH_1';
  dataCollectionOrder?: Maybe<Scalars['BigInt']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  totalOrder?: Maybe<Scalars['BigInt']['output']>;
};

export type Event_Dot_Block = {
  __typename?: 'event_DOT_block';
  dataCollectionOrder?: Maybe<Scalars['BigInt']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  totalOrder?: Maybe<Scalars['BigInt']['output']>;
};

export type Io_Dot_Debezium_Dot_Connector_Dot_Postgresql_Dot_Source = {
  __typename?: 'io_DOT_debezium_DOT_connector_DOT_postgresql_DOT_Source';
  connector?: Maybe<Scalars['String']['output']>;
  db?: Maybe<Scalars['String']['output']>;
  lsn?: Maybe<Scalars['BigInt']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  schema?: Maybe<Scalars['String']['output']>;
  sequence?: Maybe<Scalars['String']['output']>;
  snapshot?: Maybe<Scalars['String']['output']>;
  table?: Maybe<Scalars['String']['output']>;
  tsMs?: Maybe<Scalars['BigInt']['output']>;
  txId?: Maybe<Scalars['BigInt']['output']>;
  version?: Maybe<Scalars['String']['output']>;
  xmin?: Maybe<Scalars['BigInt']['output']>;
};

export type ObjectTaskType = {
  __typename?: 'objectTaskType';
  externalResource?: Maybe<Scalars['String']['output']>;
  externalResourceId?: Maybe<Scalars['String']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
};

export enum PriorityTypes {
  Critical = 'Critical',
  High = 'High',
  Low = 'Low',
  Medium = 'Medium',
  NotSet = 'NotSet'
}

export enum StatusTypes {
  Assigned = 'Assigned',
  Done = 'Done',
  Flagged = 'Flagged',
  InProgress = 'InProgress',
  New = 'New'
}

export type TaskResponse = {
  __typename?: 'taskResponse';
  count?: Maybe<Scalars['Int']['output']>;
  data?: Maybe<Array<Maybe<TaskTemplate>>>;
};

export type UserDataVerificationsUpdated = {
  __typename?: 'userDataVerificationsUpdated';
  after?: Maybe<Endeavour_Verifications_Data_Verifications_Dot_Value2>;
  before?: Maybe<Endeavour_Verifications_Data_Verifications_Dot_Value>;
  op?: Maybe<Scalars['String']['output']>;
  source?: Maybe<Io_Dot_Debezium_Dot_Connector_Dot_Postgresql_Dot_Source>;
  transaction?: Maybe<Event_Dot_Block>;
  tsMs?: Maybe<Scalars['BigInt']['output']>;
};

export type UserFreeBetsUpdated = {
  __typename?: 'userFreeBetsUpdated';
  after?: Maybe<Endeavour_Free_Bets_User_Profile_Free_Bets_Dot_Value2>;
  before?: Maybe<Endeavour_Free_Bets_User_Profile_Free_Bets_Dot_Value>;
  op?: Maybe<Scalars['String']['output']>;
  source?: Maybe<Io_Dot_Debezium_Dot_Connector_Dot_Postgresql_Dot_Source>;
  transaction?: Maybe<Event_Dot_Block>;
  tsMs?: Maybe<Scalars['BigInt']['output']>;
};

export type UserFreeSpinsUpdated = {
  __typename?: 'userFreeSpinsUpdated';
  after?: Maybe<Endeavour_Free_Spins_User_Profile_Free_Spins_Dot_Value2>;
  before?: Maybe<Endeavour_Free_Spins_User_Profile_Free_Spins_Dot_Value>;
  op?: Maybe<Scalars['String']['output']>;
  source?: Maybe<Io_Dot_Debezium_Dot_Connector_Dot_Postgresql_Dot_Source>;
  transaction?: Maybe<Event_Dot_Block>;
  tsMs?: Maybe<Scalars['BigInt']['output']>;
};

export type UserSweepstakesUpdated = {
  __typename?: 'userSweepstakesUpdated';
  after?: Maybe<Endeavour_Sweepstakes_User_Profile_Sweepstakes_Dot_Value2>;
  before?: Maybe<Endeavour_Sweepstakes_User_Profile_Sweepstakes_Dot_Value>;
  op?: Maybe<Scalars['String']['output']>;
  source?: Maybe<Io_Dot_Debezium_Dot_Connector_Dot_Postgresql_Dot_Source>;
  transaction?: Maybe<Event_Dot_Block>;
  tsMs?: Maybe<Scalars['BigInt']['output']>;
};

export type User_Profile_Updated_Slash__Slash_Properties_Slash_Current_Slash_Properties_Slash_AdditionalData_Slash_OneOf_Slash_1 = {
  __typename?: 'user_profile_updated_SLASH__SLASH_properties_SLASH_Current_SLASH_properties_SLASH_AdditionalData_SLASH_oneOf_SLASH_1';
  additionalProperties?: Maybe<Scalars['JSON']['output']>;
};
